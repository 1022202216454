const fbConfig ={
  apiKey: "AIzaSyCD3EBYqKUWXHwiM96mFshIjX6Jo_UbgAc",
  authDomain: "admin-panel-94225.firebaseapp.com",
  databaseURL: "https://admin-panel-94225.firebaseio.com",
  projectId: "admin-panel-94225",
  storageBucket: "admin-panel-94225.appspot.com",
  messagingSenderId: "783032865055",
  appId: "1:783032865055:web:9457bc3c00f8cb3162202a",
  measurementId: "G-6471F5R69G"
}

const apiUrl = "https://us-central1-admin-panel-94225.cloudfunctions.net/api";

export { apiUrl, fbConfig }
