import React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Notification from '../../components/Notification';
import Grid from '@material-ui/core/Grid';

import Decoration from '../../components/FormsLanding/Decoration';

const sectionMargin = margin => (margin * 20);
const useStyles = makeStyles(theme => ({
    mainWrap: {
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
        background: theme.palette.background.paper,
    },
    divText: {
        alignItems:'flex-start',
    },
    text: {
        alignItems:'flex-start',
        marginBottom:theme.spacing(3),
    },
    subtitle:{
        marginBottom:theme.spacing(6),
    },
    spaceBottom: {
        marginBottom: sectionMargin(theme.spacing())
    },
    spaceTop: {
        paddingTop:theme.spacing(15),
    },
    spaceBottomText: {
        marginBottom:theme.spacing(10),
    },
    containerWrap: {
        marginTop: theme.spacing(10),
        padding: theme.spacing(4),
        '& > section': {
            position: 'relative'
        }
    },
}));

const infoText= {
    section1: {
        title:'Cómo se propaga',
        text1:'En estos momentos no existe una vacuna para prevenir la enfermedad del coronavirus 2019 (COVID-19).',
        text2:'La mejor manera de prevenir la enfermedad es evitar la exposición a este virus.',
        text3:'Se cree que el virus se propaga principalmente de persona a persona.',
        text4:[
           "Entre personas que están en contacto cercano (a una distancia de hasta aproximadamente 6 pies).",
           "A través de gotitas respiratorias que se producen cuando una persona infectada tose, estornuda o habla.",
           "Estas gotitas pueden terminar en la boca o en la nariz de quienes se encuentran cerca o posiblemente ser inhaladas y llegar a los pulmones.",
           "Algunos estudios recientes sugieren que el COVID-19 puede propagarse a través de personas que no presentan síntomas.",
            ]},

    section2: {
        title:'Lavarse las manos frecuentemente',
        text1:'Lávese las manos con frecuencia con agua y jabón por al menos 20 segundos, especialmente después de haber estado en un lugar público, o después de sonarse la nariz, toser o estornudar.',
        text2:'Es de suma importancia que se lave:',
        text3:[
            "Antes de comer o preparar la comida",
            "Antes de tocarse la cara",
            "Después de salir de lugares públicos",
            "Después de sonarse la nariz, toser o estornudar",
            "Después de manipular su mascarilla",
            "Después de cambiar pañales",
            "Después de cuidar a una persona enferma",
            "Después de tocar animales o mascotas",
        ],
        text4:'Si no dispone de agua y jabón, use un desinfectante de manos que contenga al menos un 60 % de alcohol. Cubra todas las superficies de las manos y frótelas hasta que sienta que se secaron.',
        text5:'Evite tocarse los ojos, la nariz y la boca sin haberse lavado las manos.',
    },
    section3: {
        title:'Cúbrase la boca y la nariz con una mascarilla cuando está con otras personas',
        text1:'Podría transmitir el COVID-19 a otras personas incluso si no se siente mal.',
        text2:'El objetivo de la mascarilla es proteger a otras personas, en caso de estar infectado.',
        text3:'Todos deben usar una mascarilla en lugares públicos y cuando están con otras personas que no viven en su hogar, especialmente cuando es difícil mantener otras medidas de distanciamiento social.',
        text4:'NO use una mascarilla destinada a trabajadores de atención médica. Actualmente, las mascarillas quirúrgicas y las mascarillas de respiración N95 son suministros fundamentales que deberían reservarse para los trabajadores de la salud y el personal de respuesta a emergencias.',
        text5:'Siga manteniendo una distancia de aproximadamente 6 pies de las demás personas. El uso de la mascarilla no reemplaza el distanciamiento social.',
    },
    section4: {
        title:'Cúbrase la nariz y la boca al toser y estornudar',
        text1:'Cúbrase siempre la boca y la nariz con un pañuelo desechable al toser o estornudar o cúbrase con la parte interna del codo y no escupa.',
        text2:'Bote los pañuelos desechables usados a la basura.',
        text3:'Lávese las manos inmediatamente con agua y jabón por al menos 20 segundos. Si no dispone de agua y jabón, límpiese las manos con un desinfectante de manos que contenga al menos un 60 % de alcohol.',
    },
    section5: {
        title:'Limpie y desinfecte',
        text1:'Limpie Y desinfecte diariamente las superficies que se tocan con frecuencia . Esto incluye las mesas, las manijas de las puertas, los interruptores de luz, los mesones, las barandas, los escritorios, los teléfonos, los teclados, los inodoros, los grifos, los lavamanos y los lavaplatos.',
        text2:'Si las superficies están sucias, límpielas. Lávelas con agua y detergente o jabón antes de desinfectarlas.',
        text3:'Luego, use un desinfectante de uso doméstico. La mayoría de los desinfectantes comunes para el hogar registrados en la EPAícono de sitio externo  funcionarán.',
    },
    section6: {
        title:'Monitoree su salud a diario',
        text1:'Esté atento a los síntomas. Esté atento a la aparición de fiebre, tos, dificultad para respirar u otros síntomas del COVID-19.',
        text2:'Controle su temperatura si presenta síntomas',
    },
}

export default function Info(props) {
    const classes = useStyles();
    const {onToggleDark, onToggleDir, t} = props;
    return (
        <React.Fragment>
            <CssBaseline/>
            <div className={classes.mainWrap}>
                <Header onToggleDark={onToggleDark} onToggleDir={onToggleDir} invert/>
                <main className={classes.containerWrap}>
                    <Decoration/>
                    <section className={clsx(classes.spaceTop, classes.spaceBottom)}>
                        <Grid container direction="row" justify="center" alignItems="center" className={[classes.text, classes.spaceBottomText]}>
                            <Grid item ms={12} xs={12} className={classes.text}>
                                <Typography  variant="h4" align="center" gutterBottom>
                                    COVID-19
                                </Typography>
                            </Grid>
                            <Grid item ms={12} xs={12} className={classes.text}>
                                <Typography variant="h5" align="center" gutterBottom>
                                    La vacuna del COVID-19, cuándo estará lista y qué es lo que se sabe hasta ahora
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container direction="row" justify="center" alignItems="center" className={[classes.text, classes.spaceBottomText]}>
                            <Grid item ms={7} xs={7} className={classes.text}>
                                <Typography variant="h6" align="justify" gutterBottom>
                                    {infoText.section1.title}
                                </Typography>
                            </Grid>
                            <Grid item ms={7} xs={7} className={classes.text}>
                                <Typography variant="subtitle1" align="justify" gutterBottom>
                                    {infoText.section1.text1}
                                </Typography>
                                <Typography variant="subtitle1" align="justify" gutterBottom>
                                    {infoText.section1.text2}
                                </Typography>
                                <Typography variant="subtitle1" align="justify" gutterBottom>
                                    {infoText.section1.text3}
                                </Typography>
                                <Typography variant="subtitle1" align="justify" gutterBottom>
                                    <ul>
                                        {infoText.section1.text4.map(x => {
                                            return (
                                                <li>{x}</li>
                                            )
                                        })}
                                    </ul>
                                </Typography>
                            </Grid>

                            <Grid item ms={7} xs={7} className={classes.text}>
                                <Typography variant="h6" align="justify" gutterBottom>
                                    {infoText.section2.title}
                                </Typography>
                            </Grid>
                            <Grid item ms={7} xs={7} className={classes.text}>
                                <Typography variant="subtitle1" align="justify" gutterBottom>
                                    {infoText.section2.text1}
                                </Typography>
                                <Typography variant="subtitle1" align="justify" gutterBottom>
                                    {infoText.section2.text2}
                                </Typography>
                                <Typography variant="subtitle1" align="justify" gutterBottom>
                                    <ul>
                                        {infoText.section2.text3.map(x => {
                                            return (
                                                <li>{x}</li>
                                            )
                                        })}
                                    </ul>
                                </Typography>
                                <Typography variant="subtitle1" align="justify" gutterBottom>
                                    {infoText.section2.text4}
                                </Typography>
                                <Typography variant="subtitle1" align="justify" gutterBottom>
                                    {infoText.section2.text5}
                                </Typography>
                            </Grid>


                            <Grid item ms={7} xs={7} className={classes.text}>
                                <Typography variant="h6" align="justify" gutterBottom>
                                    {infoText.section3.title}
                                </Typography>
                            </Grid>
                            <Grid item ms={7} xs={7} className={classes.text}>
                                <Typography variant="subtitle1" align="justify" gutterBottom>
                                    {infoText.section3.text1}
                                </Typography>
                                <Typography variant="subtitle1" align="justify" gutterBottom>
                                    {infoText.section3.text2}
                                </Typography>
                                <Typography variant="subtitle1" align="justify" gutterBottom>
                                    {infoText.section3.text3}
                                </Typography>
                                <Typography variant="subtitle1" align="justify" gutterBottom>
                                    {infoText.section3.text4}
                                </Typography>
                                <Typography variant="subtitle1" align="justify" gutterBottom>
                                    {infoText.section3.text5}
                                </Typography>
                            </Grid>

                            <Grid item ms={7} xs={7} className={classes.text}>
                                <Typography variant="h6" align="justify" gutterBottom>
                                    {infoText.section4.title}
                                </Typography>
                            </Grid>
                            <Grid item ms={7} xs={7} className={classes.text}>
                                <Typography variant="subtitle1" align="justify" gutterBottom>
                                    {infoText.section4.text1}
                                </Typography>
                                <Typography variant="subtitle1" align="justify" gutterBottom>
                                    {infoText.section4.text2}
                                </Typography>
                                <Typography variant="subtitle1" align="justify" gutterBottom>
                                    {infoText.section4.text3}
                                </Typography>
                            </Grid>


                            <Grid item ms={7} xs={7} className={classes.text}>
                                <Typography variant="h6" align="justify" gutterBottom>
                                    {infoText.section5.title}
                                </Typography>
                            </Grid>
                            <Grid item ms={7} xs={7} className={classes.text}>
                                <Typography variant="subtitle1" align="justify" gutterBottom>
                                    {infoText.section5.text1}
                                </Typography>
                                <Typography variant="subtitle1" align="justify" gutterBottom>
                                    {infoText.section5.text2}
                                </Typography>
                                <Typography variant="subtitle1" align="justify" gutterBottom>
                                    {infoText.section5.text3}
                                </Typography>
                            </Grid>

                            <Grid item ms={7} xs={7} className={classes.text}>
                                <Typography variant="h6" align="justify" gutterBottom>
                                    {infoText.section6.title}
                                </Typography>
                            </Grid>
                            <Grid item ms={7} xs={7} className={classes.text}>
                                <Typography variant="subtitle1" align="justify" gutterBottom>
                                    {infoText.section6.text1}
                                </Typography>
                                <Typography variant="subtitle1" align="justify" gutterBottom>
                                    {infoText.section6.text2}
                                </Typography>
                            </Grid>
                        </Grid>

                    </section>
                </main>
                <section className={classes.spaceTop}>
                    <Footer/>
                </section>
                <Notification/>
            </div>
        </React.Fragment>
    );
}
