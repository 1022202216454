export const ARTICLES_BY_CATEGORY_REQUEST = 'ARTICLES_BY_CATEGORY_REQUEST';
export const ARTICLES_BY_CATEGORY_SUCCESS = 'ARTICLES_BY_CATEGORY_SUCCESS';
export const ARTICLES_BY_CATEGORY_ERROR = 'ARTICLES_BY_CATEGORY_ERROR';

export function ArticlesByCategoryRequest() {
  return {
    type: ARTICLES_BY_CATEGORY_REQUEST
  };
}

export function ArticlesByCategorySuccess(payload) {
  return {
    type: ARTICLES_BY_CATEGORY_SUCCESS,
    payload
  };
}

export function ArticlesByCategoryError(error) {
  return {
    type: ARTICLES_BY_CATEGORY_ERROR,
    error
  };
}

export function articleByCategory(info) {
  return async function (dispatch) {
    dispatch(ArticlesByCategoryRequest());
    try {
        dispatch(ArticlesByCategorySuccess(info));
    } catch (error) {
      dispatch(ArticlesByCategoryError(error.message));
    }
  };
}
