import { firebase } from '../../api/firebase';

export const CATEGORY_ARTICLES_REQUEST = 'CATEGORY_ARTICLES_REQUEST';
export const CATEGORY_ARTICLES_SUCCESS = 'CATEGORY_ARTICLES_SUCCESS';
export const CATEGORY_ARTICLES_ERROR = 'CATEGORY_ARTICLES_ERROR';

export function categoryArticlesRequest() {
  return {
    type: CATEGORY_ARTICLES_REQUEST
  };
}

export function categoryArticlesSuccess(payload) {
  return {
    type: CATEGORY_ARTICLES_SUCCESS,
    payload
  };
}

export function categoryArticlesError(error) {
  return {
    type: CATEGORY_ARTICLES_ERROR,
    error
  };
}

export function categoryArticle() {
  return async function (dispatch) {
    dispatch(categoryArticlesRequest());
    try {
      const db = firebase.firestore();
      let categoryArticles = db.collection('category_articles');
      categoryArticles.onSnapshot(documentSnapshots => {
        let categories = [];
        documentSnapshots.forEach(doc => {
          let data = doc.data();
          data['id'] = doc.id;
          data['value'] = doc.id;
          data['label'] = data.categoryName;
          categories.push(data);
        });
        dispatch(categoryArticlesSuccess(categories));
      });

    } catch (error) {
      dispatch(categoryArticlesError(error.message));
    }
  };
}
