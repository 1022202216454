import { makeStyles } from '@material-ui/core/styles';
import flag from '../../images/flag-logo.png';

const flagIcon = {
  width: 16,
  height: 16,
  borderRadius: '50%',
  display: 'inline-block',
  position: 'relative',
  marginRight: 5,
  top: 1,
  background: `url(${flag}) no-repeat transparent`,
  backgroundSize: '16px auto',
  '&[class="ar"]': {
    backgroundPosition: '0 3px'
  },
  '&[class="zh"]': {
    backgroundPosition: '0 -12px'
  },
  '&[class="en"]': {
    backgroundPosition: '0 -28px'
  },
  '&[class="de"]': {
    backgroundPosition: '0 -44px'
  },
  '&[class="id"]': {
    backgroundPosition: '0 -62px'
  },
  '&[class="pt"]': {
    backgroundPosition: '0 -79px'
  },
};

const headerStyles = makeStyles(theme => ({
  '@keyframes slideRight': {
    from: {
      opacity: 0,
      transform: 'translateX(-100px)'
    },
    to: {
      opacity: 1,
      transform: 'none'
    }
  },
  fixed: {},
  openDrawer: {},
  shoppingCartIcon: {
    fontSize: 30,
    color: '#0091ea'
  },

  header: {
    color: theme.palette.text.primary,
    background: 'none',
    boxShadow: 'none',
    transition: 'all 0.5s ease-out',
    position: 'fixed',
    '& > *': {
      [theme.breakpoints.down('md')]: {
        padding: 0
      }
    },
    '& nav': {
      transition: 'all 0.5s ease-out',
    },
    '&$fixed': {
      position: 'fixed',
      top: 0,
      boxShadow: theme.shadows[4],
      background: theme.palette.background.paper,
      zIndex: 1000,
      '& $logo': {
        '& a': {
          color: theme.palette.text.primary,
        },
        '& img': {
          height: 100,
          width: 100,
        }
      },
      '& $bar': {
        [theme.breakpoints.down('sm')]: {
          backgroundColor: theme.palette.text.secondary,
          '&:after, &:before': {
            backgroundColor: theme.palette.text.secondary
          }
        }
      },
      '& nav': {
        padding: theme.spacing(1, 0),
        '& ul': {
          '& li': {
            '& a': {
              color: theme.palette.text.primary,
            }
          }
        }
      },
      '& $vDivider': {
        minHeight: theme.spacing(3)
      },
      '& $icon': {
        '& svg': {
          fill: theme.palette.text.secondary
        }
      }
    },
    '&$openDrawer': {
      zIndex: 1600,
      boxShadow: 'none',
      '& $logo': {
        '& a': {
          color: theme.palette.text.primary,
        }
      },
    }
  },
  icon: {
    // background: theme.palette.secondary.light,
    '&:hover': {
      height: 100,
      background: 'rgba(255, 255, 255, 0.2)'
    },
  },
  button: {
    fontSize:16,
    textTransform: 'capitalize',
    // background: theme.palette.secondary.light,
    color: '#000',
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover': {
      height: 28,
      background: 'rgba(255, 255, 255, 0.2)'
    },
  },
  headerContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& nav': {
      alignItems: 'center',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2, 0),
      },
    }
  },
  logo: {
    '& a': {
      textDecoration: 'none',
      display: 'flex',
      fontSize: 22,
      color: theme.palette.text.primary,
      alignItems: 'center',
      fontWeight: 500,
      [theme.breakpoints.down('sm')]: {
        color: theme.palette.common.white
      }
    },
    '& img': {
      transition: 'all 0.3s ease-out',
      width: 150,
      height: 150,
      marginRight: theme.spacing(),
      [theme.breakpoints.only('md')]: {
        marginLeft: theme.spacing(2)
      }
    }
  },
  cartIconToggle:{
    justifyContent:'space-around',
    backgroundColor:'rgba(255,255,255,.3)',
    borderColor:'rgba(15,192,243,.3)',
    '&:focus': {
      outline: 'none',
      backgroundColor:'rgba(255,255,255,.3)',
      borderColor:'rgba(15,192,243,.3)',
    },
    '&:hover': {
      outline: 'none',
      backgroundColor:'rgba(255,255,255,.3)',
      borderColor:'rgba(15,192,243,.3)',
    },
  },
  cartDropdownMenu:{
    marginTop:10,
    backgroundColor:'rgba(255,255,255,.3)',
    borderColor:'rgba(15,192,243,.3)',
  },



  active: {},
  invert: {},
  navLogo: {
    '&$invert': {
      '& $logo': {
        '& a': {
          color: theme.palette.text.primary,
        }
      },
      '& $mobileMenu': {
        '& $bar': {
          [theme.breakpoints.down('sm')]: {
            backgroundColor: theme.palette.text.secondary,
            '&:after, &:before': {
              backgroundColor: theme.palette.text.secondary
            },
          }
        }
      },
    }
  },

  linkColor:{
    '& a': {
      textTransform: 'capitalize',
      minWidth: 0,
      padding: theme.spacing(0, 2),
      fontWeight: theme.typography.fontWeightMedium,
      transition: 'all 0.3s ease-out',
      color: `#000 !important`,
      fontSize: 16,
      boxShadow: 'none',
      position: 'relative',
      margin: theme.spacing(0.5, 1),
      '&:hover': {
        color: `#00c853 !important`,
        background: 'rgba(255, 255, 255, 0.2)'
      },
      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(0, 3),
      },
      [theme.breakpoints.down('xs')]: {
        margin: 0
      },
    },
    '&[class="active"]': {
      '& a': {
        boxShadow: '0 1.5px 32px -8px rgba(0, 0, 0, 0.8)',
        color: `#000 !important`,
        background: '#00c853',
      }
    }

  },

  navMenu2: {
    '&$invert': {
      '& ul': {
        '& li': {
          '& a': {
            transition: 'all 0.3s ease-out',
            color: theme.palette.text.primary,
          }
        }
      }
    },
    '& ul': {
      listStyle: 'none',
      '& li': {
        [theme.breakpoints.up('lg')]: {
          margin: theme.spacing(0, 2),
        },
        listStyle: 'none',
        position: 'relative',
        display: 'inline-block',
        '& button': {
          color: '#000',
          textTransform: 'capitalize',
          fontSize: 18,
          fontWeight: theme.typography.fontWeightMedium,
          background: 'none !important',
          boxShadow: 'none',
          position: 'relative',
          padding: '6px',
          minWidth: 0,
          '&:after': {
            content: '""',
            borderBottom: '3px solid #fff',
            width: '0%',
            position: 'absolute',
            bottom: 0,
            left: '50%',
            transition: 'all 0.2s cubic-bezier(0.42, 0.16, 0.21, 0.93)'
          },
          '&:hover': {
            transition: 'all 0.3s ease-out',
            '&:after': {
              width: '60%',
              left: 8,
              borderBottomColor: theme.palette.primary.light
            }
          }
        },
      }
    }
  },
  navMenu: {
    '& > *': {
      margin: 0,
      [theme.breakpoints.up('lg')]: {
        margin: theme.spacing(0, 1),
      }
    },
    '&$invert': {
      '& ul': {
        '& li': {
          '& a': {
            transition: 'all 0.3s ease-out',
            color: theme.palette.text.primary,
          }
        }
      }
    },
    '& ul': {
      listStyle: 'none',
      '& li': {
        [theme.breakpoints.up('lg')]: {
          margin: theme.spacing(0, 2),
        },
        listStyle: 'none',
        position: 'relative',
        display: 'inline-block',
        '& button': {
          color: '#000',
          marginTop: theme.spacing(0.5),
          textTransform: 'capitalize',
          fontSize: 18,
          fontWeight: theme.typography.fontWeightMedium,
          background: 'none !important',
          boxShadow: 'none',
          position: 'relative',
          padding: '6px',
          margin: theme.spacing(0, 1),
          minWidth: 0,
          '&:after': {
            content: '""',
            borderBottom: '3px solid #fff',
            width: '0%',
            position: 'absolute',
            bottom: 0,
            left: '50%',
            transition: 'all 0.2s cubic-bezier(0.42, 0.16, 0.21, 0.93)'
          },
          '&:hover': {
            transition: 'all 0.3s ease-out',
            '&:after': {
              width: '60%',
              left: 8,
              borderBottomColor: theme.palette.primary.light
            }
          }
        },
        '& a': {
          color: '#000',
          marginTop: theme.spacing(0.5),
          textTransform: 'capitalize',
          fontSize: 18,
          fontWeight: theme.typography.fontWeightMedium,
          background: 'none !important',
          boxShadow: 'none',
          position: 'relative',
          padding: '6px',
          margin: theme.spacing(0, 1),
          minWidth: 0,
          '&:after': {
            content: '""',
            borderBottom: '3px solid #fff',
            width: '0%',
            position: 'absolute',
            bottom: 0,
            left: '50%',
            transition: 'all 0.2s cubic-bezier(0.42, 0.16, 0.21, 0.93)'
          },
          '&:hover': {
            transition: 'all 0.3s ease-out',
            '&:after': {
              width: '60%',
              left: 8,
              borderBottomColor: theme.palette.primary.light
            }
          }
        },
        '&[class="active"]': {
          '& a': {
            color: theme.palette.primary.light,
            '&:after': {
              borderBottomColor: theme.palette.primary.light,
              width: '60%',
              left: 8,
            },
          }
        }
      }
    }
  },
  langMenu: {
    textTransform: 'capitalize',
    '& i': {
      ...flagIcon
    }
  },
  modeMenu: {
    textTransform: 'capitalize',
  },
  vDivider: {
    margin: theme.spacing(0, 1),
    borderLeft: `1px solid ${theme.palette.divider}`,
    height: '100%',
    transition: 'all 0.5s ease-out',
    minHeight: theme.spacing(6)
  },

  setting: {
    '& $icon': {
      fontSize: 32,
      transition: 'all 0.3s ease',
      color: theme.palette.common.white,
      '&$invert': {
        color: theme.palette.text.primary
      }
    },
    '& $active': {
      transform: 'rotate(30deg)'
    }
  },
  titleMenu: {
    textTransform: 'capitalize'
  },
  bar: {},
  menu: {},
  menuOpen: {},
  paperNav: {
    width: '100%',
    [theme.breakpoints.up(680)]: {
      width: 300,
    },
  },
  mobileMenu: {
    margin: theme.spacing(0, 1),
    '& $bar': {
      backgroundColor: theme.palette.common.white,
      '&:after, &:before': {
        backgroundColor: theme.palette.common.white
      },
    },
    '&[class*="is-active"]': {
      '& $bar': {
        backgroundColor: theme.palette.text.secondary,
        '&:after, &:before': {
          backgroundColor: theme.palette.text.secondary
        }
      }
    }
  },
  mobileNav: {
    background: theme.palette.background.paper,
    '& $menu': {
      padding: theme.spacing(0, 2),
      overflow: 'auto',
      top: theme.spacing(15),
      width: '100%',
      position: 'absolute',
      height: 'calc(100% - 80px)',
      '& a': {
        animationName: '$slideRight',
        animationTimingFunction: 'ease'
      },
    }
  },
  menuList: {
    textTransform: 'capitalize',
    '& span': {
      fontSize: 24
    }
  },
  dropdownItem:{
    '&:focus': {
      color:'#00c853',
      outline: 'none',
    },
    '&:hover': {
      color:'#00c853',
      outline: 'none',
    },
  },
}));

export default headerStyles;
