export const GET_LIGHT_BOX_REQUEST = "GET_LIGHT_BOX_REQUEST";
export const GET_LIGHT_BOX_SUCCESS = "GET_LIGHT_BOX_SUCCESS";
export const GET_LIGHT_BOX_ERROR = "GET_LIGHT_BOX_ERROR";

export function lightBoxRequest() {
  return {
    type: GET_LIGHT_BOX_REQUEST
  }
}

export function lightBoxSuccess(payload) {
  return {
    type: GET_LIGHT_BOX_SUCCESS,
    payload
  }
}

export function lightBoxError(error) {
  return {
    type: GET_LIGHT_BOX_ERROR,
    error
  }
}

export function lightBox(toggle, slide) {
  return async function (dispatch) {
    dispatch(lightBoxRequest());
    try {
      dispatch(lightBoxSuccess({toggle:!toggle, slide:slide }));
    } catch (error) {
      dispatch(lightBoxError(error.message));
    }
  }
}
