import React from 'react';
import clsx from 'clsx';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {NavLink} from 'react-router-dom';
import useStyles from './header-style';
import navMenu from './menu';

const headerName = [
  {name: 'about', value: 'Sobre nosotros'},
  {name: 'services', value: 'Servicios'},
  {name: 'our-expertise', value: 'Nuestro trabajo'},
  {name: 'case-studies', value: 'Portafolio'},
];


export default function MobileMenu(props) {
  const classes = useStyles();
  const { toggleDrawer, open } = props;
  const SideList = () => (
    <div
      className={classes.mobileNav}
      role="presentation"
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}
    >
      <div className={clsx(classes.menu, open && classes.menuOpen)}>
        <List component="nav">
          {navMenu.map((item, index) => (
            <ListItem
              button
              component="a"
              href={`#${item}`}
              key={index.toString()}
              style={{ animationDuration: index * 0.15 + 's' }}
            >
              <ListItemText primary={headerName.find(x=>x.name===item).value} className={classes.menuList} />
            </ListItem>
          ))}
          <Divider className={classes.dividerSidebar} />
          {['contacto', 'inicio', 'registrarse'].map((item, index) => (
            <ListItem
              button
              component="a"
              key={index.toString()}
              style={{ animationDuration: navMenu.length * 0.15 + 's' }}
            >
              <NavLink  to={'/'+item}>
              <ListItemText primary={ item} className={classes.menuList} />
              </NavLink>
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );

  return (
    <SwipeableDrawer
      open={open}
      onClose={toggleDrawer}
      onOpen={toggleDrawer}
      classes={{
        paper: classes.paperNav
      }}
    >
      <SideList />
    </SwipeableDrawer>
  );
}
