import React from "react";
import CustomNavbar from "../../components/CustomNavbar";
import Footer from '../../components/Footer';
import FooterData from '../../components/Footer/FooterData';
let {height} =window.screen
const NotFound = () => (
    <div className="body_wrapper">
        <CustomNavbar hbtnClass="new_btn"/>
        <section className="error_two_area">
            <div className="container flex">
                <div className="error_content_two text-center">
                    <h2 style={{fontSize: height*0.25, marginBottom:100}}>404</h2>
                    <h2>Error. No podemos encontrar la página que busca.</h2>
                    <p>
                        Lo sentimos por los inconvenientes ocasionados. Vaya a nuestra página de inicio.
                    </p>
                    <a href="/" className="about_btn btn_hover">
                        Volver al inicio <i className="arrow_right"></i>
                    </a>
                </div>
            </div>
        </section>
        <Footer FooterData={FooterData}/>
    </div>
);
export default NotFound;
