import React from 'react';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import useStyles from './title-style';

function WithDecoration(props) {
  const classes = useStyles();
  const { text, extended, icon } = props;
  return (
    <div className={clsx(classes.iconDeco, extended && classes.extend)}>
        {icon }
      <Typography variant="h3">
        {text}
      </Typography>
    </div>
  );
}

export default WithDecoration;
