import React from 'react';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import useStyles from './cards-style';
import {NavLink} from "react-router-dom";

function Services(props) {
    const classes = useStyles();
    const {
        img,
        title,
        desc,
        button,
        link
    } = props;
    return (
        <Paper className={classes.defaultCard}>
            <NavLink exact className="nav-link" target="_blank" to={link} variant="subtitle1" color="textSecondary">
                <figure style={{cursor: 'pointer'}}>
                    <img src={img} alt="img"/>
                </figure>
            </NavLink>
            <div className={classes.text}>
                <Typography display="block" variant="h6">{title}</Typography>
                <Typography component="p">{desc}</Typography>
            </div>
            <NavLink exact className="nav-link" to={link} variant="subtitle1" color="textSecondary">
                <Button variant="outlined" fullWidth color="primary" className={classes.button}>
                    {button}
                </Button>
            </NavLink>
        </Paper>
    );
}

export default Services;
