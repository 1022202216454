import React, {useEffect, useState} from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';
import ReactWOW from 'react-wow';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/LocalPhone';
import LocationIcon from '@material-ui/icons/LocationOn';
import useStyles from './map-address-style';
import TitleDeco from '../Title/WithDecoration';
import Link from "@material-ui/core/Link";
import nightShowers from '../../images/weather/night-showers.png'
import daySleet from '../../images/weather/day-sleet.png'
import daySnow from '../../images/weather/day-snow.png'
import dayFog from '../../images/weather/day-fog.png'
import daySunny from '../../images/weather/day-sunny.png'
import nightPartlyCloudy from '../../images/weather/partly-cloudy.png'
import dayCloudy from '../../images/weather/day-cloudy.png'
import dayWindy from '../../images/weather/day-windy.png'

export default function MapAdress() {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
    const [weatherInfo, setWeatherInfo] = useState({city: 'Santiago de los Caballeros', temp: 'No disponible', weatherIcon: '', wind: 'No disponible'});


    function getIcon(id) {
        if (id >= 200 && id < 300) {
            return nightShowers
        } else if (id >= 300 && id < 500) {
            return daySleet
        } else if (id >= 500 && id < 600) {
            return nightShowers
        } else if (id >= 600 && id < 700) {
            return daySnow
        } else if (id >= 700 && id < 800) {
            return dayFog
        } else if (id === 800) {
            return daySunny
        } else if (id >= 801 && id < 803) {
            return nightPartlyCloudy
        } else if (id >= 802 && id < 900) {
            return dayCloudy
        } else if (id === 905 || (id >= 951 && id <= 956)) {
            return dayWindy
        } else if (id >= 900 && id < 1000) {
            return nightShowers
        }
    }

    useEffect(() => {
        const appid = 'ad02eff480234645cfd4a5b6b56405de';
        const city = 'Santiago de los Caballeros';
        const weatherUrl = `https://api.openweathermap.org/data/2.5/weather?q=${city},do&appid=${appid}`
        fetch(weatherUrl)
            .then((response)=> {
                return response.json();
            })
            .then( (response)=> {
               let temp= response.main.temp - 273.15
                let data = {
                    city: response.name,
                    temp: temp.toFixed(1),
                    weatherIcon: getIcon(response.weather[0].id),
                    wind:response.wind.speed+' m/s'
                }
                setWeatherInfo(data)
            }).catch(error => {
            console.log('Error fetching and parsing data', error);
        })

    }, [])


    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Container fixed>
                <Grid container spacing={6}>
                    {isDesktop && (
                        <Grid item md={1}/>
                    )}
                    <Grid item md={5} xs={12}>
                        <TitleDeco text={'Nuestra oficina'}/>
                        <div className={classes.block}>
                            <ReactWOW animation="fadeInLeftShort" offset={-100} delay="0.5s" duration="0.3s">
                                <Paper className={classes.paper}>
                                    <Typography variant="h6" display="block">
                                        Oficina
                                    </Typography>
                                        <Grid style={{ marginTop: 10 }} container justify="center" direction="row" alignItems="center" >
                                            <Grid style={{textAlign:'center'}} item sm={2} xs={12}>
                                                <PhoneIcon className={classes.icon}/>
                                            </Grid>
                                            <Grid style={{textAlign:'center'}} item sm={10} xs={12}>
                                                809-489-9843
                                            </Grid>
                                        </Grid>

                                        <Grid style={{ marginTop: 10 }} container justify="space-evenly" direction="row" alignItems="center" >
                                            <Grid style={{textAlign:'center'}} item sm={2} xs={12}>
                                                <EmailIcon className={classes.icon}/>
                                            </Grid>
                                            <Grid style={{textAlign:'center'}} item sm={10} xs={12}>
                                                info@saludatualcance.com.do
                                            </Grid>
                                        </Grid>

                                        <Grid style={{ marginTop: 10 }} container justify="space-evenly" direction="row" alignItems="center" >
                                            <Grid style={{textAlign:'center'}} item sm={2} xs={12}>
                                                <LocationIcon className={classes.icon}/>
                                            </Grid>
                                            <Grid style={{textAlign:'center'}} item sm={10} xs={12}>
                                                Calle Proyecto 3 #28 Reparto Oquet, Santiago, RD.
                                            </Grid>
                                        </Grid>
                                </Paper>
                            </ReactWOW>
                            <ReactWOW animation="fadeInLeftShort" offset={-100} delay="0.2s" duration="0.3s">
                                <Paper className={classes.paper}>
                                    <Grid container justify="center" alignItems="center" >
                                        <Grid item sm={6} xs={12}>
                                            <Typography variant="h6" display="block">
                                                Clima
                                            </Typography>
                                        </Grid>
                                        <Grid style={{textAlign:'center'}} item sm={6} xs={12}>
                                            <Typography variant="subtitle1" display="block">
                                                {weatherInfo.city}
                                            </Typography>
                                        </Grid>
                                        <Grid container justify="space-around" direction="row" alignItems="center" >
                                            <Grid style={{textAlign:'center'}} item sm={4} xs={6}>
                                                <img src={weatherInfo.weatherIcon} alt=""/>
                                            </Grid>
                                            <Grid style={{textAlign:'center'}} item sm={4} xs={6}>
                                                <Typography variant="subtitle1" display="block">{weatherInfo.temp}&#x2103;</Typography>
                                            </Grid>
                                            <Grid style={{textAlign:'center'}} item sm={4} xs={12}>
                                                <Typography variant="subtitle1" display="block">viento: {weatherInfo.wind}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </ReactWOW>
                        </div>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Link target="_blank"
                              href={'https://www.google.com/maps/place/19%C2%B027\'33.7%22N+70%C2%B041\'48.2%22W/@19.4596108,-70.6966309,18.74z/data=!4m13!1m6!3m5!1s0x8eb1cf1f8db84f6f:0xe01b6b3b3f10b8f8!2sSalud+A+Tu+Alcance!8m2!3d19.428027!4d-70.6821002!3m5!1s0x0:0x0!7e2!8m2!3d19.4593595!4d-70.696715'}
                              variant="subtitle1" color="textSecondary">
                            <Paper className={classes.map} elevation={10}>
                                <img className={classes.mapcenter}  src={require('../../images/mapasata.jpg')} alt=""/>
                            </Paper>
                        </Link>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
