import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import YouTube from 'react-youtube';
// import { withTranslation } from '~/i18n';
import imgAPI from '../../../images/imgAPI';

// import yt from '~/youtube';
import useStyles from './banner-style';

export default function Banner() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const classes = useStyles();
  const [play, setPlayed] = useState(false);
  const [player, setPlayer] = useState([]);

  const _onPlay = () => {
    const curTime = player[0].getCurrentTime();
    if (curTime > 0) {
      setPlayed(true);
    }
  };

  useEffect(() => {
    if (isDesktop) {
    }
  }, []);

  const _onEnd = event => {
    event.target.playVideo();
  };

  const _onReady = event => {
    player.push(event.target);
    setPlayer(player);
  };

  const opts = {
    height: '900',
    width: '100%',
    playerVars: {
      autoplay: 1,
      controls: 0,
      rel: 0,
      showinfo: 0,
      mute: 1,
      origin: 'https://localhost:3010'
    }
  };

  return (
    <>
      {isMobile ? <img style={{  height: '100%', width: '100%', }} src={imgAPI.agency[1]} alt="cover"/> : null}
      <div className={isMobile ? classes.root2 : classes.root}>
        <div className={classes.video}>
          <div className={isMobile ? classes.overlay2 : classes.overlay}/>
          {!play && !isMobile ? <img className={classes.imagecenter} src={imgAPI.agency[0]} alt="cover"/> : null}

            <Hidden mdDown>
              <YouTube
                videoId="CAExSTp6C7g"
                opts={opts}
                onReady={_onReady}
                onEnd={_onEnd}
                onPlay={_onPlay}
              />
            </Hidden>

        </div>
      </div>
    </>
  );
}
