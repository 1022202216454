import React, {useState, useEffect, Fragment} from 'react';
import AppBar from '@material-ui/core/AppBar';
import clsx from 'clsx';
import {Badge} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Scrollspy from 'react-scrollspy';
import MobileMenu from './MobileMenu';
import useStyles from './header-style';
import navMenu from './menu';
import '../../../vendors/hamburger-menu.css';
import {useSelector} from 'react-redux';
import {firebase} from '../../../api/firebase'
import {confirmAlert} from "react-confirm-alert";
import Paper from '@material-ui/core/Paper';
import {listRouters} from "../../../router/routers";
import {Link, NavLink} from "react-router-dom";
import {useRouter} from "../../../router/useRouter";

let counter = 0;

function createData(name, url, offset) {
    counter += 1;
    return {
        id: counter,
        name,
        url,
        offset,
    };
}

export default function Header(props) {
    const router = useRouter()
    const [fixed, setFixed] = useState(false);
    let flagFixed = false;
    const shopping_value = useSelector(state => state.shopping_value);
    const handleScroll = () => {
        const doc = document.documentElement;
        const scroll = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
        const newFlagFixed = (scroll > 80);
        if (flagFixed !== newFlagFixed) {
            setFixed(newFlagFixed);
            flagFixed = newFlagFixed;
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, []);



    const classes = useStyles();
    const theme = useTheme();
    const {invert,} = props;
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [menuList] = useState([
        createData(navMenu[0], '#' + navMenu[0], 100),
        createData(navMenu[1], '#' + navMenu[1]),
        createData(navMenu[2], '#' + navMenu[2]),
        createData(navMenu[3], '#' + navMenu[3]),
    ]);
    const [openDrawer, setOpenDrawer] = useState(false);
    const handleOpenDrawer = () => {
        setOpenDrawer(!openDrawer);
    };

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);


    const logOutUser = async () => {
        await firebase.auth().signOut()
    }


    const logOut = () => {
        alert()
    }

    const toContact= async ()=>{
        await router.push('/contacto');
    }

    function alert() {
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <Paper elevation={3} style={{padding: 35, justifyContent: 'center'}}>
                        <h2>¿Deseas cerrar sesión?</h2>
                        <div
                            style={{margin: 25, flexDirection: 'row', display: 'flex', justifyContent: 'space-around'}}>
                            <Button onClick={logOutUser} className={classes.logout} variant="outlined">
                                Cerrar session
                            </Button>
                            <Button onClick={() => onClose()} className={classes.cancel} variant="contained">
                                Cancelar
                            </Button>
                        </div>
                    </Paper>
                );
            }
        });
    }

    return (
        <Fragment>
            {isMobile && (<MobileMenu open={openDrawer} toggleDrawer={handleOpenDrawer}/>)}
            <AppBar
                component="header"
                position="relative"
                id="header"
                className={clsx(
                    classes.header,
                    fixed && classes.fixed,
                    invert && classes.invert,
                    openDrawer && classes.openDrawer
                )}
            >
                <Container fixed={isDesktop}>
                    <div className={classes.headerContent}>
                        <nav className={clsx(classes.navMenu, classes.navLogo, invert && classes.invert)}>
                            <Link className={`navbar-brand`} to="/">
                                <img style={{marginLeft:10}} width={125} src={require("../../../images/logo.png")} alt=""/>
                                <img style={{marginLeft:10}} width={125} src={require("../../../images/logo.png")} alt="logo"/>
                            </Link>
                            {isMobile && (
                                <IconButton style={{marginLeft:'75%'}} onClick={handleOpenDrawer}
                                            className={clsx('hamburger hamburger--spin right', classes.mobileMenu, openDrawer && 'is-active')}>
                                      <span className="hamburger-box">
                                         <span className={clsx(classes.bar, 'hamburger-inner')}/>
                                          </span>
                                </IconButton>
                            )}
                            <ul className='navbar-nav menu ml-auto'>
                                {isDesktop && (
                                    <Scrollspy items={navMenu} currentClassName="active">
                                        {listRouters.map(router => {
                                            if (!router.collapse) {
                                                if (router.anchorLink){
                                                    return (
                                                        <li key={router.label} className={`nav-item`}>
                                                            {invert ? (
                                                                // eslint-disable-next-line
                                                                <Button href={'/' + router.path}>
                                                                    {router.label}
                                                                </Button>
                                                            ) : (
                                                                // eslint-disable-next-line
                                                                <Button component={AnchorLink} offset={0}
                                                                        href={router.path}>
                                                                    {router.label}
                                                                </Button>
                                                            )}
                                                        </li>
                                                    )
                                                }else {
                                                    return(
                                                        <li key={router.label} className={`nav-item`}>
                                                            <Button className={classes.button} onClick={toContact} >
                                                                    {router.label}
                                                            </Button>
                                                        </li>
                                                    )
                                                }
                                            }
                                        })}
                                    </Scrollspy>
                                )}
                            </ul>
                        </nav>

                        <nav className={clsx('navbar navbar-expand-lg menu_one', classes.linkColor)}>
                            <div className={`container `}>
                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul className={`navbar-nav menu ml-auto`}>
                                        {listRouters.map(router => {
                                            if (router.collapse) {
                                                return (
                                                    <li key={router.label} className="dropdown submenu nav-item">
                                                        <Badge invisible={!router.badge} badgeContent={shopping_value} overlap={'circle'}  color="error">
                                                            <Button style={router.style ? router.style : null} data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">{router.iconVisible ?
                                                                    <i style={{fontSize: 25, color: '#fff'}} className={clsx(router.icon)}/> : router.label}
                                                            </Button>
                                                        </Badge>
                                                        <ul role="menu" className="dropdown-menu">
                                                            {
                                                                router.child.map(otherRouter => {
                                                                    return (
                                                                        <li key={router.label+otherRouter.label} className="nav-item"><NavLink exact className="nav-link" to={otherRouter.path}>{otherRouter.label}</NavLink></li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </li>
                                                )
                                            }
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                </Container>
            </AppBar>
        </Fragment>
    );
}
