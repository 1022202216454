import React, { Fragment } from 'react';
import TermsInfo from "../../components/Policy/TermsInfo";
import Decoration from '../../components/FormsLanding/Decoration';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

export default function Terms(props) {
    const { onToggleDark, onToggleDir } = props;
    return (
        <Fragment>
            <div>
                <Header onToggleDark={onToggleDark} onToggleDir={onToggleDir} invert/>
                <Decoration />
                <TermsInfo/>
                <Footer />
            </div>
        </Fragment>
    );
}
