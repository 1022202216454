import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { useText } from '../../theme/common';
import Title from '../Title/TitleSecondary';
import AuthFrame from './AuthFrame';
import useStyles from './form-style';
import {firebase} from '../../api/firebase'
import { useRouter } from '../../router/useRouter'
import { confirmAlert } from "react-confirm-alert";
import Paper from '@material-ui/core/Paper';
import ArrowBack from "@material-ui/icons/ArrowBack";
import Swal from 'sweetalert2'

export default function Login(){
  const router = useRouter()
  const classes = useStyles();
  const text = useText();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [check, setCheck] = useState(false);
  const [values, setValues] = useState({email: '', password: '',});
  const user = firebase.auth().currentUser;

  useEffect(() => {
    getValues().finally()
    if (user) {
      toShop().finally()
    }
  },[]);


  const toShop= async ()=>{
    await router.push('/tienda');
  }

  const toRegister= async ()=>{
    await router.push('/registrarse');
  }

  const getValues= async ()=>{
  let email = await localStorage.getItem('email')
  let password = await localStorage.getItem('password')
    if (email !==null){
      setCheck(true)
      setValues({ ...values, ['email']: email });
      setValues({ ...values, ['password']: password });
    }
  }



  const handleChange = (name, value) => {
    setValues({ ...values, [name]: value });
  };

  const handleCheck = event => {
    setCheck(event.target.checked);
  };

  const handleSubmit = async (event) => {
    if (check) {
      localStorage.setItem('email', values.email);
      localStorage.setItem('password', values.password);
    } else {
      localStorage.removeItem('email');
      localStorage.removeItem('password');
    }
    try {
      event.preventDefault();
      firebase.auth().signInWithEmailAndPassword(values.email, values.password).then(async (response) => {
          await router.push('/tienda');
        }).catch(error=>{
        return  confirmAlert({
          customUI: ({onClose }) => {
            return (
              <Paper elevation={3} style={{padding:35, justifyContent:'center'}} >
                <h2>Error iniciando sesión</h2>
                <p>Correo y/o contraseña son incorrectos</p>

                <div style={{margin:25, flexDirection:'row', display:'flex', justifyContent:'space-around'}}>
                  <Button onClick={toRegister} className={classes.button1}  variant="outlined" >
                    Registrarme
                  </Button>
                  <Button onClick={()=>onClose()} className={classes.button2} variant="contained" >
                    Cerrar
                  </Button>
                </div>
              </Paper>
            );
          }
        });
      })
    } catch (error) {
      return  confirmAlert({
        customUI: ({onClose }) => {
          return (
            <Paper elevation={3} style={{padding:35, justifyContent:'center'}} >
              <h2>Error iniciando sesión</h2>
              <p>Correo y/o contraseña son incorrectos</p>

              <div style={{margin:25, flexDirection:'row', display:'flex', justifyContent:'space-around'}}>
                <Button onClick={toRegister} className={classes.button1}  variant="outlined" >
                  Registrarme
                </Button>
                <Button onClick={()=>onClose()} className={classes.button2} variant="contained" >
                  Cerrar
                </Button>
              </div>
            </Paper>
          );
        }
      });
    }
  };

  const recoverPass = () => {
    if (values.email.length === 0) {
      return (
          Swal.fire({
            title: 'Error',
            text: 'Correo es requerido',
            icon: 'warning',
            confirmButtonText: 'Bien!'
          })
      )
    } else {
      firebase.auth().sendPasswordResetEmail(values.email).then(() => {
        Swal.fire({
          title: 'Cambio de contraseña',
          text: 'caba de recibir un correo electrónico para cambiar su contraseña',
          icon: 'success',
          confirmButtonText: 'Bien!'
        })

      }).catch(() => {
        Swal.fire({
          title: 'Error',
          text: 'El usuario no existe, debe ingresar el usuario correctamente',
          icon: 'error',
          confirmButtonText: 'Bien!'
        })
      });
    }

  }

  const singIn= async ()=>{
    await router.push('/registrarse');
  }

  return (
    <AuthFrame title={'Bienvenido'} subtitle={'Por favor inicie sesión para continuar'}>
      <div>
        <div className={classes.head}>
          <Title align={isMobile ? 'center' : 'left'}>
            Iniciar sesión
          </Title>
          <Button size="small" className={classes.button1}  onClick={singIn}>
            <ArrowBack className={clsx(classes.icon, classes.signArrow)}/>
           Crear una cuenta
          </Button>
        </div>
        <ValidatorForm
          onError={errors => console.log(errors)}
          onSubmit={handleSubmit}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextValidator
                  variant="outlined"
                className={classes.input}
                placeholder={'Correo'}
                onChange={(e)=>handleChange('email', e.target.value)}
                name="email"
                value={values.email}
                validators={['required', 'isEmail']}
                errorMessages={['Este campo es requerido', 'Correo no es valido']}
              />
            </Grid>
            <Grid item xs={12}>
              <TextValidator
                  variant="outlined"
                type="password"
                className={classes.input}
                  placeholder={'Contraseña'}
                validators={['required']}
                onChange={(e)=>handleChange('password',e.target.value)}
                errorMessages={['Este campo es requerido']}
                name="password"
                value={values.password}
              />
            </Grid>
          </Grid>
          <div className={classes.formHelper}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={check}
                  onChange={(e) => handleCheck(e)}
                  color="secondary"
                  value={check}
                  className={classes.check}
                />
              )}
              label={(
                <span className={text.caption}>
                 Recordar  inicio de sesión
                </span>
              )}
            />
            <Button onClick={recoverPass} size="small" className={classes.buttonLink} href="#">
              ¿Olvido su contraseña?
            </Button>
          </div>
          <div className={classes.btnArea}>
            <Button className={classes.button1} style={{fontSize:18}} variant="contained" fullWidth type="submit" color="secondary" size="large">
             Iniciar
            </Button>
          </div>
        </ValidatorForm>
      </div>
    </AuthFrame>
  );
}
