import { makeStyles } from '@material-ui/core/styles';

const featureStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    textAlign: 'center',
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(10),
    }
  },
  carouselContainer: {
    width: '100%'
  },
  [theme.breakpoints.down('xs')]: {
    marginBottom: theme.spacing(10)
  },
  item: {
    '&:focus': {
      outline: 'none'
    }
  },
  nav: {
    background: theme.palette.background.paper,
    width: 50,
    height: 40,
    textAlign: 'center',
    lineHeight: '30px',
    position: 'absolute',
    top: 'calc(50% - 20px)',
    zIndex: 90,
    boxShadow: '0 0 20px 2px rgba(0, 0, 0, 0.2)',
    '& svg': {
      verticalAlign: 'middle',
      transform: theme.direction === 'rtl' ? 'scale(-1)' : 'none'
    },
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  prev: {
    left: theme.spacing(-10),
  },
  next: {
    right: theme.spacing(-10),
  },
}));

export default featureStyles;
