import cort1 from '../../images/portfolio/cort1.jpeg';
import cort2 from '../../images/portfolio/cort2.jpeg';
import cort3 from '../../images/portfolio/cort3.jpeg';
import cort4 from '../../images/portfolio/cort4.jpeg';
import cort5 from '../../images/portfolio/cort5.jpeg';
import cort6 from '../../images/portfolio/cort6.jpg';
import cort8 from '../../images/portfolio/cort8.jpg';
import cort9 from '../../images/portfolio/cort9.jpg';
import cort10 from '../../images/portfolio/cort10.jpg';
import mob31 from '../../images/portfolio/mob31.jpg';
import dise3 from '../../images/portfolio/dise3.jpeg';
import dise4 from '../../images/portfolio/dise4.jpeg';
import dise5 from '../../images/portfolio/dise5.jpeg';
import dise8 from '../../images/portfolio/dise8.jpeg';
import dise9 from '../../images/portfolio/dise9.jpeg';
import dise10 from '../../images/portfolio/dise10.jpeg';
import ga1 from '../../images/portfolio/ga1.jpg';
import ga2 from '../../images/portfolio/ga2.jpg';
import ga3 from '../../images/portfolio/ga3.jpg';
import ga4 from '../../images/portfolio/ga4.jpg';
import ga5 from '../../images/portfolio/ga5.jpg';
import ga6 from '../../images/portfolio/ga6.jpg';
import bom1 from '../../images/portfolio/bom1.jpg';
import bom2 from '../../images/portfolio/bom2.jpg';
import bom3 from '../../images/portfolio/bom3.jpg';
import bom4 from '../../images/portfolio/bom4.jpg';
import mob9 from '../../images/portfolio/mob9.jpeg';
import mob10 from '../../images/portfolio/mob10.jpeg';
import mob11 from '../../images/portfolio/mob11.jpeg';
import mob12 from '../../images/portfolio/mob12.jpeg';
import mob13 from '../../images/portfolio/mob13.jpeg';
import dise1 from '../../images/portfolio/dise1.jpeg';
import dise2 from '../../images/portfolio/dise2.jpeg';
import dise11 from '../../images/portfolio/dise11.jpg';
import dise12 from '../../images/portfolio/dise12.jpg';
import dise13 from '../../images/portfolio/dise13.jpg';
import equip from '../../images/portfolio/equip.jpg';
import mob1 from '../../images/portfolio/mob1.jpeg';
import mob3 from '../../images/portfolio/mob3.jpeg';
import mob4 from '../../images/portfolio/mob4.jpeg';
import mob5 from '../../images/portfolio/mob5.jpeg';
import mob6 from '../../images/portfolio/mob6.jpeg';
import mob2 from '../../images/portfolio/mob2.jpeg';
import mob15 from '../../images/portfolio/mob15.jpeg';
import mob16 from '../../images/portfolio/mob16.jpeg';
import mob17 from '../../images/portfolio/mob17.jpeg';
import mob20 from '../../images/portfolio/mob20.jpg';
import mob21 from '../../images/portfolio/mob21.jpg';
import mob22 from '../../images/portfolio/mob22.jpg';
import mob23 from '../../images/portfolio/mob23.jpg';
import mob24 from '../../images/portfolio/mob24.jpg';
import mob25 from '../../images/portfolio/mob25.jpg';
import mob26 from '../../images/portfolio/mob26.jpg';
import mob27 from '../../images/portfolio/mob27.jpg';
import mob28 from '../../images/portfolio/mob28.jpg';
import mob29 from '../../images/portfolio/mob29.jpg';
import mob30 from '../../images/portfolio/mob30.jpg';
import piso from '../../images/portfolio/piso.jpeg';
import piso1 from '../../images/portfolio/piso1.jpeg';
import piso2 from '../../images/portfolio/piso2.jpeg';
import piso3 from '../../images/portfolio/piso3.jpeg';
import mob8 from '../../images/portfolio/mob8.jpeg';



export const portfolio=[
    {title:'Cortinas', size:'long',category:'cat1',  img:cort1},
    {title:'Cortinas', size:'short',category:'cat1', img:cort2},
    {title:'Cortinas', size:'short',category:'cat1', img:cort3},
    {title:'Cortinas', size:'short',category:'cat1', img:cort4},
    {title:'Cortinas', size:'short',category:'cat1', img:cort5},
    {title:'Cortinas', size:'short',category:'cat1', img:cort6},
    {title:'Cortinas', size:'short',category:'cat1', img:cort8},
    {title:'Cortinas', size:'short',category:'cat1', img:cort9},
    {title:'Cortinas', size:'short',category:'cat1', img:cort10},
    {title:'Mobiliarios', size:'short',category:'cat1', img:mob31},


    {title:'Diseños de infraestructura', size:'short',category:'cat7', img:dise3},
    {title:'Diseños de infraestructura', size:'short',category:'cat7', img:dise4},
    {title:'Diseños de infraestructura', size:'short',category:'cat7', img:dise5},
    {title:'Diseños de infraestructura', size:'short',category:'cat7', img:dise8},
    {title:'Diseños de infraestructura', size:'short',category:'cat7', img:dise9},
    {title:'Diseños de infraestructura', size:'short',category:'cat7', img:dise10},
    {title:'Mobiliarios', size:'long',category:'cat7', img:mob8},

    {title:'Gases', size:'short',category:'cat6', img:ga1},
    {title:'Gases', size:'short',category:'cat6', img:ga2},
    {title:'Gases', size:'short',category:'cat6', img:ga3},
    {title:'Gases', size:'short',category:'cat6', img:ga4},
    {title:'Gases', size:'short',category:'cat6', img:ga5},
    {title:'Gases', size:'short',category:'cat6', img:ga6},

    {title:'Protectores de pared', size:'medium',category:'cat2', img:bom1},
    {title:'Protectores de pared', size:'medium',category:'cat2', img:bom2},
    {title:'Protectores de pared', size:'medium',category:'cat2', img:bom3},
    {title:'Protectores de pared', size:'medium',category:'cat2', img:bom4},


    {title:'Mobiliarios', size:'short',category:'cat3', img:mob9},
    {title:'Mobiliarios', size:'short',category:'cat3', img:mob10},
    {title:'Mobiliarios', size:'short',category:'cat3', img:mob11},
    {title:'Mobiliarios', size:'short',category:'cat3', img:mob12},
    {title:'Mobiliarios', size:'short',category:'cat3', img:mob13},
    {title:'Mobiliarios', size:'short',category:'cat3', img:dise1},
    {title:'Mobiliarios', size:'short',category:'cat3', img:dise2},
    {title:'Mobiliarios', size:'short',category:'cat3', img:dise11},
    {title:'Mobiliarios', size:'short',category:'cat3', img:dise12},
    {title:'Mobiliarios', size:'long',category:'cat3', img:dise13},
    {title:'equip', size:'long',category:'cat3', img:equip},



    {title:'Mobiliarios', size:'long',category:'cat4', img:mob1},
    {title:'Mobiliarios', size:'short',category:'cat4', img:mob3},
    {title:'Mobiliarios', size:'long',category:'cat4', img:mob4},
    {title:'Mobiliarios', size:'short',category:'cat4', img:mob5},
    {title:'Mobiliarios', size:'short',category:'cat4', img:mob6},
    {title:'Mobiliarios', size:'long',category:'cat4', img:mob2},
    {title:'Mobiliarios', size:'short',category:'cat4', img:mob15},
    {title:'Mobiliarios', size:'short',category:'cat4', img:mob16},
    {title:'Mobiliarios', size:'short',category:'cat4', img:mob17},
    {title:'Mobiliarios', size:'long',category:'cat4', img:mob20},
    {title:'Mobiliarios', size:'short',category:'cat4', img:mob21},
    {title:'Mobiliarios', size:'short',category:'cat4', img:mob22},
    {title:'Mobiliarios', size:'short',category:'cat4', img:mob23},
    {title:'Mobiliarios', size:'short',category:'cat4', img:mob24},
    {title:'Mobiliarios', size:'short',category:'cat4', img:mob25},
    {title:'Mobiliarios', size:'short',category:'cat4', img:mob26},
    {title:'Mobiliarios', size:'short',category:'cat4', img:mob27},
    {title:'Mobiliarios', size:'short',category:'cat4', img:mob28},
    {title:'Mobiliarios', size:'short',category:'cat4', img:mob29},
    {title:'Mobiliarios', size:'short',category:'cat4', img:mob30},

    {title:'Piso asepticos', size:'short',category:'cat5', img:piso},
    {title:'Piso asepticos', size:'short',category:'cat5', img:piso1},
    {title:'Piso asepticos', size:'short',category:'cat5', img:piso2},
    {title:'Piso asepticos', size:'short',category:'cat5', img:piso3},

];
