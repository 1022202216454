import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useStyles from './policy-style';

export default function TermsInfo() {
    const classes = useStyles();
    return (
        <div className={classes.policyWrap}>
            <Container maxWidth="md">
                <Grid container>
                    <Grid item md={12} xs={12}>
                        <div className={classes.text}>

                            <Typography>
                                Su acceso y uso de nuestro sitio web están sujetos a los siguientes términos y
                                condiciones sin
                                limitación o calificación, así como a todas las leyes aplicables. Su acceso al sitio
                                web está en
                                consideración para su aceptación de estos Términos y Condiciones, ya sea que usted
                                sea un
                                usuario registrado o no. Al acceder, navegar y usar el Sitio, usted acepta, sin
                                limitación o
                                calificación, estos Términos y condiciones de uso
                            </Typography>

                            <Typography variant="h5">
                                PRÁCTICAS DE PRIVACIDAD
                            </Typography>

                            <Typography>
                                Nuestro Aviso de prácticas de privacidad describe cómo usamos y divulgamos su
                                información
                                personal. Por ejemplo, el Aviso de prácticas de privacidad describe cómo compartimos
                                información
                                con nuestros contratistas, como agentes de seguros certificados y asesores de
                                inscripción
                                certificados, que certificamos para ayudarlo a inscribirse. Al acceder, navegar y
                                usar el Sitio,
                                usted acepta, sin limitación o calificación, nuestro Aviso de prácticas de
                                privacidad. El Aviso
                                de prácticas de privacidad puede cambiar sin previo aviso.
                            </Typography>

                            <Typography>
                                <b>Salud A Tu Alcance</b><br/><br/>
                                Calle Proyecto 3 #28 Reparto Oquet, Santiago De Los Caballeros, RD <br/><br/>
                                {/*<br/>RNC: ####### <br/><br/>*/}
                                Para cualquier duda, pregunta o comentario que quieras hacernos llegar, te facilitamos
                                los
                                siguientes puntos de contacto:<br/><br/>
                                Teléfono:809-489-9843 <br/><br/>
                                Email: info@saludatualcance.com.do
                            </Typography>

                            <Typography variant="h5">
                                INFORMACIÓN VOLUNTARIA POR USTED
                            </Typography>

                            <Typography>
                                Podemos solicitarle información de identificación para brindarle servicios a través
                                de nuestra
                                aplicación en línea. Durante su visita al sitio web. Los tipos de información
                                personal que
                                recopilamos de usted y el propósito por el cual recopilamos esa información se
                                describen en
                                nuestro Aviso de prácticas de privacidad. Puede acceder al Aviso como se describe
                                anteriormente
                                en Prácticas de privacidad.
                            </Typography>

                            <Typography>
                                Cuando nos contacte por correo electrónico, le recomendamos que solo proporcione la
                                información
                                personal que cree que necesitamos para responder a su inquietud. Sin embargo, si no
                                nos
                                proporciona suficiente información, es posible que no podamos comunicarnos con usted
                                o ayudarlo
                                a resolver su inquietud. Podemos utilizar la información para mejorar nuestro
                                servicio o para
                                responder a su solicitud. Podemos
                            </Typography>

                            <Typography>
                                reenviar su correo electrónico a otros empleados estatales que puedan ayudarlo
                                mejor, y este
                                personal puede ser empleado por una agencia diferente. A excepción de las
                                investigaciones
                                autorizadas de cumplimiento de la ley o según lo exija la ley, no compartimos
                                nuestro correo
                                electrónico con ninguna otra organización.
                            </Typography>


                            <Typography variant="h5">
                                INFORMACIÓN RECOPILADA AUTOMÁTICAMENTE CUANDO VISITA ESTE SITIO WEB
                            </Typography>
                            <Typography>
                                Cuando navega por cualquier sitio web, se puede recopilar automáticamente cierta
                                información
                                sobre su visita. Mientras visita este sitio web, se rastreará automáticamente al
                                menos la
                                siguiente información:
                            </Typography>

                            <Typography>
                                <ul>
                                    <li>
                                        La dirección de protocolo de Internet y el nombre de dominio utilizados,
                                        pero no la
                                        dirección de correo electrónico. La dirección del Protocolo de Internet es
                                        un
                                        identificador numérico asignado a su proveedor de servicios de Internet o
                                        directamente a
                                        su computadora. Usamos la dirección de protocolo de Internet para dirigirle
                                        el tráfico de
                                        Internet y generar estadísticas utilizadas en la administración de este
                                        sitio;

                                    </li>
                                    <li>
                                        El tipo de navegador y sistema operativo que utilizó;
                                    </li>
                                    <li>
                                        La fecha y hora en que visitó este sitio;
                                    </li>
                                    <li>
                                        Las páginas web o servicios a los que accedió en este sitio;
                                    </li>
                                    <li>
                                        El sitio web que visitó antes de ingresar a este sitio web;
                                    </li>
                                    <li>
                                        El sitio web que visita al salir de este sitio web;
                                    </li>
                                    <li>
                                        Cantidad de datos enviados desde el servidor web al visitante del sitio web
                                        durante esa
                                        conexión;
                                    </li>
                                    <li>
                                        Cantidad de datos enviados desde el visitante del sitio web al servidor web;
                                    </li>
                                    <li>
                                        Localizador uniforme de recursos (URL) que hace referencia al archivo
                                        solicitado;
                                    </li>
                                    <li>
                                        La solicitud exacta que hizo el usuario;
                                    </li>
                                    <li>
                                        El protocolo de transporte y la versión utilizada;
                                    </li>
                                    <li>
                                        Versión de HTTP utilizada por el software del navegador web del visitante; y
                                    </li>
                                    <li>
                                        Si descargó un formulario, el formulario que se descargó.
                                    </li>
                                </ul>
                            </Typography>

                            <Typography>
                                La información que recopilamos o almacenamos automáticamente se utiliza para mejorar
                                el
                                contenido del sitio web y para ayudarnos a comprender cómo las personas usan nuestro
                                sitio. Esta
                                información no lo identifica a usted personalmente y se utiliza para recopilar
                                información, como
                                estadísticas del sitio web, que se utilizan para fines autorizados. Por ejemplo, los
                                propósitos
                                autorizados pueden incluir evaluar qué información es de interés, determinar las
                                especificaciones técnicas de diseño e identificar el rendimiento del sistema o las
                                áreas
                                problemáticas. Esta información nos ayuda a analizar nuestro sitio web para mejorar
                                continuamente el valor de los materiales disponibles, incluidos, entre otros,
                                nuestros
                                materiales de marketing. Salud A Tu Alcance puede proporcionar o distribuir ciertas
                                listas e
                                informes estadísticos de información reglamentaria según lo dispuesto por la ley,
                                pero no se
                                vende ni distribuye información personal, y todas las protecciones legales
                                relevantes aún se
                                aplican al sitio web. Si participa voluntariamente en una actividad que solicita
                                información
                                específica (es decir, completar una solicitud de asistencia, personalizar el
                                contenido del sitio
                                web, enviar un correo electrónico o participar en una encuesta), se recopilarán
                                datos más
                                detallados.
                            </Typography>

                            <Typography variant="h5">
                                COOKIES
                            </Typography>

                            <Typography>
                                Utilizamos "cookies" para personalizar su experiencia de navegación con el sitio web
                                de Salud A
                                Tu Alcance. Las cookies son simples archivos de texto almacenados en su navegador
                                web para
                                proporcionar un medio de distinguir entre los usuarios de este sitio web. El uso de
                                cookies es
                                una práctica estándar entre los sitios web. Una cookie contiene información de
                                seguimiento única
                                para las listas de páginas que ha visitado y la fecha en que vio por última vez una
                                página
                                específica. También se utiliza para identificar su sesión y distinguir entre los
                                visitantes.
                                Puede rechazar la cookie o eliminar el archivo de cookies de su computadora
                                utilizando
                                cualquiera de los métodos ampliamente disponibles.
                            </Typography>


                            <Typography>
                                Para servirle mejor, ocasionalmente utilizamos "cookies de sesión" para mejorar o
                                personalizar
                                su visita a este sitio web. Las cookies de sesión se pueden crear automáticamente en
                                el
                                dispositivo que usa para acceder al sitio web de esta agencia estatal. No contienen
                                información
                                personal y no comprometen su privacidad o seguridad. Una cookie de sesión se borra
                                durante el
                                funcionamiento de su navegador o cuando su navegador está cerrado.
                            </Typography>


                            <Typography>
                                Si lo desea, puede completar un registro para personalizar este sitio web y permitir
                                que se
                                almacene una "cookie persistente" en el disco duro de su computadora. Esta cookie
                                persistente
                                permitirá que el sitio web lo reconozca cuando vuelva a visitarlo y adapte la
                                información que se
                                le presente en función de sus necesidades e intereses. Salud A Tu Alcance utiliza
                                cookies
                                persistentes solo con su permiso.
                            </Typography>


                            <Typography>
                                El software y el hardware que utiliza para acceder al sitio web le permite rechazar
                                nuevas
                                cookies o eliminar cookies existentes. Rechazar o eliminar estas cookies puede
                                limitar su
                                capacidad de aprovechar algunas características de este sitio web.
                            </Typography>

                            <Typography variant="h5">
                                DERECHO A TENER SU INFORMACIÓN PERSONAL DESECHADA
                            </Typography>


                            <Typography>
                                Si elige, puede descartar cualquier información personal sobre usted sin
                                reutilizarla o distribuirla, siempre que se nos notifique oportunamente.
                            </Typography>

                            <Typography variant="h5">
                                MODIFICACIÓN DE ESTE ACUERDO
                            </Typography>
                            <Typography>
                                Salud A Tu Alcance se reserva el derecho en cualquier momento y a su exclusivo
                                criterio de
                                modificar, suspender o descontinuar el sitio web (o cualquier parte del mismo) con o
                                sin previo
                                aviso. Al continuar usando este sitio web después de cualquier enmienda, usted
                                acepta aceptar
                                dicho cambio
                            </Typography>

                            <Typography variant="h5">
                                PERMISO PARA ACTUAR EN TU NOMBRE
                            </Typography>

                            <Typography>
                                Cualquier instrucción, elección o solicitud que realice en nuestro sitio web se
                                considerará su
                                permiso por escrito a Salud A Tu Alcance para proporcionar información o realizar
                                transacciones
                                en su nombre.
                            </Typography>
                            <Typography variant="h5">
                                CÓMO TE IDENTIFICAMOS
                            </Typography>

                            <Typography>
                                La entrada de su contraseña, número de identificación personal (PIN) y / u otro
                                identificador
                                puede servir como verificación de su identidad. Sin esta información, es posible que
                                no podamos
                                procesar su solicitud.
                            </Typography>

                            <Typography variant="h5">
                                REGISTRO
                            </Typography>

                            <Typography>
                                Ciertas partes del sitio web están limitadas a usuarios registrados y / o permiten
                                que un
                                usuario solicite soporte o servicios en línea ingresando información personal. Su
                                aprobación
                                como usuario registrado queda a exclusivo criterio de Salud A Tu Alcance. Para
                                solicitar un
                                seguro de salud en línea a través de Salud A Tu Alcance, el solicitante deberá crear
                                una cuenta
                                en línea. Este acceso a la cuenta se otorga sujeto al cumplimiento de la ley y estos
                                Términos y
                                condiciones.
                            </Typography>
                            <Typography>
                                Al registrarse, usted acepta que cualquier información que nos proporcione será
                                completa y
                                precisa, que no se registrará con el nombre de otra persona, ni intentará ingresar
                                al sitio web
                                con el nombre de otra persona, y que no adoptará un nombre de usuario que Salud A Tu
                                Alcance, a
                                su exclusivo criterio, considere ofensivo. Usted acepta informarnos de cualquier
                                actualización o
                                cambio en su información de registro.
                            </Typography>

                            <pTypography>
                                Usted acepta mantener la información y contraseña de su cuenta confidencial. Usted
                                acepta
                                notificar de inmediato al Funcionario de Privacidad de Salud A Tu Alcance sobre
                                cualquier uso no
                                autorizado real o presunto de su cuenta al (829) 767-4715.
                            </pTypography>
                            <Typography>
                                Como parte de la creación de su cuenta, es posible que también deba seleccionar
                                preguntas y
                                respuestas secretas que Salud A Tu Alcance puede utilizar para ayudar a verificar su
                                identidad y
                                ayudar a restablecer su contraseña. Algunos servicios pueden requerir seguridad
                                adicional y, en
                                estos casos, se le puede solicitar que cree una clave de seguridad adicional. Salud
                                A Tu Alcance
                                registra permanentemente la dirección IP de la máquina que utiliza para registrarse
                                y cada
                                inicio de sesión posterior. Finalmente, se asignará un número de identificación
                                único a su
                                cuenta que se utilizará para identificar su perfil y la información asociada, como
                                el historial
                                de inicio de sesión y el historial de la aplicación.
                            </Typography>

                            <Typography variant="h5">
                                TÉRMINOS ADICIONALES PARA PRODUCTOS OFRECIDOS A TRAVÉS DE ESTE SITIO WEB
                            </Typography>


                            <Typography>
                                Se pueden aplicar pautas, términos y acuerdos adicionales a los programas y
                                productos ofrecidos
                                a través de este sitio web. Por ejemplo, si selecciona un plan de seguro de salud a
                                través de
                                este sitio web, se pueden aplicar términos separados a su selección. En caso de
                                conflicto entre
                                dichos términos adicionales y estos Términos y condiciones de uso, dichos términos
                                adicionales
                                prevalecerán.
                            </Typography>
                            <Typography variant="h5">
                                SITIOS WEB EXTERIORES
                            </Typography>

                            <Typography>
                                Nuestro sitio web incluye enlaces a sitios web externos que no están operados por
                                Salud A Tu
                                Alcance. Salud A Tu Alcance proporciona estos enlaces a los usuarios para su
                                conveniencia.
                                Salud A Tu Alcance no controla estos sitios web ni revisamos ni controlamos su
                                contenido. No
                                hacemos representaciones ni garantías con respecto al contenido de estos sitios web
                                y no nos
                                hacemos responsables de dichos sitios web. Estos enlaces no son un aval de
                                productos, servicios
                                o información, y no implican una asociación entre Salud A Tu Alcance y los
                                operadores del sitio
                                web vinculado. Si accede a un sitio web externo desde nuestro sitio web, lo hace
                                bajo su propio
                                riesgo. Cuando selecciona un enlace a un sitio web externo, está sujeto a los
                                términos y
                                condiciones del propietario / patrocinadores de ese sitio web externo. Le
                                recomendamos que
                                revise cualquier sitio".
                            </Typography>

                            <Typography variant="h5">
                                CONTENIDO Y PROPIEDAD
                            </Typography>

                            <Typography>
                                Salud A Tu Alcance utiliza datos protegidos por derechos de autor (por ejemplo,
                                fotografías) que
                                pueden requerir permisos adicionales antes de su uso. La marca única del sitio y
                                varios sellos y
                                marcas oficiales no se pueden usar sin permiso. Para utilizar cualquier información
                                en este
                                sitio web que no sea propiedad de Salud A Tu Alcance o que no la haya creado, debe
                                solicitar el
                                permiso directamente de las fuentes propietarias (o tenedoras). Salud A Tu Alcance
                                tendrá el
                                derecho ilimitado de usar para cualquier propósito, sin cargo alguno, toda la
                                información
                                presentada a través de este sitio web, excepto aquellas presentaciones realizadas
                                bajo un
                                contrato legal separado. Salud A Tu Alcance será libre de usar, para cualquier
                                propósito,
                                cualquier idea, concepto o técnica contenida en la información proporcionada a
                                través de este
                                sitio web.
                            </Typography>
                            <Typography>
                                Las leyes de derechos de autor Dominicanas y extranjeras y las convenciones
                                internacionales
                                protegen los contenidos del sitio web. Usted acepta cumplir con todos los avisos de
                                derechos de
                                autor publicados en el sitio web. El sitio web y todo el contenido del sitio web,
                                incluidos,
                                entre otros, texto, archivos, imágenes, gráficos, ilustraciones, audio, video y
                                fotografías en
                                el sitio web (colectivamente, "Contenido") están protegidos por derechos de
                                propiedad
                                intelectual. derechos de propiedad, incluidos, según corresponda y sin limitación,
                                derechos de
                                autor, marcas comerciales, patentes y otros derechos de propiedad intelectual y de
                                propiedad
                                ("Derechos de propiedad intelectual") de Salud A Tu Alcance u otros terceros que
                                hayan otorgado
                                derechos a Salud A Tu Alcance.
                            </Typography>
                            <Typography>
                                Excepto como se indicó anteriormente, no recibe ningún derecho o licencia, por
                                implicación,
                                impedimento o de otro modo, en o bajo ninguna patente, marca registrada, derecho de
                                autor o
                                derecho de propiedad de Salud A Tu Alcance o de terceros a través del uso de este
                                sitio web. No
                                se pueden descargar, republicar, revender, duplicar o "raspar", en su totalidad o en
                                parte,
                                datos de propiedad en el sitio web de Salud A Tu Alcance para ningún fin que no sea
                                el uso
                                personal permitido en estos Términos y Condiciones.
                            </Typography>

                            <Typography variant="h5">
                                CONDUCTA
                            </Typography>

                            <Typography>
                                Usted acepta acceder y usar el sitio web solo para fines legales. Usted es el único
                                responsable
                                del conocimiento y el cumplimiento de todas y cada una de las leyes, estatutos,
                                normas y
                                reglamentos relacionados con su uso del sitio web. Al acceder al sitio web, acepta
                                que no:
                            </Typography>
                            <Typography>
                                <ul>
                                    <li>
                                        Acceda o intente acceder a este sitio web por cualquier medio que no sea a
                                        través de la
                                        interfaz proporcionada por Salud A Tu Alcance, a menos que se le haya
                                        permitido
                                        específicamente hacerlo en un acuerdo por escrito por separado con Salud A
                                        Tu Alcance. Usted
                                        acepta específicamente no acceder ni intentar acceder a este sitio web a
                                        través de ningún
                                        medio automatizado (incluido, entre otros, el uso de scripts, rastreadores
                                        web o raspadores
                                        de pantalla) y se asegurará de cumplir con las instrucciones establecidas en
                                        cualquier
                                        robot. archivo txt presente en el sitio;
                                    </li>
                                    <li>
                                        Usar con fines comerciales o revender cualquiera de los datos derivados de
                                        este sitio web a
                                        menos que se le haya permitido específicamente hacerlo en un acuerdo por
                                        escrito por
                                        separado con Salud A Tu Alcance;
                                    </li>
                                    <li> Utilice el sitio web para cometer un delito penal o para alentar a otros a
                                        participar en
                                        cualquier conducta que constituya un delito penal o genere responsabilidad
                                        civil;
                                    </li>
                                    <li> Publicar o transmitir cualquier contenido discriminatorio, difamatorio,
                                        acosador,
                                        difamatorio, obsceno, pornográfico o ilegal;
                                    </li>
                                    <li> Use el sitio web para hacerse pasar por otras partes o entidades;
                                    </li>
                                    <li> Use el sitio web para cargar cualquier contenido que contenga un virus de
                                        software, "Trojan
                                        Horse" o cualquier otro código de computadora, archivos o programas que
                                        puedan alterar,
                                        dañar o interrumpir la funcionalidad del sitio web o el hardware o software
                                        de cualquier
                                        otra persona que accede al sitio web;
                                    </li>
                                    <li> Cargue, publique, envíe por correo electrónico o transmita cualquier
                                        material que no tenga
                                        derecho a transmitir bajo ninguna ley o bajo una relación contractual;
                                    </li>
                                    <li> Alterar, dañar o eliminar cualquier contenido publicado en el sitio;
                                    </li>
                                    <li> Interrumpir el flujo normal de comunicación de cualquier manera;
                                    </li>
                                    <li>
                                        Reclamar una relación con o representar a cualquier empresa, asociación u
                                        otra organización
                                        con la que no está autorizado para reclamar dicha relación o representar;
                                    </li>
                                    <li>
                                        Publicar o transmitir publicidad no solicitada, materiales promocionales u
                                        otras formas de
                                        solicitud;
                                    </li>
                                    <li>
                                        Publicar cualquier material que infrinja o viole los derechos de propiedad
                                        intelectual de
                                        otro; o
                                    </li>
                                    <li>
                                        Recopilar o almacenar información personal sobre otros.
                                    </li>

                                </ul>
                            </Typography>

                            <Typography variant="h5">
                                AVISO DE SEGURIDAD Y SEGUIMIENTO
                            </Typography>

                            <Typography>
                                Para proteger este sitio web, así como el sistema informático asociado que respalda
                                el sitio web
                                del uso no autorizado y garantizar que el sistema informático funcione
                                correctamente, las
                                personas que acceden a este sitio web y al sistema informático asociado están
                                sujetas a que
                                todas sus actividades sean monitoreadas y registradas por el personal autorizado
                                para hacerlo.
                                El tráfico de la red se monitorea para identificar intentos no autorizados de cargar
                                o cambiar
                                información, o realizar actividades delictivas. Cualquier persona que use este sitio
                                web y el
                                sistema informático asociado consiente expresamente dicha supervisión. Salud A Tu
                                Alcance se
                                reserva el derecho, pero no está obligado a (excepto lo requerido por la ley
                                aplicable) informar
                                cualquier actividad ilegal a todas y cada una de las autoridades reguladoras,
                                administrativas y
                                / o gubernamentales para su enjuiciamiento.
                            </Typography>
                            <Typography>
                                Para fines de seguridad del sitio, Salud A Tu Alcance se reserva el derecho de
                                monitorear
                                cualquier contenido que usted proporcione, pero no estará obligado a hacerlo. Aunque
                                Salud A Tu Alcance
                                no puede monitorear todas las publicaciones en el sitio web, nos reservamos el
                                derecho de eliminar, mover o editar cualquier publicación que viole estos Términos y
                                Condiciones.
                            </Typography>
                            <Typography variant="h5">
                                TERMINACIÓN DE USO
                            </Typography>

                            <Typography>
                                Salud A Tu Alcance puede, a su sola discreción, rescindir o suspender su acceso y
                                uso de este
                                sitio web sin previo aviso y por cualquier motivo, incluso por la violación de estos
                                Términos y
                                Condiciones o por otra conducta que Salud A Tu Alcance cree que es ilegal o
                                perjudicial para
                                otros. En caso de terminación, ya no estará autorizado para acceder al sitio web, y
                                Salud A Tu Alcance
                                utilizará cualquier medio posible para hacer cumplir esta terminación. Salud A Tu
                                Alcance
                                también puede suspender su uso del sitio web o desactivar una identificación de
                                usuario y pedirle que deje de usar el sitio web con o sin previo aviso y con o sin
                                causa. Ante
                                la terminación de este acuerdo,
                            </Typography>

                            <Typography variant="h5">
                                INDEMNIDAD
                            </Typography>

                            <Typography>
                                Usted acepta defender, indemnizar y eximir de responsabilidad a Salud A Tu Alcance y
                                a todos sus
                                empleados, directores, funcionarios y agentes de todas y cada una de las
                                responsabilidades
                                incurridas en relación con cualquier reclamo que surja de cualquier incumplimiento
                                por su parte
                                de estos Términos y condiciones de uso, su uso de los materiales e información aquí
                                contenidos,
                                o su uso inapropiado o ilegal del sitio web. Estos incluyen honorarios de abogados,
                                costos,
                                daños, pérdidas y gastos. Usted acepta cooperar plenamente en la defensa de
                                cualquier reclamo.
                                Salud A Tu Alcance se reserva el derecho de asumir, a su cargo, la defensa y el
                                control
                                exclusivos de cualquier asunto sujeto a indemnización por su parte. Usted acepta no
                                resolver
                                ningún asunto sin el consentimiento por escrito de Salud A Tu Alcance.
                            </Typography>

                            <Typography variant="h5">
                                LIMITACIÓN DE RESPONSABILIDAD
                            </Typography>

                            <Typography>
                                En ningún caso Salud A Tu Alcance o sus empleados serán responsables de ningún daño
                                incidental,
                                indirecto, especial, punitivo, ejemplar o consecuente, ya sea por contrato, agravio
                                o cualquier
                                otra teoría legal, que surja de su uso o incapacidad para usar el sitio web o
                                cualquier otro
                                asunto relacionado con su interacción con el sitio web, incluyendo, entre otros,
                                pérdida de
                                ingresos o ganancias anticipadas, pérdida de buena voluntad, pérdida de negocios,
                                pérdida de
                                datos, falla o mal funcionamiento de la computadora, o cualquier otro daño.
                            </Typography>


                            <Typography variant="h5">
                                REPRESENTACIONES
                            </Typography>

                            <Typography>
                                Por la presente, declara, garantiza y acuerda en beneficio de Salud A Tu Alcance y
                                sus afiliados
                                que: (1) Usted tiene el derecho legal y la autoridad para celebrar este acuerdo y,
                                si lo acepta
                                en nombre de una empresa u otra entidad, obligar a la compañía u otra entidad a los
                                términos de
                                este acuerdo; (2) usted tiene el derecho legal y la autoridad para cumplir con sus
                                obligaciones
                                en virtud de este acuerdo y para otorgar los derechos y licencias descritos en este
                                acuerdo y en
                                cualquier acuerdo adicional aplicable que celebre en relación con cualquiera de los
                                servicios;
                                (3) toda la información que proporcione a Salud A Tu Alcance en relación con este
                                acuerdo y su
                                acceso al sitio web y el uso de los servicios son correctos y actuales.
                            </Typography>

                            <Typography variant="h5">
                                INTEGRACIÓN Y SEPARABILIDAD
                            </Typography>

                            <Typography>
                                Estos Términos y condiciones establecen la comprensión total de las partes con
                                respecto al uso
                                de este sitio web y reemplaza todos y cada uno de los acuerdos o entendimientos
                                orales y
                                escritos anteriores entre las partes con respecto al tema de este acuerdo. El
                                incumplimiento de
                                lo anterior constituirá un incumplimiento de este acuerdo, lo que puede resultar en
                                la
                                terminación inmediata de su cuenta. La exención por parte de Salud A Tu Alcance de
                                un
                                incumplimiento de cualquier disposición de estos Términos y condiciones no
                                funcionará ni se
                                interpretará como una exención de cualquier otro incumplimiento o posterior.
                            </Typography>

                            <Typography>
                                En caso de que alguna disposición de estos Términos y Condiciones se considere
                                inválida, ilegal
                                o por cualquier motivo inaplicable, la disposición inválida, ilegal o inaplicable
                                será separable
                                de las disposiciones restantes. Dicha disposición inválida, ilegal o inaplicable no
                                afectará la
                                validez o aplicabilidad de las disposiciones restantes.
                            </Typography>

                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
