import React, {Fragment} from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import SendIcon from '@material-ui/icons/Send';
import useStyles from './action-style';
import {useRouter} from "../../router/useRouter";


export default function CallAction() {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const classes = useStyles();
    const router = useRouter()

    const toQuotation= async ()=>{
        await router.push('/cotizacion');
    }

    return (
        <Fragment>
            <Container fixed={isDesktop}>
                <div className={classes.root}>
                    <Paper className={classes.paper}>
                        <Grid container alignItems="center">
                            <Grid item md={8} xs={12}>
                                <Typography variant="h4" gutterBottom display="block">
                                    ¿Listo para cotizar?
                                </Typography>
                                <Typography display="block" component="div">
                                    Aprovecha ahora nuestras ofertas de servicios
                                </Typography>
                            </Grid>
                            <Grid item md={4} xs={12} xl={12}>
                                <Grid container alignItems="center">
                                    <Button  onClick={toQuotation} fullWidth size="large" variant="outlined" color="secondary">
                                        Cotizar servicios
                                        <SendIcon className={classes.rightIcon}/>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            </Container>
        </Fragment>
    );
}
