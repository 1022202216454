export class ModelRegister {
  firstName="";
  lastName="";
  phoneNumber="";
  email="";
  identificationCard="";
  workplace="";
  password='';
  confirmPassword='';
}
