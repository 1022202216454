import {
  GET_LIGHT_BOX_SUCCESS,
  SHOPPING_VALUES_SUCCESS,
  SHOPPING_ITEM_SUCCESS,
  CATEGORY_ARTICLES_SUCCESS,
  ARTICLES_SUCCESS,
  ARTICLES_BY_CATEGORY_SUCCESS,
  LOADING_ITEM_SUCCESS
} from '../actions';

const INITIAL_STATE = {
  light_box: {
    toggle: false,
    slide: 0,
  },
  shopping_value: 0,
  shopping_item: [],
  category_articles: [],
  articles: [],
  loading_item:null
};

export default function Reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_LIGHT_BOX_SUCCESS:
      return {
        ...state,
        light_box: action.payload
      };
    case SHOPPING_VALUES_SUCCESS:
      return {
        ...state,
        shopping_value: action.payload
      };
    case SHOPPING_ITEM_SUCCESS:
      return {
        ...state,
        shopping_item: action.payload
      };
    case CATEGORY_ARTICLES_SUCCESS:
      return {
        ...state,
        category_articles: action.payload
      };
    case ARTICLES_SUCCESS:
      return {
        ...state,
        articles: action.payload
      };
    case ARTICLES_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        articles: action.payload
      };
    case LOADING_ITEM_SUCCESS:
      return {
        ...state,
        loading_item: action.payload
      };
    default:
      return state;
  }
}
