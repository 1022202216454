import c1 from '../../images/client/c1.jpg';
import c2 from '../../images/client/c2.jpg';
import c3 from '../../images/client/c3.jpg';
import c4 from '../../images/client/c4.jpg';
import c5 from '../../images/client/c5.jpg';
import c6 from '../../images/client/c6.jpg';
import c7 from '../../images/client/c7.jpg';
import c8 from '../../images/client/c8.jpg';
import c9 from '../../images/client/c9.jpg';
import c10 from '../../images/client/c10.jpg';
import c11 from '../../images/client/c11.jpg';
import c12 from '../../images/client/c12.jpg';
import c13 from '../../images/client/c13.jpg';
import c14 from '../../images/client/c14.jpg';

export const images = [
    {logo:c1, title:''},
    {logo:c2, title:''},
    {logo:c3, title:''},
    {logo:c4, title:''},
    {logo:c5, title:''},
    {logo:c6, title:''},
    {logo:c7, title:''},
    {logo:c8, title:''},
    {logo:c9, title:''},
    {logo:c10, title:''},
    {logo:c11, title:''},
    {logo:c12, title:''},
    {logo:c13, title:''},
    {logo:c14, title:''},
]
