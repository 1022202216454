import React, { Fragment } from 'react';
import Decoration from '../../components/FormsLanding/Decoration';
import Footer from '../../components/Footer/Footer';
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Container from '@material-ui/core/Container';
import Header from '../../components/Header/Header';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CallAction from '../../components/CallAction/CallAction';


const services = ['DISEÑO DE INFRAESTRUCTURA HOSPITALARIAS SEGÚN NORMAS', 'EQUIPAMIENTO DE ALTA GAMA EN GENERAL', 'MANTENIMIENTOS EQUIPOS SECUNDARIOS', 'MOBILIARIOS EN ACERO INOXIDABLE', 'PROTECTORES DE PARED',
  'CORTINAS DE PRIVACIDAD ANTIBACTERIAL', 'ACCESORIOS PARA BAÑOS DE ACCESIBILIDAD UNIVERSAL', 'PISOS EPOXICOS 100% ASEPTICOS', 'RED DE DATA', 'CONTRA INCENDIO', 'SISTEMAS DE GASES MEDICOS', 'SISTEMAS ELECTRICOS'
];

const sectionMargin = margin => (margin * 20);
const useStyles = makeStyles(theme => ({
  spaceBottom: {
    marginBottom: sectionMargin(theme.spacing())
  },
  spaceTop: {
    paddingTop: theme.spacing(12),
  },
  spaceTop2: {
    paddingTop: theme.spacing(10),
  },
  text: {
    alignItems: 'flex-start',
    marginBottom: theme.spacing(3),
  },
  containerWrap: {
    marginTop: theme.spacing(10),
    position: 'relative',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    '& h3': {
      fontFamily: 'Roboto Condensed',
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.type === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark,
      [theme.breakpoints.down('md')]: {
        fontSize: 36
      }
    }
  },
  text2: {
    marginTop: -13,
    textAlign: 'justify',
    fontFamily: 'Roboto Condensed',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('md')]: {
      fontSize: 36
    }
  },
  mainWrap: {
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    background: theme.palette.background.paper,
  },
}));


export default function Engineering(props) {
  const classes = useStyles();
  const { onToggleDark, onToggleDir } = props;
  return (
    <Fragment>
      <div className={classes.mainWrap}>
        <Header onToggleDark={onToggleDark} onToggleDir={onToggleDir} invert/>
        <Container maxWidth="lg" className={classes.containerWrap}>
          <Decoration/>
          <div className={clsx(classes.spaceTop)}>
            <Grid container direction="row" justify="center" alignItems="center">
              <Grid item ms={6} xs={12} md={6}>
                <Grid item ms={12} xs={12} md={12}>
                  <Typography variant="h3" align="center" gutterBottom>
                    Ingeniería Hospitalaria
                  </Typography>
                </Grid>
                <Grid ms={12} xs={12} md={12}>
                  <Typography variant="h6" align="left" gutterBottom>
                    Dentro del margen hospitalario hay varios factores involucrados que son indispensable, lograr la incorporación de los recursos físicos de la institución de servicios de salud (equipamiento médico, instalaciones industriales, planta física y gestión de servicios.
                  </Typography>
                </Grid>
              </Grid>
              <Hidden smDown>
                <Grid item ms={6} xs={12} md={6}>
                  <img width={'100%'} src={require('../../images/home/ingh1.jpg')} alt=""/>
                </Grid>
              </Hidden>
            </Grid>
          </div>

          <div className={clsx(classes.spaceTop2)}>
            <Grid container direction="row" justify="justify" alignItems="center">
              <Hidden smDown>
                <Grid item ms={6} xs={12} md={6}>
                  <img width={'100%'} src={require('../../images/home/ingh3.jpg')} alt=""/>
                </Grid>
              </Hidden>
              <Grid item ms={6} xs={12} md={6}>
                <Grid item ms={12} xs={12} md={12}>
                  <Typography variant="h3" align="center" gutterBottom>
                    Servicios
                  </Typography>
                </Grid>
                <Grid item ms={12} xs={12} md={12}>
                  <Typography variant="h6" align="left" gutterBottom>
                    <List disableTypography={true}>
                      {services.map(item => {
                        return <ListItem>
                          <ListItemText className={clsx(classes.text2)} primary={item}/>
                        </ListItem>;
                      })}
                    </List>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <div className={clsx(classes.spaceTop2,classes.spaceBottom) }>
            <CallAction/>
          </div>
        </Container>
      </div>
      <section className={classes.spaceTop}>
        <Footer/>
      </section>
    </Fragment>
  );
}
