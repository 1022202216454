import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  block: {
    marginTop: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(20)
    }
  },
  paper: {
    padding: theme.spacing(3, 5),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(3, 2),
    }
  },
  icon: {
    color: theme.palette.primary.main,
    // marginRight: theme.spacing(),
    // marginTop: theme.spacing(2),
    // top: theme.spacing(),
    position: 'relative'
  },
  map: {
    cursor:'pointer',
    background: '#dedede',
    overflow: 'hidden',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(10),
      height: 700,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(-6),
      height: 350,
    }
  },

  mapcenter: {
    textAlign: 'center',
    display: 'block',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    width: '100%',
  }

}));

export default useStyles;
