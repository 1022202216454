import React, {Fragment} from 'react';
import Decoration from '../../components/FormsLanding/Decoration';
import Footer from "../../components/Footer/Footer";
import Hidden from '@material-ui/core/Hidden';
import Container from '@material-ui/core/Container';
import Header from "../../components/Header/Header";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import CallAction from '../../components/CallAction/CallAction';
import useStyles from './styles/mission-styles';


export default function CompanyMission(props) {
    const classes = useStyles();
    const {onToggleDark, onToggleDir} = props;
    return (
        <Fragment>
            <div className={classes.mainWrap}>
                <Header onToggleDark={onToggleDark} onToggleDir={onToggleDir} invert/>
                <Container maxWidth="lg" className={classes.containerWrap}>
                    <Decoration/>
                    <div className={clsx(classes.spaceTop)}>
                        <Grid container direction="row" justify="center" alignItems="center">
                            <Grid ms={6} xs={12} md={6}>
                                <Grid ms={12} xs={12} md={12}>
                                    <Typography variant="h3" align="center" gutterBottom>
                                        Misión
                                    </Typography>
                                </Grid>
                                <Grid ms={12} xs={12} md={12}>
                                    <Typography variant="h6" align="center" gutterBottom>
                                        Garantizar los servicios, brindando el mayor beneficio a los usuarios
                                        manteniendo la calidad y eficiencia de los servicios ofertados utilizando
                                        tecnologías adecuadas.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Hidden smDown>
                                <Grid ms={6} xs={12} md={6}>
                                    <img width={'100%'} src={require('../../images/home/mision.jpg')} alt=""/>
                                </Grid>
                            </Hidden>
                        </Grid>
                    </div>
                    <div className={clsx(classes.spaceTop2, classes.spaceBottom)}>
                        <CallAction/>
                    </div>
                </Container>
            </div>
            <section className={classes.spaceTop}>
                <Footer/>
            </section>
        </Fragment>
    );
}

