import { makeStyles } from '@material-ui/core/styles';
import {darken, fade, lighten} from '@material-ui/core/styles/colorManipulator';

const bannerStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.type === 'dark' ? darken(theme.palette.primary.light, 0.8) : lighten(theme.palette.primary.light, 0.8),
    '&:hover': {
      '& $btnPlay': {
        opacity: 1
      }
    },
    [theme.breakpoints.up('md')]: {
      '&:after': {
        content: '""',
        position: 'absolute',
        right: 0,
        width: '75%',
        height: 160,
        background: theme.palette.type === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light,
        transform: theme.direction === 'rtl' ? 'skew(0, -10deg)' : 'skew(0, 10deg)',
        bottom: -55,
        [theme.breakpoints.down('md')]: {
          display: 'none'
        }
      }
    }
  },
  bannerWrap: {
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: 0
    }
  },

  overlay: {
    background: 'rgba(0, 0, 0, 0.001)',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  },
  video: {
    position: 'relative',
    zIndex: 10,
    overflow: 'hidden',
    height: 500,
    width: '100%',
    '& iframe': {
      width: '100%',
      marginTop: -30
    },
    [theme.breakpoints.up('md')]: {
      // marginLeft: '-12%',
       height: 650,
    },
    [theme.breakpoints.up('1400')]: {
      // marginLeft: '-16%'
    },
    background: theme.palette.common.black,
    '& img': {
      minHeight: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '100%',
      }
    }
  },
  button: {
    position: 'relative',
    zIndex: 10,
    overflow: 'hidden',
    // height: 60,
    // backgroundImage: `linear-gradient(120deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    width: '100%',
    color:'#fff',
  },
  rightIcon: {
    marginLeft: theme.spacing(),
    transform: theme.direction === 'rtl' ? 'rotate(180deg)' : 'none'
  }

}));

export default bannerStyles;
