import { makeStyles } from '@material-ui/core/styles';

const policyStyles = makeStyles(theme => ({
    policyWrap: {
        width: '100%',
        minHeight: '90vh',
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(20, 0, 10),
    },
    flex: {
        display: 'flex',
        justifyContent: 'center'
    },

    text: {
        [theme.breakpoints.down('md')]: {
            textAlign: 'center'
        },
        '& h5': {
            fontWeight: theme.typography.fontWeightBold,
            marginBottom: theme.spacing(3)
        },
        '& p': {
            fontSize: 18,
            color: theme.palette.text.secondary,
            marginBottom: theme.spacing(2)
        }
    },
}));

export default policyStyles;
