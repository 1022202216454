import React, { useState } from 'react';
import clsx from 'clsx';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import SendIcon from '@material-ui/icons/Send';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import routeLink from '../../const/link';
import { useText } from '../../theme/common';
import logo from '../../images/agency-logo.svg';
import useStyles from './form-style';
import { firebase } from '../../api/firebase';
import {NavLink} from "react-router-dom";
import Paper from "@material-ui/core/Paper";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


export default function Contact() {
  const classes = useStyles();
  const text = useText();
  const [values, setValues] = useState({
    name: '',
    email: '',
    phone: '',
    company: '',
    message: ''
  });
  const [openNotif, setNotif] = useState(false);

  const [check, setCheck] = useState(false);

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleCheck = event => {
    setCheck(event.target.checked);
  };



  const handleClose = () => {
    setNotif(false);
  };

  const handleSubmit = () => {
    const timestamp = firebase.firestore.FieldValue.serverTimestamp();
    firebase.firestore().collection("contact").add({
      name: values.name,
      email: values.email,
      phone: values.phone,
      company: values.company,
      message: values.message,
      createdAt: timestamp
    }).then(() => {
      setNotif(true);
    }).catch(err => {
      console.log(err)
    });
  };

  return (
    <div className={classes.formWrap}>
      <Snackbar
          anchorOrigin={{vertical: 'top', horizontal: 'right'}}
          key="top right"
          open={openNotif}
          autoHideDuration={6000}
          onClose={handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}>
        <Alert severity="success">
          Formulario enviado
        </Alert>
      </Snackbar>
      <Hidden mdUp>
        <div className={clsx(classes.logo, classes.logoHeader)}>
          <NavLink exact className="nav-link" to={'/'}>
            <img src={logo} alt="logo"/>
          </NavLink>
        </div>
      </Hidden>
      <Hidden smDown>
        <NavLink exact className="nav-link" to={'/'}>
          <IconButton className={classes.backtohome}>
            <i className="ti-home"/>
            <i className="ti-arrow-left"/>
          </IconButton>
        </NavLink>
      </Hidden>
      <Container maxWidth="md">
        <Typography variant="h3" gutterBottom className={text.title}>
          Contacto
        </Typography>
        <Typography className={clsx(classes.desc, text.subtitle2)}>
          ¿Tiene usted alguna pregunta? ¿Quiere conocer más de nuestros servicios? ¿O simplemente quieres saludar? Déjanos un mensaje.
        </Typography>
        <div className={classes.form}>
          <ValidatorForm
            onSubmit={handleSubmit}
            onError={errors => console.log(errors)}
          >
            <Grid container spacing={6}>
              <Grid item sm={6} xs={12}>
                <TextValidator
                  InputProps={{
                    className: classes.input
                  }}
                  InputLabelProps={{
                    className: classes.input
                  }}
                  InputProps={{
                    className: classes.input
                  }}
                  className={classes.input}
                  label={"¿Cual es su nombre?*"}
                  onChange={handleChange('name')}
                  name="Name"
                  value={values.name}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextValidator
                  InputProps={{
                    className: classes.input
                  }}
                  InputLabelProps={{
                    className: classes.input
                  }}
                  InputProps={{
                    className: classes.input
                  }}
                  className={classes.input}
                  label={"¿Cual es su correo?*"}
                  onChange={handleChange('email')}
                  name="Email"
                  value={values.email}
                  validators={['required', 'isEmail']}
                  errorMessages={['this field is required', 'email is not valid']}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextValidator
                  InputProps={{
                    className: classes.input
                  }}
                  InputLabelProps={{
                    className: classes.input
                  }}
                  InputProps={{
                    className: classes.input
                  }}
                  className={classes.input}
                  label={"¿Cual es su teléfono?*"}
                  onChange={handleChange('phone')}
                  name="Phone"
                  value={values.phone}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextValidator
                  InputProps={{
                    className: classes.input
                  }}
                  InputLabelProps={{
                    className: classes.input
                  }}
                  InputProps={{
                    className: classes.input
                  }}
                  className={classes.input}
                  label={"¿Cual es el nombre de su empresa?"}
                  onChange={handleChange('company')}
                  name="Company"
                  value={values.company}
                />
              </Grid>
              <Grid item xs={12}>
                <TextValidator
                  InputProps={{
                    className: classes.input
                  }}
                  InputLabelProps={{
                    className: classes.input
                  }}
                  InputProps={{
                    className: classes.input
                  }}
                  multiline
                  rows="6"
                  className={classes.input}
                  label={"Escriba su mensaje aquí"}
                  onChange={handleChange('message')}
                  name="Message"
                  value={values.message}
                />
              </Grid>
            </Grid>
            <div className={classes.btnArea}>
              <FormControlLabel
                control={
                  <Checkbox className={classes.checked} checked={check} onChange={(e) => handleCheck(e)} color={'primary'} value="check" />
                }
                label={(
                  <span> He leído y acepto los Términos de
                    <br />
                    <a target={'_blank'} href="/privacy">
                    Servicio y política de privacidad *
                    </a>
                  </span>
                )}
              />
              <Button className={classes.button} disabled={!(check && values.phone.length>5 && values.email.length>5 && values.name.length>3)} variant="outlined" type="submit"  size="large">
                Enviar mensaje
                <SendIcon className={classes.rightIcon} />
              </Button>
            </div>
          </ValidatorForm>
        </div>
      </Container>
    </div>
  );
}
