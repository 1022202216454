import React from 'react';
import useStyles from './cards-style';
import Paper from '@material-ui/core/Paper';

export default function SimpleThumb(props) {
  const classes = useStyles();
  const { logo } = props;
  return (
    <div className={classes.simpleThumb}>
      <div className={classes.figure}>
        <Paper style={{padding:10, margin:3}} elevation={0}>
          <img  width={180} height={180} src={logo} alt={'logo' + logo.toString()}/>
        </Paper>
      </div>
    </div>
  );
}
