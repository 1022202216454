import React, { useState, useEffect, useMemo } from 'react';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import routeLink from '../../const/link';
import { useText } from '../../theme/common';
import Title from '../Title/TitleSecondary';
import AuthFrame from './AuthFrame';
import useStyles from './form-style';
import { firebase } from '../../api/firebase';
import {ModelRegister} from '../../model';
import {useRouter} from '../../router/useRouter'
import { confirmAlert } from 'react-confirm-alert';
import Paper from '@material-ui/core/Paper';
import ArrowBack from "@material-ui/icons/ArrowBack";

export default function Register() {
  const router = useRouter()
  const classes = useStyles();
  const text = useText();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [check, setCheck] = useState(false);
  const [isDoctor, setIsDoctor] = useState(false);
  const user = firebase.auth().currentUser;
  const [values, setValues] = useState(new ModelRegister());

  useEffect(() => {
    if (user) {
      toShop().finally()
    }

  }, []);


  const toShop= async ()=>{
    await router.push('/tienda');
  }

  useMemo(()=>{
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      if (value !== values.password) {
        return false;
      }
      return true;
    });
  },[values.confirmPassword])

  const handleChange = (name, event) => {
    setValues({...values, [name]: event.target.value});
  };

  const handleCheck = event => {
    setCheck(event.target.checked);
  };

  const toLogin= async ()=>{
    await router.push('/inicio');
  }


  const handleSubmit = async (event) => {
    event.preventDefault();
    try{
      const {user} = await firebase.auth().createUserWithEmailAndPassword(values.email, values.password);
      generateUserDocument(user).finally();
    }
    catch(error){
        return  confirmAlert({
          customUI: ({onClose }) => {
            return (
              <Paper elevation={3} style={{padding:35, justifyContent:'center'}} >
                <h2>Error al registrarse</h2>
                <p>Es posible que este correo ya este registrado con nosotros, inténtelo más tarde o inicie sesión.</p>

                <div style={{margin:25, flexDirection:'row', display:'flex', justifyContent:'space-around'}}>
                  <Button onClick={toLogin} className={classes.button1}  variant="outlined" >
                    Iniciar sesión
                  </Button>
                  <Button onClick={()=>onClose()} className={classes.button2} variant="contained" >
                    Cerrar
                  </Button>
                </div>
              </Paper>
            );
          }
        });
    }
  };


  const generateUserDocument = async (user) => {
    if (!user) return;
    const userRef = firebase.firestore().doc(`clients/${user.uid}`);
    const snapshot = await userRef.get();
    const update = {displayName: values.firstName + ' ' + values.lastName};
    const timestamp = firebase.firestore.FieldValue.serverTimestamp();
    await firebase.auth().currentUser.updateProfile(update);
    if (!snapshot.exists) {
      try {
        await userRef.set({
          createdAt: timestamp,
          id:user.uid,
          firstName:values.firstName,
          lastName:values.lastName,
          phoneNumber:values.phoneNumber,
          email:values.email,
          type:isDoctor?'Medico':'Cliente',
          identificationCard:values.identificationCard,
          workplace:values.workplace,
          displayName:values.firstName+ ' '+values.lastName ,
          photoURL:null,
        });
        await router.push('/tienda');
      } catch (error) {
        console.error('Error creating user document', error);
      }
    }

    return console.log(user.uid);
  };

  const logIn= async ()=>{
    await router.push('/iniciar-sesion');
  }

  return (
    <AuthFrame title={'Gusto en conocerlo'} subtitle={'Solo regístrate para unirte a nosotros'}>
      <div>
        <div className={classes.head}>
          <Title align={isMobile ? 'center' : 'left'}>Registrarse </Title>
          <Button size="small" className={classes.button1} onClick={logIn}>
            <ArrowBack className={clsx(classes.icon, classes.signArrow)}/>
            ¿Ya posees una cuenta?
          </Button>
        </div>
        <ValidatorForm
          onError={errors => console.log(errors)}
          onSubmit={handleSubmit}
        >
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextValidator
                variant="outlined"
                placeholder={'Nombre'}
                className={classes.input}
                onChange={(v)=>handleChange('firstName', v)}
                name="firstName"
                value={values.firstName}
                validators={['required']}
                errorMessages={['Este campo es requerido']}
              />
            </Grid>
            <Grid item xs={6}>
              <TextValidator
                variant="outlined"
                placeholder={'Apellido'}
                className={classes.input}
                onChange={(v)=>handleChange('lastName',v)}
                name="lastName"
                value={values.lastName}
                validators={['required']}
                errorMessages={['Este campo es requerido']}
              />
            </Grid>
            <Grid item xs={6}>
              <TextValidator
                variant="outlined"
                className={classes.input}
                placeholder={'Correo'}
                onChange={(v)=>handleChange('email',v)}
                name="email"
                value={values.email}
                validators={['required', 'isEmail']}
                errorMessages={['Este campo es requerido', 'Correo no es valido']}
              />
            </Grid>
            <Grid item xs={6}>
              <TextValidator
                variant="outlined"
                className={classes.input}
                placeholder={'teléfono o celular'}
                onChange={(v)=>handleChange('phoneNumber',v)}
                name="phoneNumber"
                value={values.phoneNumber}
                validators={['required']}
                errorMessages={['Este campo es requerido']}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={isDoctor}
                    onChange={(e) => setIsDoctor(e.target.checked)}
                    color="secondary"
                    value={isDoctor}
                    className={classes.check}
                  />
                )}
                label={(<span> ¿Eres doctor? </span>)}
              />
            </Grid>
            {isDoctor && (<>
            <Grid item xs={6}>
              <TextValidator
                variant="outlined"
                className={classes.input}
                placeholder={'Cedula'}
                onChange={(v)=>handleChange('identificationCard',v)}
                name="identificationCard"
                value={values.identificationCard}
                validators={['required']}
                errorMessages={['Este campo es requerido']}
              />
            </Grid>

            <Grid item xs={6}>
              <TextValidator
                variant="outlined"
                className={classes.input}
                placeholder={'Lugar de trabajo'}
                onChange={(v)=>handleChange('workplace',v)}
                name="workplace"
                value={values.workplace}
                validators={['required']}
                errorMessages={['Este campo es requerido']}
              />
            </Grid>
            </>) }
            <Grid item md={6} xs={12}>
              <TextValidator
                variant="outlined"
                type="password"
                className={classes.input}
                placeholder={'Contraseña'}
                validators={['required']}
                onChange={(v)=>handleChange('password',v)}
                errorMessages={['Este campo es requerido']}
                name="password"
                value={values.password}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextValidator
                variant="outlined"
                type="password"
                className={classes.input}
                placeholder={'Confirmar contraseña'}
                validators={['isPasswordMatch', 'required']}
                errorMessages={['contraseña no coinciden', 'Este campo es requerido']}
                onChange={(v)=>handleChange('confirmPassword',v)}
                name="confirmPassword"
                value={values.confirmPassword}
              />
            </Grid>
          </Grid>
          <div className={classes.btnArea}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={check}
                  onChange={(e) => handleCheck(e)}
                  color="secondary"
                  value={check}
                  className={classes.check}
                />
              )}
              label={(
                <span className={text.caption}>
                He leído y acepto los
                  &nbsp;
                  <a href="/terminos">
                  Términos
                 </a>
                  &nbsp;
                  y
                  &nbsp;
                  <a href="/privacidad">
                    política de privacidad
                  </a>
                </span>
              )}
            />
            <Button className={classes.button1} style={{fontSize:18}} disabled={!(check && values.password.length >5)} variant="contained" type="submit" color="secondary" size="large">
              Registrarme
            </Button>
          </div>
        </ValidatorForm>
      </div>
    </AuthFrame>
  );
}
