import React, { useState, useEffect } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import YouTube from 'react-youtube';
import imgAPI from '../../images/imgAPI';
import CrossParallax from '../Parallax/Cross';
import useStyles from './banner-style';
import { Row, Col } from 'reactstrap';
import Slides from '../CarucelOnVideo';
import { firebase } from '../../api/firebase';
import TitleIcon from '../Title/WithIcon';



export default function Banner() {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [play, setPlayed] = useState(false);
  const [player, setPlayer] = useState([]);
  const [carouselServicesUp, setCarouselServicesUp] = useState([]);
  const [carouselServicesDown, setCarouselServicesDown] = useState([]);

  useEffect(() => {
    const servicesCarouselCover = firebase.firestore()
      .collection('carousel_services')
      .orderBy('createdAt', 'desc')
      .limit(10)
      .onSnapshot(documentSnapshots => {
        let Up = [];
        let Down = [];
        documentSnapshots.forEach(doc => {
          let data = doc.data();
          data['id'] = doc.id;
          if (data.position.value === 'up') {
            Up.push(data);
          } else {
            Down.push(data);
          }
        });
        setCarouselServicesUp(Up)
        setCarouselServicesDown(Down)
      });

    return () => {
      servicesCarouselCover()
    }


  }, []);

  const _onEnd = event => {
    event.target.playVideo();
  };

  const _onPlay = () => {
    const curTime = player[0].getCurrentTime();
    if (curTime > 0) {
      setPlayed(true);
    }
  };

  const _onReady = event => {
    player.push(event.target);
    setPlayer(player);
  };


  const opts = {
    // height: '720',
    // width: '100%',
    height: '800',
    width: '100%',
    playerVars: { // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      controls: 0,
      rel: 0,
      showinfo: 0,
      mute: 1,
      origin: 'https://saludatualcance.com.do/'
    }
  };

  return (
    <div className={classes.root}>
      {isMobile &&
      <div style={{textAlign:'center', alignSelf:'center', marginBottom:15}} >
        <TitleIcon text={'Más de nosotros'} icon="apps" extended/>
      </div>
      }
      <div className={classes.bannerWrap}>
        <Row>
          {isDesktop &&
          <Col md={9}>
            <div className={classes.video}>
              <div className={classes.overlay}/>
              {!play || isMobile ? <img src={imgAPI.agency[0]} alt="cover"/> : null}

                <YouTube
                  videoId="XWONOBvCGI4"
                  opts={opts}
                  onReady={_onReady}
                  onEnd={_onEnd}
                  onPlay={_onPlay}
                />
            </div>
          </Col>
          }
          {isDesktop &&
          <Col style={{ marginLeft: '-1%', marginTop: 10 }} md={3}>
            <Row className={classes.button} >
              <Col md={12}>
                <Slides images={carouselServicesUp}/>
              </Col>
            </Row>
            <Row className={classes.button}>
              <Col md={12}>
                <Slides images={carouselServicesDown}/>
              </Col>
            </Row>
          </Col>
          }

        </Row>
        {isDesktop &&
        <div className={classes.parallax}>
          <CrossParallax/>
        </div>
        }
      </div>
    </div>
  );
}
