import React from 'react';
import useStyles from './form-style';
import deco from '../../assets/images/crypto/form-deco.svg'

export default function Decoration() {
    const classes = useStyles();

    return (
        <div className={classes.deco}>
            <img className={classes.svg} alt={'deco'} src={deco}/>
        </div>

    );
}
