import React from 'react';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import useStyles from './shop-style';

export default function  CoverDetails({sectionR, sectionL}) {
  const classes = useStyles();
  return (
    <>
      {sectionR &&
        <>
      <hr/>
      <Grid style={{ marginTop: 15, marginBottom: 30 }} spacing={2} container direction="row" justify="flex-start" alignItems="center">
        <Grid item xs={12} xl={12} sm={8} md={8} lg={8} className={clsx(classes.gridItem)}>
          <img alt={sectionR.title} width={'100%'} style={{
            alignSelf: 'center',
            borderRadius: 8
          }} src={sectionR.imageUrl}/>
        </Grid>

        <Grid item xs={12} xl={12} sm={4} md={4} lg={4} className={clsx(classes.gridItem)}>
          <Typography variant="h3" align="center" gutterBottom>
          {sectionR.title}
          </Typography>
        </Grid>
      </Grid>
        </>
      }
      {sectionL &&
      <>
      <hr/>
      <Grid style={{ marginTop: 15, marginBottom: 80 }} spacing={2} container direction="row" justify="flex-start" alignItems="center">
        <Grid item xs={12} xl={12} sm={4} md={4} lg={4} className={clsx(classes.gridItem)}>
          <Typography variant="h3" align="center" gutterBottom>
            {sectionL.title}
          </Typography>
        </Grid>
        <Grid item xs={12} xl={12} sm={8} md={8} lg={8} className={clsx(classes.gridItem)}>
          <img alt={sectionL.title} width={'100%'} style={{ alignSelf: 'center', borderRadius: 8 }} src={sectionL.imageUrl}/>
        </Grid>
      </Grid>
        </>
      }
    </>
  );

}
