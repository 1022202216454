import React, { Fragment } from 'react';
import ContactFrm from '../../components/FormsLanding/Contact';
import useStyles from '../../components/FormsLanding/form-style';

export default function Contact() {
  const classes = useStyles();
  return (
    <Fragment>
      <div>
        <img className={classes.imgBg} src={require('../../images/home/contact.jpg')} alt=""/>
        <ContactFrm />
      </div>
    </Fragment>
  );
}
