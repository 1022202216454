const link = {
  agency: {
    home: '/',
    contact: '/contacto',
    login: '/inicio',
    register: '/registrarse'
  }
};

export default link;
