
export const listRouters =[
    {
        collapse:false,
        visible:true,
        anchorLink:true,
        iconVisible:false,
        name: "about",
        url: "/about",
        path: "#about",
        label:'Sobre nosotros',
    },
    {
        visible:true,
        collapse:false,
        anchorLink:true,
        iconVisible:false,
        path: "#services",
        name: "services",
        url: "/services",
        label:'Servicios',
    },
    {
        visible:true,
        collapse:false,
        iconVisible:false,
        anchorLink:true,
        path: "#our-expertise",
        name: "our-expertise",
        url: "/our-expertise",
        label:'Nuestro trabajo',
    },
    {
        visible:true,
        collapse:false,
        iconVisible:false,
        anchorLink:true,
        path: "#case-studies",
        name: "case-studies",
        url: "/case-studies",
        label:'Portafolio',
    },
    {
        visible:true,
        collapse:false,
        anchorLink:false,
        iconVisible:false,
        path: "/contacto",
        url: "#contacto",
        name: "contacto",
        label:'Contacto',
    },

    {
        visible:true,
        collapse: true,
        anchorLink:false,
        path: "/profile",
        name: "profile",
        label:'profile',
        iconVisible:true,
        url: "",
        icon: "ti-user",
        style:{marginRight:-25},
        child: [
            {
                visible:true,
                path: "/iniciar-sesion",
                label:'Iniciar',
                name: "login",
                url: "",
                icon: "mdi mdi-view-dashboard",
            },
            {
                visible:true,
                path: "/registrarse",
                label:'Registrarse',
                name: "register",
                url: "",
                icon: "mdi mdi-view-dashboard",
            }
        ]
    },
    {
        visible:true,
        anchorLink:false,
        collapse: true,
        iconVisible:true,
        path: "/cart",
        url: "",
        name: "cart",
        label:'Carrito',
        badge:true,
        style:{marginLeft:-15},
        icon: "ti-shopping-cart",
        // icon: "ti-shopping-cart-full",
        child: [
            {
                visible:true,
                path: "/tienda",
                name: "store",
                url: "",
                label:'Tienda',
                icon: "mdi mdi-view-dashboard",
            },
            {
                visible:true,
                url: "",
                path: "/carrito-de-compras",
                label:'Carrito',
                name: "cart",
                icon: "mdi mdi-view-dashboard",
            }
        ]
    },
]
