import React, {useState} from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import logo from '../../assets/images/crypto-logo.png';
import fb from '../../assets/images/facebook2x.png';
import Ins from '../../assets/images/instagram2x.png';
import EM from '../../assets/images/em.png';
import PH from '../../assets/images/ph.png';
import WO from '../../assets/images/wo.png';
import logoW from '../../assets/images/crypto-logoW.png';
import Parallax from '../Parallax/Hexagonal';
import Decoration from './Decoration';
import useStyles from './form-style';
import moment from "moment";
import {firebase} from "../../api/firebase";
import {Collapse} from 'reactstrap';
import KeyboardArrowRight from '@material-ui/icons/ChevronRight';
import KeyboardArrowDown from '@material-ui/icons/ExpandMore';
import {servicesIng} from '../../const/services'
import deco from "../../assets/images/crypto/deco-benefit-colored.svg";
import {NavLink} from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Contact() {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [isOpenBio, setIsOpenBio] = useState(false);
    const [isOpenIng, setIsOpenIng] = useState(false);
    const [values, setValues] = useState({
        name: '',
        email: '',
        phone: '',
        company: '',
        message: '',
        date: moment().format('YYYY-MM-DD'),
        rnc: '',
        sector: 'La Esmeralda',
    });

    const [openNotif, setNotif] = useState(false);

    const [desinfeccionElectrostatica, setDesinfeccionElectrostatica] = useState(false);
    const [limpiezaProfundaSuperficie, setLimpiezaProfundaSuperficie] = useState(false);
    const [controlPlagas, setControlPlagas] = useState(false);
    const [productosDesinfeccion, setProductosDesinfeccion] = useState(false);
    const [igualas, setIgualas] = useState(false);
    const [sendActive, setSendActive] = useState(false);
    const [sendActiveText, setSendActiveText] = useState(false);
    const [sendText, setSendText] = useState('');

    const [servicesIngSelected, setServicesIngSelected] = useState({
        '1': false,
        '2': false,
        '3': false,
        '4': false,
        '5': false,
        '6': false,
        '7': false,
        '8': false,
        '9': false,
        '10': false,
        '11': false,
        '12': false
    });

    const handleChange = name => event => {
        setValues({...values, [name]: event.target.value});
    };

    const handleCheckDesinfeccionElectrostatica = event => {
        setDesinfeccionElectrostatica(event.target.checked);
    };

    const handleCheckLimpiezaProfundaSuperficie = event => {
        setLimpiezaProfundaSuperficie(event.target.checked);
    };

    const handleCheckControlPlagas = event => {
        setControlPlagas(event.target.checked);
    };

    const handleCheckProductosDesinfeccion = event => {
        setProductosDesinfeccion(event.target.checked);
    };

    const handleCheckIgualas = event => {
        setIgualas(event.target.checked);
    };


    const handleSubmit = () => {
        if (!sendActive) {
            const timestamp = firebase.firestore.FieldValue.serverTimestamp();

            firebase.firestore().collection("quotation").add({
                ...values,
                createdAt: timestamp,
                desinfeccionElectrostatica: desinfeccionElectrostatica,
                limpiezaProfundaSuperficie: limpiezaProfundaSuperficie,
                controlPlagas: controlPlagas,
                productosDesinfeccion: productosDesinfeccion,
                igualas: igualas,
                servicesIng: servicesIngSelected
            }).then(() => {
                setNotif(true);
                setSendActive(true);
                setSendActiveText(true);
                setSendText(`Hola ${values.name}!, ya acabas de enviarnos el formulario, pronto nos comunicaremos con usted.`)
            })
        } else {
            setSendActiveText(true)
        }
    };

    const handleClose = () => {
        setNotif(false);
    };


    const handleServicesIng = (event) => {
        setServicesIngSelected(prev => ({...prev, [event.target.name]: event.target.checked}));
    };

    return (
        <div className={classes.pageWrap}>
            <Decoration/>
            <div style={{position: 'absolute', top: '50%'}}>
                <img className={classes.svg} alt={'deco'} src={deco}/>
            </div>
            <div style={{position: 'absolute', top: '30%', right: '5%'}}>
                <img className={classes.svg} alt={'deco'} src={deco}/>
            </div>
            <div style={{position: 'absolute', top: '80%', right: '10%'}}>
                <img className={classes.svg} alt={'deco'} src={deco}/>
            </div>
            <div className={clsx(classes.parallax, classes.parallaxLeft)}>
                <Parallax/>
            </div>
            <div className={clsx(classes.parallax, classes.parallaxRight)}>
                <Parallax/>
            </div>

            <Snackbar
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                key="top right"
                open={openNotif}
                autoHideDuration={6000}
                onClose={handleClose}
                ContentProps={{'aria-describedby': 'message-id',}}
            >
                <Alert severity="success">
                    Formulario enviado
                </Alert>
            </Snackbar>
            <Hidden mdUp>
                <div className={clsx(classes.logo, classes.logoHeader)}>
                    <img src={logoW} alt="logo"/>
                </div>
            </Hidden>
            <Container maxWidth="md" className={classes.innerWrap}>
                <Paper className={clsx(classes.formBox, 'fragment-fadeUp')}>
                    <div className={classes.fullFromWrap}>
                        <Hidden mdUp>
                            <NavLink exact className="nav-link" to={'/'}>
                                <IconButton className={classes.backtohome2}>
                                    <i className="ti-arrow-left"/>
                                </IconButton>
                            </NavLink>
                        </Hidden>
                        <NavLink exact className="nav-link" to={'/'}>
                            <IconButton className={classes.backtohome}>
                                <i className="ti-home"/>
                                <i className="ti-arrow-left"/>
                            </IconButton>
                        </NavLink>

                        <Hidden mdDown>
                            <div className={clsx(classes.logoPaper, classes.logoHeader)}>
                                <img src={logo} alt="logo"/>
                            </div>
                        </Hidden>

                        <Typography
                            variant="h3"
                            align="center"
                            style={{fontSize: 33, color: '#4caf50'}}
                            className={clsx(classes.title)}
                            gutterBottom
                        >
                            COTIZACIÓN DE SERVICIOS
                        </Typography>
                        <div className={classes.form}>
                            <ValidatorForm
                                onSubmit={handleSubmit}
                                onError={errors => console.log(errors)}
                            >
                                <Grid container spacing={6}>
                                    <Grid item md={6} xs={12}>
                                        <TextValidator
                                            variant="filled"
                                            className={classes.input}
                                            label={'Nombre'}
                                            onChange={handleChange('name')}
                                            name="Name"
                                            value={values.name}
                                            validators={['required']}
                                            errorMessages={['Este campo es requerido']}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextValidator
                                            variant="filled"
                                            className={classes.input}
                                            label={'Teléfono o celular'}
                                            onChange={handleChange('phone')}
                                            name="Phone"
                                            validators={['required']}
                                            errorMessages={['Este campo es requerido']}
                                            value={values.phone}
                                        />
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <TextValidator
                                            variant="filled"
                                            className={classes.input}
                                            label={'Correo'}
                                            onChange={handleChange('email')}
                                            name="Email"
                                            value={values.email}
                                            validators={['required', 'isEmail']}
                                            errorMessages={['Este campo es requerido', 'El correo no es válido']}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextValidator
                                            variant="filled"
                                            className={classes.input}
                                            label={'Sector'}
                                            onChange={handleChange('sector')}
                                            name="sector"
                                            value={values.sector}
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextValidator
                                            variant="filled"
                                            className={classes.input}
                                            label={'Nombre de la empresa'}
                                            onChange={handleChange('company')}
                                            name="Company"
                                            value={values.company}
                                        />
                                    </Grid>
                                    {values.company.length > 0 &&
                                    <Grid item md={12} xs={12}>
                                        <TextValidator
                                            variant="filled"
                                            className={classes.input}
                                            label={'RNC'}
                                            onChange={handleChange('rnc')}
                                            name="rnc"
                                            value={values.rnc}
                                        />
                                    </Grid>
                                    }

                                    <Grid item xs={12}>
                                        <TextValidator
                                            type={'date'}
                                            variant="filled"
                                            className={classes.input}
                                            label={'¿Cuándo desea el servicio?'}
                                            onChange={handleChange('date')}
                                            name="date"
                                            value={values.date}
                                        />
                                    </Grid>

                                    <Grid style={{marginTop: 15}} container spacing={1}>
                                        <Grid onClick={() => setIsOpenIng(!isOpenIng)} container
                                              direction="row"
                                              justify="center"
                                              alignItems="center">
                                            <span style={{fontSize:20, color: '#4caf50'}} className={classes.title}>Servicios de  Ingeniería Hospitalaria</span>
                                            {!isOpenIng && <KeyboardArrowRight className={classes.iconOne}/>}
                                            {isOpenIng && <KeyboardArrowDown className={classes.iconOne}/>}
                                        </Grid>

                                        <Collapse isOpen={isOpenIng}>
                                            <Grid style={{marginLeft: '10%'}} container spacing={1}>
                                                {
                                                    servicesIng.map((item, index) => {
                                                        return (
                                                            <Grid key={index} item xs={12} sm={12}>
                                                                <FormControlLabel
                                                                    control={<Checkbox
                                                                        checked={item.active || servicesIngSelected[item.id.toString()]}
                                                                        onChange={handleServicesIng}
                                                                        color="primary"
                                                                        name={item.id.toString()}
                                                                    />}
                                                                    label={(
                                                                        <span>{item.title.charAt(0).toUpperCase() + item.title.slice(1).toLowerCase()}</span>)}
                                                                />
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                            </Grid>
                                        </Collapse>
                                    </Grid>


                                    <Grid style={{marginTop: 25}} container spacing={1}>
                                        <Grid onClick={() => setIsOpenBio(!isOpenBio)} container
                                              direction="row"
                                              justify="center"
                                              alignItems="center">
                                            <span style={{fontSize:20, color: '#4caf50'}} className={classes.title}>Servicios de  Bioseguridad</span>
                                            {!isOpenBio && <KeyboardArrowRight className={classes.iconOne}/>}
                                            {isOpenBio && <KeyboardArrowDown className={classes.iconOne}/>}
                                        </Grid>

                                        <Collapse isOpen={isOpenBio}>
                                            <Grid style={{marginLeft: '10%'}} container spacing={1}>
                                                <Grid item xs={12} sm={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox checked={desinfeccionElectrostatica}
                                                                           onChange={(e) => handleCheckDesinfeccionElectrostatica(e)}
                                                                           color="primary"
                                                                           value="desinfeccionElectrostatica"/>}
                                                        label={(<span>Desinfección electrostática</span>)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox checked={productosDesinfeccion}
                                                                           onChange={(e) => handleCheckProductosDesinfeccion(e)}
                                                                           color="primary"
                                                                           value="productosDesinfeccion"/>}
                                                        label={(<span>Productos para desinfección </span>)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox checked={controlPlagas}
                                                                           onChange={(e) => handleCheckControlPlagas(e)}
                                                                           color="primary" value="controlPlagas"/>}
                                                        label={(<span>Control de plagas  </span>)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox checked={limpiezaProfundaSuperficie}
                                                                           onChange={(e) => handleCheckLimpiezaProfundaSuperficie(e)}
                                                                           color="primary"
                                                                           value="limpiezaProfundaSuperficie"/>}
                                                        label={(
                                                            <span>Limpieza profunda de todo tipo de superficie</span>)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox checked={igualas}
                                                                           onChange={(e) => handleCheckIgualas(e)}
                                                                           color="primary" value="igualas"/>}
                                                        label={(<span>Igualas</span>)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Collapse>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextValidator
                                            variant="filled"
                                            multiline
                                            rows="4"
                                            className={classes.input}
                                            label={"Información adicional"}
                                            onChange={handleChange('message')}
                                            name="Message"
                                            value={values.message}
                                        />
                                    </Grid>
                                </Grid>
                                <div className={clsx(classes.btnArea, classes.flex)}>
                                    <Button variant="contained" fullWidth={isMobile} type="submit" color="secondary"
                                            size="large">
                                        Enviar COTIZACIÓN
                                    </Button>
                                </div>

                                {sendActiveText && <div style={{marginTop: 10}}>
                                    <span style={{fontSize: 16, color: '#2690c0'}}>{sendText}</span>
                                </div>}
                            </ValidatorForm>


                        </div>
                        <Grid container direction="row" justify="center" alignItems="center">
                            <Grid item style={{textAlign: 'center'}} xs={12} sm={12} lg={12}>
                                <label>Calle Proyecto 3 #28 Reparto Oquet, Santiago De Los Caballeros, RD</label>
                            </Grid>
                        </Grid>

                        <Grid style={{marginTop: 15}} container direction="row" justify="space-evenly"
                              alignItems="center">
                            <Grid item style={{textAlign: 'center'}} xs={12} sm={12} lg={4}>
                                <Grid container direction="column" justify="space-evenly" alignItems="center">
                                    <img alt="Instagram" height="18" src={PH} title="Instagram" width="18"/>
                                    <label>809-489-9843</label>
                                </Grid>
                            </Grid>

                            <Grid item style={{textAlign: 'center'}} xs={12} sm={12} lg={4}>
                                <Grid container direction="column" justify="space-evenly" alignItems="center">
                                    <img alt="Instagram" height="18" src={EM} title="Instagram" width="18"/>
                                    <label>info@saludatualcance.com.do</label>
                                </Grid>
                            </Grid>

                            <Grid item style={{textAlign: 'center'}} xs={12} sm={12} lg={4}>
                                <Grid container direction="column" justify="space-evenly" alignItems="center">
                                    <a href="https://www.instagram.com/saludatualcancerd/?hl=es-la" target="_blank"><img
                                        alt="Instagram" height="18" src={WO} title="Instagram" width="18"/></a>
                                    <label>www.saludatualcance.com.do</label>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item style={{marginTop: 15}} container direction="row" justify="center"
                              alignItems="center">
                            <Grid item xs={1} sm={1}>
                                <a href="https://www.facebook.com/saludatualcancerd" target="_blank"><img
                                    alt="Instagram" height="32" src={fb} title="Instagram" width="32"/></a>
                            </Grid>
                            <Grid item xs={1} sm={1}>
                                <a href="https://www.instagram.com/saludatualcancerd/?hl=es-la" target="_blank"><img
                                    alt="Instagram" height="32" src={Ins} title="Instagram" width="32"/></a>
                            </Grid>
                        </Grid>
                    </div>
                </Paper>
            </Container>
        </div>
    );
}
