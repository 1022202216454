export const servicesIng=[
    {id:1, title:'DISEÑO DE INFRAESTRUCTURA HOSPITALARIAS SEGÚN NORMAS', active:false},
    {id:2, title:'EQUIPAMIENTO DE ALTA GAMA EN GENERAL', active:false},
    {id:3, title:'MANTENIMIENTOS EQUIPOS SECUNDARIOS (AUTO CLAVES, CAMAS Y CAMILLA)', active:false},
    {id:4, title:'MOBILIARIOS EN ACERO INOXIDABLE (LABORATORIOS, COCINAS, COUNTERS, MORGUE, PUERTAS, LAVAMANOS QUIRURGICOS)', active:false},
    {id:5, title:'PROTECTORES DE PARED (BUMPERS, TERMINALES, ESQUINEROS)', active:false},
    {id:6, title:'CORTINAS DE PRIVACIDAD ANTIBACTERIAL', active:false},
    {id:7, title:'ACCESORIOS PARA BAÑOS DE ACCESIBILIDAD UNIVERSAL', active:false},
    {id:8, title:'PISOS EPOXICOS 100% ASEPTICOS ', active:false},
    {id:9, title:'RED DE DATA ', active:false},
    {id:10, title:'CONTRA INCENDIO ', active:false},
    {id:11, title:'SISTEMAS DE GASES MEDICOS ', active:false},
    {id:12, title:'SISTEMAS ELECTRICOS ', active:false},
    ];
