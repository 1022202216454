import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TitleDeco from '../Title/WithDecoration';
import useStyles from './about-style';
import Counter from '../Counter';

export default  function About() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container fixed>
        <Grid container spacing={6}>
          <Grid item md={5} xs={12}>
            <div>
              <TitleDeco text={'Sobre nosotros'} />
              {isDesktop && (
                <div className={classes.puzzle}>
                  <div className={classes.pieceBig}>
                    <span />
                  </div>
                  <div className={classes.pieceSmallTop}>
                    <span />
                  </div>
                  <div className={classes.pieceSmallBottom}>
                    <span />
                  </div>
                </div>
              )}
            </div>
          </Grid>
          <Grid item md={7} xs={12}>
            <Typography style={{fontWeight:'bold'}}  variant="h5">
              Ingeniería Hospitalaria – Bioseguridad – Suministro
            </Typography>
            <Typography variant="subtitle1">
              Como objetivo principal tenemos, suministrar productos y servicios de la más alta calidad, cumpliendo con los estándares sanitarios, conforme a las normas internacionales de la OMS Y OPS, con el fin de brindar seguridad y confianza a cada usuario. Tenemos diversos departamentos que abarcan las necesidades de nuestros consumidores, desde grandes hospitales e instituciones públicas, privadas y hasta los hogares de cada ciudadano.
            </Typography>
            <Counter />
            <blockquote>
              Nacemos con la finalidad y la visión de brindar los servicios adecuados en un mundo cambiante exigente y que busca resultados.
            </blockquote>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
