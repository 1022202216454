import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './cards-style';
import Paper from '@material-ui/core/Paper';

export default function SimpleThumb(props) {
  const classes = useStyles();
  const { logo } = props;
  return (
    <div className={classes.simpleThumb}>
        <Paper square  className={classes.paper} elevation={0}>
          <img width={'100%'} height={300}  src={logo} alt={'logo' + logo.toString()}/>
        </Paper>
    </div>
  );
}

SimpleThumb.propTypes = {
  logo: PropTypes.string.isRequired,
};
