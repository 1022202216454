const imgAPI = {

    agency: [
        'https://firebasestorage.googleapis.com/v0/b/formulario-de-cotizacion.appspot.com/o/office.jpeg?alt=media&token=0ce11fbf-5bef-48c2-b850-1ecfe7ee07cf',
        './images/im1.jpg',
    ],

    client: [
        './images/client/c1.jpg',
        './images/client/c2.jpg',
        './images/client/c3.jpg',
        './images/client/c4.jpg',
        './images/client/c5.jpg',
        './images/client/c6.jpg',
        './images/client/c7.jpg',
        './images/client/c8.jpg',
        './images/client/c9.jpg',
        './images/client/c10.jpg',
        './images/client/c11.jpg',
        './images/client/c12.jpg',
        './images/client/c13.jpg',
        './images/client/c14.jpg',
    ],

    portfolio:[
        {title:'Cortinas', size:'long',category:'cat1',  img:'./images/portfolio/cort1.jpeg'},
        {title:'Cortinas', size:'short',category:'cat1', img:'./images/portfolio/cort2.jpeg'},
        {title:'Cortinas', size:'short',category:'cat1', img:'./images/portfolio/cort3.jpeg'},
        {title:'Cortinas', size:'short',category:'cat1', img:'./images/portfolio/cort4.jpeg'},
        {title:'Cortinas', size:'short',category:'cat1', img:'./images/portfolio/cort5.jpeg'},
        {title:'Cortinas', size:'short',category:'cat1', img:'./images/portfolio/cort6.jpg'},
        {title:'Cortinas', size:'short',category:'cat1', img:'./images/portfolio/cort8.jpg'},
        {title:'Cortinas', size:'short',category:'cat1', img:'./images/portfolio/cort9.jpg'},
        {title:'Cortinas', size:'short',category:'cat1', img:'./images/portfolio/cort10.jpg'},
        {title:'Mobiliarios', size:'short',category:'cat1', img:'./images/portfolio/mob31.jpg'},


        {title:'Diseños de infraestructura', size:'short',category:'cat7', img:'./images/portfolio/dise3.jpeg'},
        {title:'Diseños de infraestructura', size:'short',category:'cat7', img:'./images/portfolio/dise4.jpeg'},
        {title:'Diseños de infraestructura', size:'short',category:'cat7', img:'./images/portfolio/dise5.jpeg'},
        {title:'Diseños de infraestructura', size:'short',category:'cat7', img:'./images/portfolio/dise8.jpeg'},
        {title:'Diseños de infraestructura', size:'short',category:'cat7', img:'./images/portfolio/dise9.jpeg'},
        {title:'Diseños de infraestructura', size:'short',category:'cat7', img:'./images/portfolio/dise10.jpeg'},
        {title:'Mobiliarios', size:'long',category:'cat7', img:'./images/portfolio/mob8.jpeg'},

        {title:'Gases', size:'short',category:'cat6', img:'./images/portfolio/ga1.jpg'},
        {title:'Gases', size:'short',category:'cat6', img:'./images/portfolio/ga2.jpg'},
        {title:'Gases', size:'short',category:'cat6', img:'./images/portfolio/ga3.jpg'},
        {title:'Gases', size:'short',category:'cat6', img:'./images/portfolio/ga4.jpg'},
        {title:'Gases', size:'short',category:'cat6', img:'./images/portfolio/ga5.jpg'},
        {title:'Gases', size:'short',category:'cat6', img:'./images/portfolio/ga6.jpg'},

        {title:'Protectores de pared', size:'medium',category:'cat2', img:'./images/portfolio/bom1.jpg'},
        {title:'Protectores de pared', size:'medium',category:'cat2', img:'./images/portfolio/bom2.jpg'},
        {title:'Protectores de pared', size:'medium',category:'cat2', img:'./images/portfolio/bom3.jpg'},
        {title:'Protectores de pared', size:'medium',category:'cat2', img:'./images/portfolio/bom4.jpg'},


        {title:'Mobiliarios', size:'short',category:'cat3', img:'./images/portfolio/mob9.jpeg'},
        {title:'Mobiliarios', size:'short',category:'cat3', img:'./images/portfolio/mob10.jpeg'},
        {title:'Mobiliarios', size:'short',category:'cat3', img:'./images/portfolio/mob11.jpeg'},
        {title:'Mobiliarios', size:'short',category:'cat3', img:'./images/portfolio/mob12.jpeg'},
        {title:'Mobiliarios', size:'short',category:'cat3', img:'./images/portfolio/mob13.jpeg'},
        {title:'Mobiliarios', size:'short',category:'cat3', img:'./images/portfolio/dise1.jpeg'},
        {title:'Mobiliarios', size:'short',category:'cat3', img:'./images/portfolio/dise2.jpeg'},
        {title:'Mobiliarios', size:'short',category:'cat3', img:'./images/portfolio/dise11.jpg'},
        {title:'Mobiliarios', size:'short',category:'cat3', img:'./images/portfolio/dise12.jpg'},
        {title:'Mobiliarios', size:'long',category:'cat3', img:'./images/portfolio/dise13.jpg'},
        {title:'equip', size:'long',category:'cat3', img:'./images/portfolio/equip.jpg'},



        {title:'Mobiliarios', size:'long',category:'cat4', img:'./images/portfolio/mob1.jpeg'},
        {title:'Mobiliarios', size:'short',category:'cat4', img:'./images/portfolio/mob3.jpeg'},
        {title:'Mobiliarios', size:'long',category:'cat4', img:'./images/portfolio/mob4.jpeg'},
        {title:'Mobiliarios', size:'short',category:'cat4', img:'./images/portfolio/mob5.jpeg'},
        {title:'Mobiliarios', size:'short',category:'cat4', img:'./images/portfolio/mob6.jpeg'},
        {title:'Mobiliarios', size:'long',category:'cat4', img:'./images/portfolio/mob2.jpeg'},
        {title:'Mobiliarios', size:'short',category:'cat4', img:'./images/portfolio/mob15.jpeg'},
        {title:'Mobiliarios', size:'short',category:'cat4', img:'./images/portfolio/mob16.jpeg'},
        {title:'Mobiliarios', size:'short',category:'cat4', img:'./images/portfolio/mob17.jpeg'},
        {title:'Mobiliarios', size:'long',category:'cat4', img:'./images/portfolio/mob20.jpg'},
        {title:'Mobiliarios', size:'short',category:'cat4', img:'./images/portfolio/mob21.jpg'},
        {title:'Mobiliarios', size:'short',category:'cat4', img:'./images/portfolio/mob22.jpg'},
        {title:'Mobiliarios', size:'short',category:'cat4', img:'./images/portfolio/mob23.jpg'},
        {title:'Mobiliarios', size:'short',category:'cat4', img:'./images/portfolio/mob24.jpg'},
        {title:'Mobiliarios', size:'short',category:'cat4', img:'./images/portfolio/mob25.jpg'},
        {title:'Mobiliarios', size:'short',category:'cat4', img:'./images/portfolio/mob26.jpg'},
        {title:'Mobiliarios', size:'short',category:'cat4', img:'./images/portfolio/mob27.jpg'},
        {title:'Mobiliarios', size:'short',category:'cat4', img:'./images/portfolio/mob28.jpg'},
        {title:'Mobiliarios', size:'short',category:'cat4', img:'./images/portfolio/mob29.jpg'},
        {title:'Mobiliarios', size:'short',category:'cat4', img:'./images/portfolio/mob30.jpg'},


        {title:'Piso asepticos', size:'short',category:'cat5', img:'./images/portfolio/piso.jpeg'},
        {title:'Piso asepticos', size:'short',category:'cat5', img:'./images/portfolio/piso1.jpeg'},
        {title:'Piso asepticos', size:'short',category:'cat5', img:'./images/portfolio/piso2.jpeg'},
        {title:'Piso asepticos', size:'short',category:'cat5', img:'./images/portfolio/piso3.jpeg'},

    ],


    movie: [
        'https://via.placeholder.com/1280x854/8d815b/FFFFFF',
        'https://via.placeholder.com/300x440/ffa4a4/757575',
        'https://via.placeholder.com/300x440/74b7fa/757575',
        'https://via.placeholder.com/300x440/ffa4a4/757575',
        'https://via.placeholder.com/300x440/74b7fa/757575',
        'https://via.placeholder.com/300x440/ffa4a4/757575',
        'https://via.placeholder.com/320x370/ffa4a4/FFFFFF',
        'https://via.placeholder.com/700x360/ffd7a4/757575',
        'https://via.placeholder.com/320x370/d0d4e6/757575',
        '/images/movie/bag.png',
        '/images/movie/bag.png',
        '/images/movie/man.png',
        '/images/movie/bag.png',
        '/images/movie/bag.png',
        '/images/movie/woman.png',
            ]

};

export default imgAPI;
