import React, { useState, useEffect } from 'react';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Hidden from '@material-ui/core/Hidden';
import CssBaseline from '@material-ui/core/CssBaseline';
import SquareParallax from '../../components/Parallax/Square';
import VideoBanner2 from '../../components/VideoBanner2';
import NewVideoBanner from '../../components/NewHeader/VideoBanner';
import NewPromotions from '../../components/NewHeader/Promotions';
import NewHeader from '../../components/NewHeader/Header';
import About from '../../components/About';
import Services from '../../components/Services';
import Testimonials from '../../components/Testimonials';
import CallAction from '../../components/CallAction';
import MapAddress from '../../components/MapAddress';
import Footer from '../../components/Footer';
import PageNav from '../../components/PageNav';
import Notification from '../../components/Notification';
import Gallery from '../../components/Gallery';
import AllCategories from '../../components/AllCategories';
import FsLightbox from 'fslightbox-react';
import {useSelector } from 'react-redux';
import {portfolio} from '../../components/Gallery/images'
import {HeadProvider, Link, Meta, Title} from "react-head";

const sectionMargin = margin => (margin * 20);
const useStyles = makeStyles(theme => ({
  mainWrap: {
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    background: theme.palette.type === 'dark' ? theme.palette.background.default : theme.palette.background.paper,
  },
  spaceBottom: {
    marginBottom: sectionMargin(6),
    [theme.breakpoints.down('sm')]: {
      marginBottom: sectionMargin(3),
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: sectionMargin(2),
    }
  },
  spaceBottomShort: {
    marginBottom: sectionMargin(theme.spacing() / 2)
  },
  spaceTop: {
    marginTop: sectionMargin(6),
    [theme.breakpoints.down('sm')]: {
      marginTop: sectionMargin(3),
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: sectionMargin(2),
    }
  },
  spaceTopShort: {
    marginTop: sectionMargin(theme.spacing() / 2),
    [theme.breakpoints.down('sm')]: {
      marginTop: sectionMargin(3),
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: sectionMargin(2),
    }
  },
  containerWrap: {
    marginTop: -40,
    '& > section': {
      position: 'relative'
    }
  }
}));

export default function Home(props) {
  const [snackbar, setSnackbar] = useState('false');

  useEffect(() => {
    setSnackbar(localStorage.getItem('snackbarContent'));
  }, []);

  const classes = useStyles();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { onToggleDark, onToggleDir } = props;
  const light_box = useSelector(state => state.light_box);

  return (
    <React.Fragment>
      <FsLightbox toggler={light_box.toggle} slide={light_box.slide} sources={portfolio.map(x => {return x.img;})}/>
      <HeadProvider>
        <Title> Salud</Title>
        <Link rel="canonical" content="http://saludatualcance.com.do/"/>
        <Meta name="keywords" content="Contact,salud, salud a tu alcance"/>
      </HeadProvider>
      <CssBaseline/>
      <div className={classes.mainWrap}>
        <NewHeader
          onToggleDark={onToggleDark}
          onToggleDir={onToggleDir}
        />
        <main className={classes.containerWrap}>
          <SquareParallax/>
          <section id="home">
            <NewVideoBanner/>
            <div style={isMobile? {marginBottom:'4%'}:{marginTop:'5%'}} id="home">
              <NewPromotions />
            </div>
          </section>
          <section id="about" className={isTablet ? classes.spaceTopShort : classes.spaceTop}>
            <About/>
          </section>
          <section id="services" className={classes.spaceTop}>
            <Services/>
          </section>
          <section id="our-expertise" className={isTablet ? classes.spaceTopShort : classes.spaceTopShort}>
            <VideoBanner2/>
          </section>
          <section id="testimonials" className={isMobile ? classes.spaceTopShort : classes.spaceTop}>
            <Testimonials/>
          </section>
          <section id="all">
            <AllCategories/>
          </section>
          <section id="case-studies">
            <Gallery/>
          </section>
          <section id="call-to-action" className={classes.spaceTopShort}>
            <CallAction/>
          </section>
          <section id="address" className={classes.spaceTopShort}>
            <MapAddress/>
          </section>
        </main>
        <Hidden mdDown>
          <PageNav/>
        </Hidden>
        <section className={classes.spaceTopShort}>
          <Footer toggleDir={onToggleDir}/>
        </section>

        {snackbar !== 'true' &&
        <Hidden mdDown>
          <Notification/>
        </Hidden>
        }
      </div>
    </React.Fragment>
  );
}

