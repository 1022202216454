import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import {useText} from '../../theme/common';
import useStyles from './counter-style';
import {NavLink} from "react-router-dom";

export default function Counter() {

    const classes = useStyles();
    const text = useText();

    return (
        <div className={classes.counterWrap}>
            <Container fixed>
                <Grid container justify="center" alignItems="center" spacing={6}>
                    <Grid md={4} item>
                        <div className={classes.counterItem}>
                            <div className={classes.text}>
                                <NavLink exact className="nav-link" to={'/mision'} variant="subtitle1" color="textSecondary">
                                    <img style={{cursor: 'pointer'}} src={require('../../images/icons/icon_shape1.png')} alt=""/>
                                </NavLink>
                                <Typography component="p" className={text.subtitle}>
                                    Misión
                                </Typography>
                                <Typography variant={"subtitle1"} className={text.subtitle3}>
                                    Nuestra Razón de ser y hacer
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid md={4} item>
                        <div className={classes.counterItem}>
                            <div className={classes.text}>
                                <NavLink exact className="nav-link" to={'/vision'} variant="subtitle1" color="textSecondary">
                                    <img style={{cursor: 'pointer'}} src={require('../../images/icons/icon_shape2.png')} alt=""/>
                                </NavLink>
                                <Typography component="p" className={text.subtitle}>
                                    Visión
                                </Typography>
                                <Typography variant={"subtitle1"}>
                                    Nuestra capacidad de planifición
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid md={4} item>
                        <div className={classes.counterItem}>
                            <div className={classes.text}>
                                <NavLink exact className="nav-link" to={'/valores'} variant="subtitle1" color="textSecondary">
                                    <img style={{cursor: 'pointer'}} src={require('../../images/icons/icon_shape3.png')} alt=""/>
                                </NavLink>
                                <Typography component="p" className={text.subtitle}>
                                    Valores
                                </Typography>
                                <Typography variant={"subtitle1"}>
                                   Los valores que nos definen
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
