import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import useStyles from './cards-style';
import Button from '@material-ui/core/Button';


export default function ImageThumb(props) {
  const classes = useStyles();
  const {
    img,
    size,
    openPopup
  } = props;

  const setSize = sizePaper => {
    switch (sizePaper) {
      case 'short':
        return classes.short;
      case 'long':
        return classes.long;
      default:
        return classes.medium;
    }
  };
  return (
    <Paper className={clsx(classes.imgThumb, setSize(size))}>
      <div className={classes.figure}>
        <div className={classes.img} style={{ backgroundImage: `url(${img})` }} />
      </div>
      <div className={classes.detail}>
        <Button onClick={openPopup} className={classes.button} variant="outlined" type="submit"  size="large">Mostrar</Button>
      </div>
    </Paper>
  );
}

ImageThumb.propTypes = {
  img: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
};
