import { makeStyles } from '@material-ui/styles';
const sectionMargin = margin => (margin * 20);

const useStyles = makeStyles(theme => ({
    spaceBottom: {
        marginBottom: sectionMargin(theme.spacing())
    },
    spaceTop: {
        paddingTop: theme.spacing(12),
    },
    spaceTop2: {
        paddingTop: theme.spacing(10),
    },
    text: {
        alignItems:'flex-start',
        marginBottom:theme.spacing(3),
    },
    containerWrap: {
        marginTop: theme.spacing(10),
        position: 'relative',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        '& h2': {
            fontFamily: 'Roboto Condensed',
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.type === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark,
            [theme.breakpoints.down('md')]: {
                fontSize: 40
            }
        },
        '& h3': {
            fontFamily: 'Roboto Condensed',
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.type === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark,
            [theme.breakpoints.down('md')]: {
                fontSize: 30
            }
        }
    },
    mainWrap: {
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
        background: theme.palette.background.paper,
    },
}));

export default useStyles;
