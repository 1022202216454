import React, {Fragment} from 'react';
import Decoration from '../../components/FormsLanding/Decoration';
import Footer from "../../components/Footer/Footer";
import {makeStyles} from "@material-ui/core/styles";
import Hidden from '@material-ui/core/Hidden';
import Container from '@material-ui/core/Container';
import Header from "../../components/Header/Header";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import CallAction from '../../components/CallAction/CallAction';
const sectionMargin = margin => (margin * 20);
const useStyles = makeStyles(theme => ({
    spaceBottom: {
        marginBottom: sectionMargin(theme.spacing())
    },
    spaceTop: {
        paddingTop: theme.spacing(12),
    },
    spaceTop2: {
        paddingTop: theme.spacing(10),
    },
    text: {
        alignItems:'flex-start',
        marginBottom:theme.spacing(3),
    },
    containerWrap: {
        marginTop: theme.spacing(10),
        position: 'relative',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        '& h2': {
            fontFamily: 'Roboto Condensed',
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.type === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark,
            [theme.breakpoints.down('md')]: {
                fontSize: 40
            }
        },
        '& h3': {
            fontFamily: 'Roboto Condensed',
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.type === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark,
            [theme.breakpoints.down('md')]: {
                fontSize: 30
            }
        }
    },
    mainWrap: {
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
        background: theme.palette.background.paper,
    },
}));


export default function SuppliesStore(props) {
    const classes = useStyles();
    const {onToggleDark, onToggleDir} = props;
    return (
        <Fragment>
            <div className={classes.mainWrap}>
                <Header onToggleDark={onToggleDark} onToggleDir={onToggleDir} invert/>
                <Container maxWidth="lg" className={classes.containerWrap}>
                    <Decoration/>
                    <div className={clsx(classes.spaceTop)}>
                        <Grid container direction="row" justify="center" alignItems="center">
                            <Grid item ms={6} xs={12} md={6}>
                                <Grid item ms={12} xs={12} md={12}>
                                    <Typography variant="h3" align="center" gutterBottom>
                                        Tienda de insumos
                                    </Typography>
                                </Grid>
                                <Grid ms={12} xs={12} md={12}>
                                    <Typography variant="h6" align="center" gutterBottom>
                                        Este servicio le da un acceso inmediato, le ahorra tiempo, le evita la incomodidad del desplazamiento y le permite revisar con calma los diferentes tipos de productos, de esta forma podrá obtener una cotización inmediata vía correo electrónico.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Hidden smDown>
                            <Grid item ms={6} xs={12} md={6}>
                                <img width={'100%'} src={require('../../images/home/store1.jpg')} alt=""/>
                            </Grid>
                            </Hidden>
                        </Grid>
                    </div>
                    <div className={clsx(classes.spaceTop2,classes.spaceBottom) }>
                        <CallAction/>
                    </div>
                </Container>
            </div>
            <section className={classes.spaceTop}>
                <Footer/>
            </section>
        </Fragment>
    );
}
