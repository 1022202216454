import { makeStyles } from '@material-ui/core/styles';
const sectionMargin = margin => (margin * 30);

const shopStyles = makeStyles(theme => ({
  carouselWrap: {
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    alignSelf: 'center',
    position: 'relative',
    '& ul[class="slick-dots"]': {
      bottom: theme.spacing(-5),
      opacity: 0.48,
      '& li': {
        background: theme.palette.text.disabled,
        width: 25,
        height: 4,
        transition: 'width 0.3s ease',
        '&:before': {
          display: 'none'
        },
        '&[class="slick-active"]': {
          width: 80,
        }
      }
    }
  },
  nav: {
    background: theme.palette.background.paper,
    width: 50,
    height: 40,
    textAlign: 'center',
    lineHeight: '30px',
    position: 'absolute',
    top: 'calc(50% - 20px)',
    zIndex: 90,
    boxShadow: '0 0 20px 2px rgba(0, 0, 0, 0.2)',
    '& svg': {
      verticalAlign: 'middle',
      transform: theme.direction === 'rtl' ? 'scale(-1)' : 'none'
    },
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  prev: {
    '&:focus': {
      outline: 'none',
    },
    left: theme.spacing(-10),
  },
  next: {
    '&:focus': {
      outline: 'none',
    },
    right: theme.spacing(-10),
  },
  spaceBottom: {
    marginBottom: sectionMargin(theme.spacing())
  },
  carousel: {
    '&:hover': {
      outline: 'none',
    },
    '&:active': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  spaceTop: {
    paddingTop: theme.spacing(20),
  },
  spaceTop2: {
    paddingTop: theme.spacing(10),
  },
  text: {
    alignItems: 'flex-start',
    marginBottom: theme.spacing(3),
  },
  gridItem: {
    marginTop: 15,
  },
  paper: {
    padding: 15,
    justifyContent: 'center',
    flexDirection: 'column',
    display: 'flex',
    border:'line',
    borderWidth: 1,
    borderColor:'red',
    borderRadius:8
  },
  NativeSelect: {
    justifyContent: 'flex-end',
    flexDirection: 'column',
    display: 'flex',
  },
  divButtonCar: {
    marginTop: 15,
    justifyContent: 'flex-end',
    flexDirection: 'row',
    display: 'flex',
  },
  divText: {
    marginTop: 15,
    alignSelf: 'flex-start',
    marginLeft: 15,
    justifyContent: 'flex-start',
    flexDirection: 'column',
    display: 'flex',
  },

  pagination: {
    marginTop: 100,
    color:'secondary',
    marginBottom: 100,
    justifyContent: 'center',
    flexDirection: 'row',
    display: 'flex',
    '&:focus': {
      color: '#0091ea',
      outline: 'none',
    },
    '&:hover': {
      color: '#0091ea',
      outline: 'none',
    },
    '& ul': {
      color: '#0091ea',
      outline: 'none',
      '& li': {
        color: '#0091ea',
        outline: 'none',
      }
    },

  },


  button1: {
    fontSize: 12,
    borderColor: '#4caf50',
    borderStyle: 'solid',
    borderWidth: 1,
    backgroundColor: '#fff',
    color: '#4caf50',
    fontWeight: 'bold',
    '&:hover': {
      borderWidth: 1,
      backgroundColor: '#0091ea',
      borderStyle: 'solid',
      borderColor: '#0091ea',
      color: '#fff'
    },
    '&:focus': {
      outline: 'none',
    },
  },
  button2: {
    fontSize: 12,
    fontWeight: 'bold',
    backgroundColor: '#0091ea',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#0091ea',
    color: '#fff',
    '&:hover': {
      borderColor: '#4caf50',
      borderStyle: 'solid',
      borderWidth: 1,
      backgroundColor: '#fff',
      color: '#4caf50'
    },
    '&:focus': {
      outline: 'none',
    },
  },

  containerWrap: {
    marginTop: theme.spacing(10),
    position: 'relative',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    '& h2': {
      fontFamily: 'Roboto Condensed',
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.type === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark,
      [theme.breakpoints.down('md')]: {
        fontSize: 40
      }
    },
    '& h3': {
      fontFamily: 'Roboto Condensed',
      fontWeight: theme.typography.fontWeightBold,
      color: '#00c853',
      [theme.breakpoints.down('md')]: {
        fontSize: 30
      }
    },
    '& h4': {
      fontFamily: 'Roboto Condensed',
      fontWeight: theme.typography.fontWeightBold,
      color: '#00c853',
      [theme.breakpoints.down('md')]: {
        fontSize: 26
      }
    },
  },
  mainWrap: {
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    background: theme.palette.background.paper,
  },
}));

export default shopStyles;
