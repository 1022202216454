import React, { Fragment, useState, useEffect, useRef } from 'react';
import Decoration from '../../components/FormsLanding/Decoration';
import Footer from '../../components/Footer/Footer';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Header from '../../components/Header/Header';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import {LoadingItem, shoppingItem, shoppingValues} from '../../redux/actions';
import CircularProgress from '@material-ui/core/CircularProgress';
import { firebase } from '../../api/firebase';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import { confirmAlert } from 'react-confirm-alert';
import { useRouter } from '../../router/useRouter'
import TextField from '@material-ui/core/TextField';
const sectionMargin = margin => (margin * 20);

const useStyles = makeStyles(theme => ({
  spaceBottom: {
    marginBottom: sectionMargin(theme.spacing())
  },
  spaceTop: {
    paddingTop: theme.spacing(20),
  },
  spaceTop2: {
    paddingTop: theme.spacing(10),
  },
  text: {
    alignItems: 'flex-start',
    marginBottom: theme.spacing(3),
  },
  gridItem:{
    marginTop:15,
  },
  paper:{
    padding:15,
    justifyContent:'center',
    flexDirection:'column',
    display: 'flex',
  },
  NativeSelect:{
    justifyContent:'flex-end',
    flexDirection:'column',
    display: 'flex',
  },
  divButtonCar:{
    marginTop:15,
    justifyContent:'flex-end',
    flexDirection:'row',
    display: 'flex',
  },
  divText:{
    marginTop:15,
    alignSelf:'flex-start',
    marginLeft:15,
    justifyContent:'flex-start',
    flexDirection:'column',
    display: 'flex',
  },

  pagination:{
    marginTop:100,
    marginBottom:100,
    justifyContent:'center',
    flexDirection:'row',
    display: 'flex',
    '&:focus': {
      outline: 'none',
    },
  },


  button1: {
    fontSize: 14,
    padding:10,
    paddingLeft: 25,
    paddingRight: 25,
    margin: 10,
    color: '#fff',
    backgroundColor: '#00e676',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#00c853',
      color: '#fff'
    },
    '&:focus': {
      outline: 'none',
    },
  },
  button2: {
    margin: 10,
    fontSize: 12,
    fontWeight: 'bold',
    color: '#000',
    '&:hover': {
      color: 'red'
    },
    '&:focus': {
      outline: 'none',
    },
  },

  shop: {
    fontSize: 12,
    borderColor: '#4caf50',
    borderStyle: 'solid',
    borderWidth: 1,
    backgroundColor: '#fff',
    color: '#4caf50',
    fontWeight: 'bold',
    '&:hover': {
      borderWidth: 1,
      backgroundColor: '#0091ea',
      borderStyle: 'solid',
      borderColor: '#0091ea',
      color: '#fff'
    },
    '&:focus': {
      outline: 'none',
    },
  },
  home: {
    fontSize: 12,
    fontWeight: 'bold',
    backgroundColor: '#0091ea',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#0091ea',
    color: '#fff',
    '&:hover': {
      borderColor: '#4caf50',
      borderStyle: 'solid',
      borderWidth: 1,
      backgroundColor: '#fff',
      color: '#4caf50'
    },
    '&:focus': {
      outline: 'none',
    },
  },

  containerWrap: {
    marginTop: theme.spacing(10),
    position: 'relative',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    '& h2': {
      fontFamily: 'Roboto Condensed',
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.type === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark,
      [theme.breakpoints.down('md')]: {
        fontSize: 40
      }
    },
    '& h3': {
      fontFamily: 'Roboto Condensed',
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.type === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark,
      [theme.breakpoints.down('md')]: {
        fontSize: 30
      }
    }
  },
  mainWrap: {
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    background: theme.palette.background.paper,
  },
  prev: {
    background:'#00e676',
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      background:'#00c853',
    },
    left: theme.spacing(-10),
  },
  next: {
    background:'#f44336',
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      background:'#c62828',
    },
    right: theme.spacing(-10),
  },

  progress: {
    marginTop: '7%',
    justifyContent: 'center',
    color: '#00c853'
  },
  paperLoading: {
    height: '100vh',
    outline: 'none',
    textAlign: 'center'
  },
}));

export default function ProcessShopping(props) {
  const classes = useStyles();
  const router = useRouter()
  const { onToggleDark, onToggleDir } = props;
  const shopping_value = useSelector(state => state.shopping_value);
  const shopping_item = useSelector(state => state.shopping_item);
  const loading_item = useSelector(state => state.loading_item);

  const [titleAlert, setTitleAlert] = useState('Tu carrito está vacío');
  const [subTitleAlert, setSubTitleAlert] = useState('¿Deseas buscar otros artículos?');
  const [phoneNumber, setPhoneNumber] = useState('');

  const dispatch = useDispatch();
  const user = firebase.auth().currentUser;
  const toShop= async ()=>{
    await router.push('/tienda');
  }

  const toHome= async ()=>{
    await router.push('/');
  }


  useEffect(()=>{
    if (shopping_item.length===0 && loading_item===false){
      dispatch(LoadingItem(null))
      return alert()
    }

    if (!user){
      dispatch(LoadingItem(null))
      return alert()
    }

    if (shopping_value===0){
      return alert()
    }

  },[shopping_item,shopping_value])


  function alert (){
     confirmAlert({
      customUI: ({onClose}) => {
        return (
          <Paper elevation={3} style={{padding:35, justifyContent:'center'}} >
            <h2>{titleAlert}</h2>
            <p>{subTitleAlert}</p>

            <div style={{margin:25, flexDirection:'row', display:'flex', justifyContent:'space-around'}}>
              <Button onClick={()=>{onClose(); toShop().finally()}} className={classes.shop}  variant="outlined" >
                Tienda
              </Button>
              <Button onClick={()=>{onClose(); toHome().finally()}} className={classes.home} variant="contained" >
                Ir a inicio
              </Button>
            </div>
          </Paper>
        );
      },
       closeOnEscape: false,
       closeOnClickOutside: false,

    });
  }

  const addItem = (id) => {
    let newValue = shopping_value + 1;
    let shoppingData = [...shopping_item];
    dispatch(shoppingValues(newValue));
    let item = shopping_item.find(item => item.id === id);
    let itemToUpload = shopping_item.find(item => item.id === id);
    const timestamp = firebase.firestore.FieldValue.serverTimestamp();

    firebase.firestore()
      .collection('clients')
      .doc(user.uid)
      .collection('articles')
      .doc(itemToUpload.id)
      .set({ createdAt: timestamp, ...itemToUpload, quantity:itemToUpload.quantity + 1 })
      .then(() =>{})
      .catch(err => console.log(err));

      item.quantity = item.quantity + 1;
      shoppingData.splice(shopping_item.findIndex(x => x.id === id), 1, item);
      dispatch(shoppingItem(shoppingData));

  };

  const removeItem = (id) => {
    let newValue = shopping_value - 1;
    let shoppingData = [...shopping_item];
    dispatch(shoppingValues(newValue));
    let item = shopping_item.find(item => item.id === id);
    let itemToUpload = shopping_item.find(item => item.id === id);
    const timestamp = firebase.firestore.FieldValue.serverTimestamp();

    firebase.firestore()
      .collection('clients')
      .doc(user.uid)
      .collection('articles')
      .doc(itemToUpload.id)
      .set({ createdAt: timestamp, ...itemToUpload, quantity:itemToUpload.quantity - 1 })
      .then(() =>{})
      .catch(err => console.log(err));

    item.quantity = item.quantity - 1;
    shoppingData.splice(shopping_item.findIndex(x => x.id === id), 1, item);
    dispatch(shoppingItem(shoppingData));

  };

  const deleteItem=(id)=>{
    firebase.firestore()
      .collection('clients')
      .doc(user.uid)
      .collection('articles')
      .doc(id)
      .delete()
      .then(() =>{
        let articlesList = [...shopping_item];
        articlesList.splice(shopping_item.findIndex(x=>x.id===id),1);
        dispatch(shoppingItem(articlesList));
      }).catch(err => console.log(err));
  }

  const quoteItem = () => {
    const timestamp = firebase.firestore.FieldValue.serverTimestamp();
    firebase.firestore()
      .collection('sales')
      .add({
        item:shopping_item,
        phoneNumber:phoneNumber,
        email: user.email,
        displayName: user.displayName,
        createdAt: timestamp,})
      .then(() =>{
        shopping_item.map(item=>{
          firebase.firestore()
            .collection('clients')
            .doc(user.uid)
            .collection('articles')
            .doc(item.id)
            .delete()
            .then(() =>{})
            .catch(err => console.log(err));
        })
      }).catch(err => console.log(err));
    dispatch(shoppingItem([]));
    dispatch(shoppingValues(0));
    dispatch(LoadingItem(null))
    setTitleAlert('Cotización realizada')
    setSubTitleAlert('¿Deseas seguir buscando artículos?')
  };

  const cleanItem = () => {
    shopping_item.map(item=>{
      firebase.firestore()
        .collection('clients')
        .doc(user.uid)
        .collection('articles')
        .doc(item.id)
        .delete()
        .then(() =>{})
        .catch(err => console.log(err));
    })
    dispatch(shoppingItem([]));
    dispatch(shoppingValues(0));
    dispatch(LoadingItem(null))
  };

  return (
    <Fragment>
      <div className={classes.mainWrap}>
        <Header onToggleDark={onToggleDark} onToggleDir={onToggleDir} invert/>
        <Container maxWidth="lg" className={classes.containerWrap}>
          <Decoration/>
          <div className={clsx(classes.spaceTop)}>

            <Grid item style={{marginBottom:50}} ms={12} xs={12} md={12}>
              <Typography variant="h3" align="center" gutterBottom>
             Carrito de compras
              </Typography>
            </Grid>

            {shopping_item.length===0 && loading_item !==false &&
            <div className={classes.paperLoading}>
              <CircularProgress size={50} className={classes.progress}/>
            </div>
            }

            <Grid spacing={2} container direction="row" justify="flex-start" alignItems="flex-start" >
              <Grid item xs={12} ms={9} md={9} style={{ marginBottom: 20 }}>
                <Grid spacing={2} container direction="column" justify="flex-start" alignItems="flex-start">
                  {shopping_item.map((item, index) => {
                    return (
                      <Grid key={index} container direction="row" justify="space-around" alignItems="center">
                        <Grid  item xs={12} xl={12} sm={3} md={3} lg={3} className={clsx(classes.gridItem)}>
                          <img width={100} height={100} src={item.imageUrl} alt={item.title}/>
                        </Grid>
                        <Grid  item xs={12} xl={12} sm={5} md={5} lg={5} className={clsx(classes.gridItem)}>
                          <div style={{ flexDirection: 'column', display: 'flex' }}>
                            <Typography style={{ alignSelf: 'flex-start', color: '#616161' }} variant={'h6'}> {item.title} </Typography>
                            <Typography style={{ alignSelf: 'flex-start', color: '#616161' }} variant={'subtitle1'}>Cantidad: {item.quantity} </Typography>
                          </div>
                        </Grid>
                        <Grid  item xs={12} xl={12} sm={4} md={4} lg={4} className={clsx(classes.gridItem)}>
                          <div className={classes.carouselWrap}>
                            <Button className={clsx(classes.nav, classes.next)} onClick={item.quantity !== 1? () => removeItem(item.id) : () => deleteItem(item.id)}>
                              {item.quantity !== 1
                                ? <RemoveCircleOutline style={{ color: '#fff' }}/>
                                : <DeleteOutline style={{ color: '#fff' }}/>
                              }
                            </Button>
                            <Button className={clsx(classes.nav, classes.prev)} onClick={() => addItem(item.id)}>
                              <AddCircleOutline style={{ color: '#fff' }}/>
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>

              {shopping_item.length!==0 &&
              <Grid item xs={12} ms={3} md={3}>
                <Paper style={{ padding: 20 }}>
                  <Grid item ms={12} xs={12} md={12}>
                    <Grid item ms={12} xs={12} md={12}>
                      <Typography variant="subtitle1" align="left" gutterBottom>
                        Total articulos
                      </Typography>
                    </Grid>
                      <Grid item ms={12} xs={12} md={12}>
                      <Typography variant="h5" align="left" gutterBottom>
                        {shopping_value}
                      </Typography>
                    </Grid>
                    <Grid item ms={12} xs={12} md={12}>
                      <Typography variant="subtitle1" align="left" gutterBottom>
                        Número de teléfono:
                      </Typography>
                    </Grid>
                    <Grid item ms={12} xs={12} md={12}>
                      <TextField value={phoneNumber} onChange={(e)=>setPhoneNumber(e.target.value)} size={'small'} fullWidth={true}  id="outlined-basic" placeholder="celular o teléfono" variant="outlined" />
                    </Grid>
                    {phoneNumber.length !== 10 &&
                    <Typography color={'error'} variant="inherit" align="left" gutterBottom>
                     Un numero valido de 10 digitos
                      </Typography>
                    }
                    <Divider style={{marginTop:10, marginBottom:10}}/>
                    <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
                      <Grid spacing={1} container direction="row" justify="flex-start" alignItems="center">
                        <Grid item xs={12} xl={12} sm={6} md={6} lg={6}>
                        <Button disabled={!(phoneNumber.length===10)} onClick={()=>quoteItem()}  className={classes.button1}>
                          Cotizar
                        </Button>
                        </Grid>
                        <Grid item xs={12} xl={12} sm={6} md={6} lg={6}>
                        <Button onClick={()=>cleanItem()} variant={'text'}  className={classes.button2}>
                         Limpiar
                        </Button>
                      </Grid>
                      </Grid>
                    </Grid>

                  </Grid>
                </Paper>
              </Grid>
              }
            </Grid>
          </div>
        </Container>
      </div>
      <section className={classes.spaceTop}>
        <Footer/>
      </section>
    </Fragment>
  );
}
