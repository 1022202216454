export const SHOPPING_ITEM_REQUEST = "SHOPPING_ITEM_REQUEST";
export const SHOPPING_ITEM_SUCCESS = "SHOPPING_ITEM_SUCCESS";
export const SHOPPING_ITEM_ERROR = "SHOPPING_ITEM_ERROR";

export function shoppingItemRequest() {
  return {
    type: SHOPPING_ITEM_REQUEST
  }
}

export function shoppingItemSuccess(payload) {
  return {
    type: SHOPPING_ITEM_SUCCESS,
    payload
  }
}

export function shoppingItemError(error) {
  return {
    type: SHOPPING_ITEM_ERROR,
    error
  }
}

export function shoppingItem(item) {
  return async function (dispatch) {
    dispatch(shoppingItemRequest());
    try {
      dispatch(shoppingItemSuccess(item));
    } catch (error) {
      dispatch(shoppingItemError(error.message));
    }
  }
}
