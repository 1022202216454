import React, { useRef } from 'react';
import Carousel from 'react-slick';
import SimpleThumbCard from '../CarucelOnVideo/SimpleThumb';
import useStyles from './all-categories-style';

const settings = {
  dots: false,
  speed: 500,
  slidesToShow: 1,
  arrows: false,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 7000,
  responsive: [{
    breakpoint: 1024,
    settings: {
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true
    }
  }, {
    breakpoint: 600,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1
    }
  }]
};

export default function Feature({images}) {
  const classes = useStyles();
  const slider = useRef(null);

  return (
        <Carousel ref={slider} {...settings}>
          {images.map((item, index) => (
              <div key={index.toString()} className={classes.item}>
                <SimpleThumbCard title={item.title} logo={item.imageUrl}/>
              </div>
          ))}
        </Carousel>

  );
}
