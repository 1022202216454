import React, {Fragment} from 'react';
import brand from '../../const/brand';
import {Link, Title, Meta, HeadProvider} from 'react-head';
import ContactFrm from '../../components/Forms/Contact';


export default function Quotation() {
    return (
        <>
            <HeadProvider>
                <Title> { brand.crypto.name } &nbsp; - Cotización </Title>
                <Link rel="canonical" content="http://saludatualcance.com.do/"/>
                <Meta name="keywords" content="Contact,salud, salud a tu alcance"/>
            </HeadProvider>
            <Fragment>
                <div>
                    <ContactFrm/>
                </div>
            </Fragment>
        </>
    );
}
