import React, {useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import {useText} from '../../../theme/common';
import useStyles from './promotion-style';
import '../../../vendors/animate-slider.css';
import '../../../vendors/animate-slider-extends.css';
import {firebase} from "../../../api/firebase";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useRouter } from '../../../router/useRouter'

export default function Promotion() {
    const classes = useStyles();
    const router = useRouter()
    const theme = useTheme();
    const text = useText();
    const [coverPageList, setCoverPageList] = React.useState([]);
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        const coverPage = firebase.firestore()
            .collection('webpage_first_cover')
            .orderBy('createdAt', 'desc')
            .limit(6)
            .onSnapshot(documentSnapshots => {
                let info = [];
                documentSnapshots.forEach(doc => {
                    let data = doc.data();
                    data['id'] = doc.id;
                    info.push(data);
                });
                setCoverPageList(info);
            });

        return () => {
            coverPage()
        }
    }, [])

     if(coverPageList.length===0) return null

    const toRouter= async (url)=>{
        await router.push(url);
    }

    return (
        <div className={isMobile? classes.root2: classes.root}>
            <div className={classes.sliderWrap}>
                <Slider
                    infinite={true}
                    autoplay={3000}
                    touchDisabled={true}
                    duration={2000}
                    className="slider-wrapper"
                    previousButton={(<i className={clsx(classes.arrowIcon, 'ti-angle-right')}/>)}
                    nextButton={(<i className={clsx(classes.arrowIcon, 'ti-angle-right')}/>)}>
                    {coverPageList.map((item, index) => (
                        <div className={classes.item} key={index.toString()}>
                            <div className={classes.innerBg}>
                                <div className={classes.background}>
                                    <figure>
                                        <img src={item.imageUrl} alt={item.title}/>
                                    </figure>
                                </div>
                                <Grid container className={classes.row}>
                                    <Grid item sm={7} xs={12}>
                                        <div className={classes.text}>
                                            <Typography variant="h4">
                                                <span className={text.subtitle2}>{item.prevTitle}</span>
                                                {item.title}
                                            </Typography>
                                            <article className={classes.desc}>
                                                <Typography component="h6" className={text.paragraph}>
                                                    {item.description}
                                                </Typography>
                                            </article>
                                          {item.coverType.value==='offer'
                                            ?<>
                                              <Typography style={{ alignSelf: 'flex-start', textDecorationLine: 'line-through', textDecorationStyle: 'solid', fontSize: 16, marginBottom:-10 }} variant={'subtitle2'}>RD${item.price}</Typography>
                                              <Typography style={{ alignSelf: 'flex-start', fontSize: 30, color:'#c20008', fontWeight:'bold' }} variant={'subtitle2'}>RD${item.offerPrice} </Typography>
                                            </>
                                              : null
                                          }

                                          <section className={classes.btnArea}>
                                            {item.leftButtonName.length> 0 &&
                                            <Button onClick={()=>toRouter(item.leftButtonUrl.value)} className={classes.button1}>
                                              {item.leftButtonName}
                                            </Button>}
                                            {item.rightButtonName.length > 0 &&
                                            <Button onClick={()=>toRouter(item.rightButtonUrl.value)} className={classes.button2}>
                                              {item.rightButtonName}
                                            </Button>}
                                          </section>

                                        </div>
                                    </Grid>
                                    <Grid item sm={5} xs={12}>
                                        <Hidden xsDown>
                                            <div className={classes.image}>
                                                <figure>
                                                    <img height={440} width={300} src={item.imageUrl} alt={item.title}/>
                                                </figure>
                                            </div>
                                        </Hidden>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
}
