import React, { useEffect, useRef } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Carousel from 'react-slick';
import useStyles from './services-style';
import TitleIcon from '../Title/WithIcon';
import Card from '../Cards/Default';
import DotsParallax from '../Parallax/Dots';
import services1 from  '../../images/home/Recuperado.jpg'
import services2 from  '../../images/home/suministros.jpg'
import services4 from  '../../images/home/movilidad.jpg'
import Apps from '@material-ui/icons/Apps';

const servicesList = [
  {
    title: 'Ingeniería Hospitalaria',
    desc: 'Conoce nuestro moderno y avanzado Departamento de Ingeniería Hospitalaria.',
    img: 'https://firebasestorage.googleapis.com/v0/b/formulario-de-cotizacion.appspot.com/o/ing.jpg?alt=media&token=0eb6a2f2-d6f1-4a18-a911-31007d09bc1b',
    link:'/ingenieria-hospitalaria'
  }, {
    title: 'Bioseguridad',
    desc: 'Ofrecemos las más avanzadas soluciones en desinfección y saneamiento de áreas de alto contacto.',
    img: services1,
    link:'/bioseguridad'
  }, {
    title: 'Suministros Médicos',
    desc: 'Conoce nuestra tienda en línea y selecciona los productos que necesitas desde la comodidad del hogar u oficina.',
    img: services2,
    link:'/tienda'
  },
  {
    title: 'Movilidad Profesional',
    desc: 'Contamos con las unidades de traslados más modernas y equipadas para garantizar el servicio que requieras..',
    img: services4,
    link:'/movilidad'
  },
  // {
  //   title: 'Marketing',
  //   desc: 'Ofrecemos las más avanzadas soluciones en Marketing para empresas y actores primarios del sector salud.',
  //   img: imgApi.services[3]
  // }
];

export default function Services() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const classes = useStyles();
  const slider = useRef(null);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    arrows: false,
    slidesToScroll: 1,
    variableWidth: true,
    responsive: [{
      breakpoint: 1100,
      settings: {
        slidesToShow: 3,
      }
    }, {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
      }
    }, {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      }
    }]
  };

  useEffect(() => {
    if (window.innerWidth > 1200) {
      const limit = window.innerWidth > 1400 ? 3 : 2;
      const lastSlide = Math.floor(servicesList.length - limit);
      slider.current.slickGoTo(lastSlide);
    }
  }, []);

  return (
    <div className={classes.root}>
      <DotsParallax />
      <div className={classes.carouselHandle}>
        <div className={classes.carouselWrap}>
          <Carousel ref={slider} {...settings}>
            {isDesktop && (
              <div className={classes.item}>
                <div className={classes.carouselProp}>
                  <div />
                </div>
              </div>
            )}
            {servicesList.map((item, index) => (
              <div className={classes.item} key={index.toString()}>
                <Card
                  title={item.title}
                  desc={item.desc}
                  img={item.img}
                  link={item.link}
                  button={'Ver detalles'}
                />
              </div>
            ))}
            {isDesktop && (
              <div className={classes.item}>
                <div className={classes.carouselProp}>
                  <div />
                </div>
              </div>
            )}
          </Carousel>
        </div>
      </div>
      <div className={classes.floatingTitle}>
        <Container fixed>
          <div className={classes.title}>
            <TitleIcon text={'Nuestros servicios'} icon={<Apps className={classes.icon}/>} extended />
          </div>
        </Container>
      </div>
    </div>
  );
}
