import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import {useSelector} from "react-redux";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    color:'#fff',
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  closeIcon: {
    '&:focus': {
      outline: 'none',
    },
  },
  gridImg: {
    margin: 50
  },
  button2:{
    fontSize:12,
    fontWeight:'bold',
    '&:hover': {
      borderColor:'#4caf50',
      borderStyle: 'solid',
      borderWidth:1,
      backgroundColor:'#fff',
      color: '#4caf50'
    },
    '&:focus': {
      outline: 'none',
    },
  },
  gridItem: {
    margin: 15,
    justifyContent: 'center',
    flexDirection: 'column',
    display: 'flex',
  },
  divButtonCar:{
    marginTop:15,
    justifyContent:'flex-start',
    flexDirection:'row',
    display: 'flex',
  },

}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogFull({ open, handleClose, detailsItems, addItem }) {
  const classes = useStyles();
  const shopping_item = useSelector(state => state.shopping_item);

  return (
    <div>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton className={classes.closeIcon} edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon/>
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {detailsItems.title}
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid spacing={2} container direction="row" justify="flex-start" alignItems="center">

          <Grid item xs={12} xl={12} sm={3} md={3} lg={3} className={clsx(classes.gridImg)}>
            <img width={350} height={350} style={{ alignSelf: 'center' }} src={detailsItems.imageUrl} alt={detailsItems.title}/>
          </Grid>

          <Grid item xs={12} xl={12} sm={8} md={8} lg={8} className={clsx(classes.gridItem)}>
            <div className={classes.divText}>
              <Typography style={{ alignSelf: 'flex-start', color: '#616161' }} variant={'h4'}> {detailsItems.title} </Typography>
              {/*<Typography style={{ alignSelf: 'flex-start' }} variant={'h6'}>RD$ {detailsItems.price} </Typography>*/}
              <div style={{ flexDirection: 'row', display: 'flex' }}>
                <Typography style={{ alignSelf: 'flex-start', marginRight: 5, color: '#757575' }} variant={'subtitle2'}>size</Typography>
                <Typography style={{ alignSelf: 'flex-start' }} variant={'subtitle2'}> {detailsItems.size.length > 0 ? detailsItems.size.map(x => {return x.label;}).join(', ') : 'N/A'} </Typography>
              </div>
            </div>
            <Typography align={'justify'} style={{ alignSelf: 'flex-start', marginTop:5 }} variant={'h6'}>{detailsItems.description} </Typography>
            <div className={classes.divButtonCar}>
              <div>
                <Button onClick={() => addItem(detailsItems.id)} startIcon={<ShoppingCartIcon/>} className={classes.button2} variant="contained" color="primary">
                  Agregar {shopping_item.find(x => x.id === detailsItems.id) ? '( ' + shopping_item.find(x => x.id === detailsItems.id).quantity + ' )' : null}
                </Button>
              </div>
            </div>
          </Grid>

        </Grid>
      </Dialog>
    </div>
  );
}
