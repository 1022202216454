import React, {Component} from "react";
import {StylesProvider, jssPreset} from '@material-ui/styles'
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {Provider} from 'react-redux';
import Reducer from '../src/redux/reducers';
import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import rtl from 'jss-rtl';
import {create} from 'jss';
import appTheme from '../src/theme/appTheme';
import RouterList from '../src/View/Router'

const store = createStore(Reducer, applyMiddleware(thunk));
let themeType = 'light';

class App extends Component {
    state = {
        loading: true,
        theme: {...appTheme('oceanBlue', themeType)}
    };

    componentDidMount() {
        this.props.hideLoader();
    }

    render() {
        const {theme} = this.state;
        const muiTheme = createMuiTheme(theme);
        const jss = create({plugins: [...jssPreset().plugins, rtl()]});
        return (
            <Provider store={store}>
                <StylesProvider jss={jss}>
                    <MuiThemeProvider theme={muiTheme}>
                        <CssBaseline/>
                        <RouterList/>
                    </MuiThemeProvider>
                </StylesProvider>
            </Provider>
        );
    }
}

export default App;
