import React from 'react';
import useStyles from './logo-style';
import Title from '../TitleGallery/Title';
import logo4 from '../../images/logo/logo4.png'
import logo5 from '../../images/logo/logo5.png'
import logo7 from '../../images/logo/logo7.png'
import logos3 from '../../images/logo/logos3.png'

const logos = [logo4, logo5, logo7, logos3,];

function CompanyLogo() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Title>
        <strong style={{ marginRight: 10 }}>
          Empresas
        </strong>
        afiliadas
      </Title>
      <div className={classes.logo}>
        {logos.map((logo, index) => (
            <img src={logo} alt={'logo' + index.toString()} key={index.toString()}/>
          )
        )}
      </div>
    </div>
  );
}


export default CompanyLogo;

