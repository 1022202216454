import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { fbConfig } from './config'

try {
  firebase.initializeApp(fbConfig);
} catch (e) {
  console.log(e.message)
}

const firestore = firebase.firestore();
const auth = firebase.auth();

export {
  firebase,
  fbConfig,
  auth,
  firestore,
};
