import React, { useRef } from 'react';
import Carousel from 'react-slick';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import clsx from 'clsx';
import BackIcon from '@material-ui/icons/ArrowBackSharp';
import NextIcon from '@material-ui/icons/ArrowForwardSharp';
import useStyles from './shop-style';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';


export default function CarouselOffer({ itemDetail, addItem, offerList, offerTitle }) {
  const classes = useStyles();
  const slider = useRef(null);
  const shopping_item = useSelector(state => state.shopping_item);

  const prevSlide = () => {
    slider.current.slickNext();
  };
  const nextSlide = () => {
    slider.current.slickPrev();
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <>
      <Grid item style={{ marginBottom: 40, marginTop: 80 }} ms={12} xs={12} md={12}>
        <Typography variant="h2" align="center" gutterBottom>
          {offerTitle}
        </Typography>
      </Grid>
      <div style={{marginBottom:100}} className={classes.carouselWrap}>
        <Button style={{ color: 'green' }} className={clsx(classes.nav, classes.prev)} onClick={nextSlide}>
          <BackIcon/>
        </Button>
        <Button style={{ color: 'green' }} className={clsx(classes.nav, classes.next)} onClick={prevSlide}>
          <NextIcon/>
        </Button>

          <Carousel ref={slider} {...settings}>
            {offerList.map((item, index) => {
              return (
                <Card key={index} className={classes.paper}>
                  <div className={classes.paper}>
                    <img width={150} height={150} style={{ alignSelf: 'center' }} src={item.imageUrl} alt={item.title}/>
                    <div className={classes.divText}>
                      <Typography style={{ alignSelf: 'flex-start', color: '#616161' }} variant={'subtitle1'}> {item.title.length > 25 ? item.title.substr(0, 25) + '...' : item.title} </Typography>
                      <div style={{ flexDirection: 'column', display: 'flex' }}>
                        <Typography style={{ alignSelf: 'flex-start', marginRight: 5,
                          textDecorationLine: 'line-through',
                          textDecorationStyle: 'solid',
                        }} variant={'subtitle2'}>RD${item.price}</Typography>
                        <Typography style={{ alignSelf: 'flex-start', fontSize: 25, color:'#c20008', fontWeight:'bold' }} variant={'subtitle2'}>RD${item.offerPrice} </Typography>
                      </div>
                      <div style={{ flexDirection: 'row', display: 'flex' }}>
                        <Typography style={{ alignSelf: 'flex-start', marginRight: 5, color: '#757575' }} variant={'subtitle2'}>size:</Typography>
                        <Typography style={{ alignSelf: 'flex-start' }} variant={'subtitle2'}> {item.sizeOption} </Typography>
                      </div>
                    </div>

                    <div className={classes.divButtonCar}>
                      <div style={{ marginRight: 5 }}>
                        <Button onClick={() => itemDetail(item.id)} className={classes.button1} size="small"
                                variant="outlined" color="secondary">
                          Detalles
                        </Button>
                      </div>
                      <div>
                        <Button onClick={() => addItem(item.id)} startIcon={<ShoppingCartIcon/>}
                                className={classes.button2} size="small" variant="contained" color="primary">
                          Agregar {shopping_item.find(x => x.id === item.id) ? '( ' + shopping_item.find(x => x.id === item.id).quantity + ' )' : null}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Card>
              );
            })}
          </Carousel>
      </div>
    </>
  );
}
