export const SHOPPING_VALUES_REQUEST = "SHOPPING_VALUES_REQUEST";
export const SHOPPING_VALUES_SUCCESS = "SHOPPING_VALUES_SUCCESS";
export const SHOPPING_VALUES_ERROR = "SHOPPING_VALUES_ERROR";

export function shoppingValuesRequest() {
  return {
    type: SHOPPING_VALUES_REQUEST
  }
}

export function shoppingValuesSuccess(payload) {
  return {
    type: SHOPPING_VALUES_SUCCESS,
    payload
  }
}

export function shoppingValuesError(error) {
  return {
    type: SHOPPING_VALUES_ERROR,
    error
  }
}

export function shoppingValues(value) {
  return async function (dispatch) {
    dispatch(shoppingValuesRequest());
    try {
      dispatch(shoppingValuesSuccess(value));
    } catch (error) {
      dispatch(shoppingValuesError(error.message));
    }
  }
}
