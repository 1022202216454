import React, {useState, useEffect, Fragment, useMemo} from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import { NavLink,} from 'react-router-dom';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Scrollspy from 'react-scrollspy';
import MobileMenu from './MobileMenu';
import logo from '../../images/agency-logo.svg';
import '../../vendors/hamburger-menu.css';
import useStyles from './header-style';
import navMenu from './menu';
import { useSelector} from 'react-redux';
import {listRouters} from "../../router/routers";
import {useRouter} from "../../router/useRouter";
import Container from "@material-ui/core/Container";
import {Badge} from "@material-ui/core";

export default function Header(props) {
    const router = useRouter()
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const shopping_value = useSelector(state => state.shopping_value);

    const [fixed, setFixed] = useState(false);
    let flagFixed = false;
    const handleScroll = () => {
        const doc = document.documentElement;
        const scroll = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
        const newFlagFixed = (scroll > 80);
        if (flagFixed !== newFlagFixed) {
            setFixed(newFlagFixed);
            flagFixed = newFlagFixed;
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, []);


    const classes = useStyles();
    const {invert} = props;

    const [openDrawer, setOpenDrawer] = useState(false);
    const handleOpenDrawer = () => {
        setOpenDrawer(!openDrawer);
    };

    const toContact = async () => {
        await router.push('/contacto');
    }

    return (
        <Fragment>
            {isMobile && (<MobileMenu open={openDrawer} toggleDrawer={handleOpenDrawer}/>)}
            <AppBar
                component="header"
                position="relative"
                id="header"
                className={clsx(
                    classes.header,
                    fixed && classes.fixed,
                    invert && classes.invert,
                    openDrawer && classes.openDrawer
                )}
            >
                <Container fixed={isDesktop}>
                    <div className={classes.headerContent}>
                        <nav className={clsx(classes.navLogo, invert && classes.invert)}>
                            <div className={classes.logo}>
                                <NavLink className={`navbar-brand`} to="/">
                                    <img src={logo} alt=""/>
                                    <img src={logo} alt="logo"/>
                                </NavLink>
                            </div>

                            {isMobile && (
                                <IconButton onClick={handleOpenDrawer}
                                            className={clsx('hamburger hamburger--spin', classes.mobileMenu, openDrawer && 'is-active')}>
                  <span className="hamburger-box">
                    <span className={clsx(classes.bar, 'hamburger-inner')}/>
                  </span>
                                </IconButton>
                            )}
                        </nav>
                        <nav className={clsx(classes.navMenu, classes.navLogo, invert && classes.invert)}>
                            <ul className='navbar-nav menu ml-auto'>
                                {isDesktop && (
                                    <Scrollspy items={navMenu} currentClassName="active">
                                        {listRouters.map(router => {
                                            if (!router.collapse) {
                                                if (router.anchorLink) {
                                                    return (
                                                        <li key={router.label} className={`nav-item`}>
                                                            {invert ? (
                                                                // eslint-disable-next-line
                                                                <Button href={'/#' + router.name}>
                                                                    {router.label}
                                                                </Button>
                                                            ) : (
                                                                // eslint-disable-next-line
                                                                <Button component={AnchorLink} offset={0}
                                                                        href={router.path}>
                                                                    {router.label}
                                                                </Button>
                                                            )}
                                                        </li>
                                                    )
                                                } else {
                                                    return (
                                                        <li key={router.label} className={`nav-item`}>
                                                            <Button onClick={toContact}>
                                                                {router.label}
                                                            </Button>
                                                        </li>
                                                    )
                                                }
                                            }
                                        })}
                                    </Scrollspy>
                                )}
                            </ul>
                        </nav>

                        <nav className={clsx('navbar navbar-expand-lg menu_one', classes.linkColor, classes.navMenu2)}>
                            <div className={`container `}>
                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul className={`navbar-nav menu ml-auto`}>
                                        {listRouters.map(router => {
                                            if (router.collapse) {
                                                return (
                                                    <li key={router.label} className="dropdown submenu nav-item">
                                                        <Badge invisible={!router.badge} badgeContent={shopping_value}   color="error">
                                                        <Button style={router.style} data-toggle="dropdown"
                                                                role="button" aria-haspopup="true"
                                                                aria-expanded="false">
                                                            {router.iconVisible ?
                                                                <i style={{fontSize: 25, color: '#000'}}
                                                                   className={clsx(router.icon)}/> : router.label}
                                                        </Button>
                                                        </Badge>
                                                        <ul role="menu" className="dropdown-menu">
                                                            {
                                                                router.child.map(otherRouter => {
                                                                    return (
                                                                        <li key={router.label + otherRouter.label}
                                                                            className="nav-item"><NavLink exact
                                                                                                          className="nav-link"
                                                                                                          to={otherRouter.path}>{otherRouter.label}</NavLink>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </li>
                                                )
                                            }
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                </Container>
            </AppBar>
        </Fragment>
    );
}
