import React, {Component} from 'react';
import {Link, NavLink} from 'react-router-dom';
import Sticky from 'react-stickynode';
import {listRouters} from '../router/routers'
class CustomNavbar extends Component {
    render() {
        const {mClass, nClass, cClass, slogo} =this.props;
        return (
            <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
                <header className="header_area">
                <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
                    <div className={`container ${cClass}`}>
                        <Link className={`navbar-brand ${slogo}`} to="/">
                            <img width={125} src={require("../images/logo.png")} alt=""/>
                            <img width={125} src={require("../images/logo.png")} alt="logo"/>
                        </Link>
                        <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="menu_toggle">
                                <span className="hamburger">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                                <span className="hamburger-cross">
                                    <span></span>
                                    <span></span>
                                </span>
                            </span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className={`navbar-nav menu ml-auto ${nClass}`}>
                                {listRouters.map(router => {
                                    if (router.name==='contacto'){
                                       return (
                                           <li key={router.label} className={`nav-item`}>
                                               <NavLink to={router.path} className={`nav-link ${'tClass'}`}>
                                                   {router.label}
                                               </NavLink>
                                           </li>
                                       )
                                    }
                                    if (router.collapse) {
                                        return (
                                            <li key={router.label} className="dropdown submenu nav-item">
                                                <Link to="./" title="Pages" className="dropdown-toggle nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                                                    {router.iconVisible?<i style={{fontSize:24, marginRight:-15}} className={`${router.icon}`}/> :router.label}
                                                </Link>
                                                <ul role="menu" className=" dropdown-menu">
                                                    {
                                                        router.child.map(otherRouter => {
                                                            return (
                                                                <li key={router.label+otherRouter.label} className="nav-item"><NavLink exact className="nav-link" to={otherRouter.path}>{otherRouter.label}</NavLink></li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </li>
                                        )
                                    }
                                })}
                            </ul>
                        </div>
                    </div>
                </nav>
                </header>
            </Sticky>
        );
    }
}

export default CustomNavbar;
