module.exports = {
  crypto: {
    name: 'Salud a tu Alcance',
    desc: 'Brindamos soluciones avanzadas en Ingeniería Hospitalaria, Bioseguridad y Marketing cubriendo estándares de calidad, servicios, certificaciones, protocolos y normas internacionales.',
    prefix: 'Salud a tu Alcance',
    footerText: 'Salud a tu Alcance - todos los derechos reservados 2020',
    logoText: 'Salud a tu Alcance',
    projectName: 'Salud a tu Alcance',
    url: 'luxireact.ux-maestro.com/agency',
    img: '/static/images/agency-logo.png',
    notifMsg: 'COVID-19: Quédate en casa si te sientes mal. Si tienes fiebre, tos y dificultad para respirar, busca atención médica lo antes posible.'
  }
};
