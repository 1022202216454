import React, { Fragment, useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import { useDispatch, useSelector } from 'react-redux';
import { shoppingItem, shoppingValues, categoryArticle, articleByCategory } from '../../redux/actions';
import { firebase } from '../../api/firebase';
import { confirmAlert } from 'react-confirm-alert';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { useRouter } from '../../router/useRouter';
import Decoration from '../../components/FormsLanding/Decoration';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import DialogFull from '../../components/Dialog/DialogFull';
import useStyles from '../../components/Shop/shop-style';
import CarouselCover from '../../components/Shop/CarouselCover';
import CarouselOffer from '../../components/Shop/CarouselOffer';
import CoverDetails from '../../components/Shop/CoverDetails';
import Articles from '../../components/Shop/Articles';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    width: 250,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

export default function ShoppingCart(props) {
  const router = useRouter()
  const classes = useStyles();
  const { onToggleDark, onToggleDir } = props;
  const shopping_value = useSelector(state => state.shopping_value);
  const shopping_item = useSelector(state => state.shopping_item);
  const category_articles = useSelector(state => state.category_articles);
  const items = useSelector(state => state.articles);
  const dispatch = useDispatch();

  const [coverPageList, setCoverPageList] = React.useState([]);
  const [offerTitle, setOfferTitle] = React.useState('');
  const [offerList, setOfferList] = React.useState([]);

  const [detailsItems, setDetailsItems] = useState({});
  const [sectionR, setSectionR] = useState(null);
  const [sectionL, setSectionL] = useState(null);
  const [openDialogFull, setOpenDialogFull] = useState(false);
  const [category, setCategory] = React.useState(0);

  const user = firebase.auth().currentUser;

  useEffect(() => {
    const coverPage = firebase.firestore()
      .collection('coverPage')
      .orderBy('createdAt', 'desc')
      .limit(5)
      .onSnapshot(documentSnapshots => {
        let info = [];
        documentSnapshots.forEach(doc => {
          let data = doc.data();
          data['id'] = doc.id;
          info.push(data);
        });
        setCoverPageList(info);
      });

    const offer = firebase.firestore()
      .collection('offer')
      .orderBy('createdAt', 'desc')
      .limit(10)
      .onSnapshot(documentSnapshots => {
        let info = [];
        documentSnapshots.forEach(doc => {
          let data = doc.data();
          data['id'] = doc.id;
          data['sizeOption'] = data.size.map(x => {
            return x.label;
          })
            .join(', ');
          info.push(data);
        });
        setOfferList(info);
      });

    const details = firebase.firestore()
      .collection('details')
      .orderBy('createdAt', 'desc')
      .limit(2)
      .onSnapshot(documentSnapshots => {
        let info = [];
        documentSnapshots.forEach(doc => {
          let data = doc.data();
          data['id'] = doc.id;
          info.push(data);
        });

        let sR = info.find(x => x.section.label === 'Derecha');
        let sL = info.find(x => x.section.label === 'Isquierda');
        if (sR) {
          setSectionR(sR);
        }else {
          setSectionR(null);
        }
        if (sL) {
          setSectionL(sL);
        }else {
          setSectionL(null);
        }
      });

    const titleOffer = firebase.firestore()
      .collection('offer_title')
      .onSnapshot(documentSnapshots => {
        let info = [];
        documentSnapshots.forEach(doc => {
          let data = doc.data();
          info.push(data);
        });
        let title = info[0] ? info[0].title : '';
        setOfferTitle(title);
      });

    dispatch(categoryArticle());


    return () => {
      coverPage();
      offer();
      details();
      titleOffer();
    };
  }, []);

  const handleCategory = (event) => {
    firebase.firestore()
      .collection('articles')
      .where('category.value', '==', event.target.value)
      .limit(8)
      .get()
      .then(function (querySnapshot) {
        let info = [];
        querySnapshot.forEach(doc => {
          let data = doc.data();
          data['id'] = doc.id;
          data['sizeOption'] = data.size.map(x => {return x.label;}).join(', ');
          info.push(data);
        });
        dispatch(articleByCategory(info));
      })
      .catch(function (error) {
        console.log('Error getting documents: ', error);
      });

    setCategory(event.target.value);
  };

  const toLogin= async ()=>{
    await router.push('/iniciar-sesion');
  }
  const toRegister= async ()=>{
    await router.push('/registrarse');
  }

  const addItem = (id) => {
    if(!user){
      setOpenDialogFull(false)
      return  confirmAlert({
        customUI: ({onClose}) => {
          return (
            <Paper elevation={3} style={{padding:35, justifyContent:'center'}} >
              <h2>¿Ya estas registrado?</h2>
              <p>Debes iniciar sesión antes de agregar artículos al carrito</p>

              <div style={{margin:25, flexDirection:'row', display:'flex', justifyContent:'space-around'}}>
                <Button onClick={()=>{onClose(); toLogin().finally()}} className={classes.button1}  variant="outlined" >
                  Iniciar sesión
                </Button>
                <Button onClick={()=>{onClose(); toRegister().finally()}} className={classes.button2} variant="contained" >
                  Registrarme
                </Button>
              </div>

            </Paper>
          );
        }
      });
    }

    let newValue = shopping_value + 1;
    let shoppingData = [...shopping_item];
    dispatch(shoppingValues(newValue));
    let item = shopping_item.find(item => item.id === id);

    if (item === undefined) {
      let thisItem = items.find(item => item.id === id);
      thisItem.quantity = thisItem.quantity + 1;
      shoppingData.push(thisItem);
      dispatch(shoppingItem(shoppingData));
    } else {
      item.quantity = item.quantity + 1;
      shoppingData.splice(shopping_item.findIndex(x => x.id === id), 1, item);
      dispatch(shoppingItem(shoppingData));
    }
  };

  const addItemOffer=(id)=>{
    if(!user){
      return  confirmAlert({
        customUI: ({onClose}) => {
          return (
            <Paper elevation={3} style={{padding:35, justifyContent:'center'}} >
              <h2>¿Ya estas registrado?</h2>
              <p>Debes iniciar sesión antes de agregar artículos al carrito</p>

              <div style={{margin:25, flexDirection:'row', display:'flex', justifyContent:'space-around'}}>
                <Button onClick={()=>{onClose();toLogin().finally()}} className={classes.button1}  variant="outlined" >
                  Iniciar sesión
                </Button>
                <Button onClick={()=>{onClose();toRegister().finally()}} className={classes.button2} variant="contained" >
                  Registrarme
                </Button>
              </div>

            </Paper>
          );
        }
      });
    }

    let newValue = shopping_value + 1;
    let shoppingData = [...shopping_item];
    dispatch(shoppingValues(newValue));
    let item = shopping_item.find(item => item.id === id);


    if (item === undefined) {
      let thisItem = offerList.find(item => item.id === id);
      thisItem.quantity = thisItem.quantity + 1;
      shoppingData.push(thisItem);
      dispatch(shoppingItem(shoppingData));
    } else {
      item.quantity = item.quantity + 1;
      shoppingData.splice(shopping_item.findIndex(x => x.id === id), 1, item);
      dispatch(shoppingItem(shoppingData));
    }
  }

  const itemDetail = (id) => {
    setOpenDialogFull(true);
    let thisItem = offerList.find(item => item.id === id);
    setDetailsItems(thisItem);
  };

  const itemDetailArticle = (thisItem) => {
    setDetailsItems(thisItem);
    setOpenDialogFull(true);
  };


  return (
    <Fragment>
      {detailsItems.title !== undefined &&
      <DialogFull detailsItems={detailsItems} open={openDialogFull} addItem={addItem} handleClose={() => setOpenDialogFull(false)}/>}
      <div className={classes.mainWrap}>
        <Header onToggleDark={onToggleDark} onToggleDir={onToggleDir} invert/>
        <Container maxWidth="lg" className={classes.containerWrap}>
          <Decoration/>
          <div className={clsx(classes.spaceTop)}>

            {coverPageList.length > 0 && <CarouselCover coverPageList={coverPageList}/>}

            {offerList.length > 0 && <CarouselOffer offerTitle={offerTitle} offerList={offerList} itemDetail={itemDetail} addItem={addItemOffer}/>}

              <CoverDetails sectionR={sectionR}  sectionL={sectionL} />

            <Grid spacing={2} container direction="row" justify="flex-start" alignItems="center">
              <Grid item xs={12} xl={12} sm={8} md={8} lg={8}>
                <Grid item ms={12} xs={12} md={12}>
                  <Typography variant="h4" align="center" gutterBottom>
                    ¡&#84;odo lo que necesitas esta aquí!
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={12} xl={12} sm={4} md={4} lg={4}>
                <FormControl className={classes.NativeSelect}>
                  <NativeSelect
                    style={{ alignSelf: 'flex-end' }}
                    id="demo-customized-select-native"
                    value={category}
                    onChange={handleCategory}
                    input={<BootstrapInput/>}
                  >
                    {category_articles.map((x, index) => {
                      return (
                        <option key={index} value={x.value}>{x.label}</option>
                      );
                    })}
                  </NativeSelect>
                </FormControl>
              </Grid>
            </Grid>

            <Articles classes={classes} category={category} items={items} shopping_item={shopping_item} addItem={addItem} itemDetailArticle={itemDetailArticle}/>
          </div>

        </Container>
      </div>
      <section className={classes.spaceTop}>
        <Footer/>
      </section>
    </Fragment>
  );
}
