import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import {useTheme} from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import {NavLink} from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import logo from '../../images/agency-logo.svg';
import brand from '../../const/brand';
import {useTextAlign} from '../../theme/common';
import useStyles from './footer-style';

function Copyright() {
    return (
        <Typography variant="body2" display="block" color="textSecondary">
            &copy;&nbsp;
            {brand.crypto.footerText}
        </Typography>
    );
}

const footers = [
    {
        title: 'Compañía',
        description: ['Historia', 'Contacto', 'Cotización'],
        link: ['/empresa', '/contacto', 'https://formulario-de-cotizacion.web.app/'],
    },
    {
        title: 'Otros',
        description: ['COVID-19', 'Millo'],
        link: ['/covid-19', 'https://millo.dev'],
    },
    {
        title: 'Legal',
        description: ['Privacidad', 'Términos'],
        link: ['/privacidad', '/terminos'],
    },
];



export default function Footer() {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const classes = useStyles();
    const align = useTextAlign();

    return (
        <Container maxWidth="lg" component="footer" className={classes.footer}>
            <Grid container spacing={4}>
                <Grid item xs={12} md={3}>
                    <div className={classes.logo}>
                        <img src={logo} alt="logo"/>
                    </div>
                    {isDesktop && <Copyright/>}
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={4} justify="space-evenly">
                        {footers.map(footer => (
                            <Grid item xs={12} md={3} key={footer.title} className={classes.siteMapItem}>
                                {isDesktop && (
                                    <div>
                                        <Typography variant="h6" className={classes.title} color="textPrimary"
                                                    gutterBottom>
                                            {footer.title}
                                        </Typography>
                                        <ul>
                                            {footer.description.map((item, index) => (
                                                <li key={item}>
                                                    <NavLink exact to={footer.link[index]} variant="subtitle1"
                                                          color="textSecondary">
                                                        {item}
                                                    </NavLink>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                                {isMobile && (
                                    <ExpansionPanel
                                        square
                                        classes={{
                                            root: classes.accordionRoot,
                                        }}
                                    >
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon className={classes.accordionIcon}/>}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            classes={{
                                                content: classes.accordionContent,
                                            }}
                                        >
                                            <strong>
                                                {footer.title}
                                            </strong>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <ul>
                                                {footer.description.map((item, index) => (
                                                    <li key={item}>
                                                        <NavLink exact to={footer.link[index]} variant="subtitle1"
                                                              color="textSecondary">
                                                            {item}
                                                        </NavLink>
                                                    </li>
                                                ))}
                                            </ul>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                )}
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                <Grid item xs={12} md={3}>

                    <div className={classes.socmed}>
                        <Link target="_blank" href={'https://www.facebook.com/saludatualcancerd'} variant="subtitle1"
                              color="textSecondary">
                            <IconButton aria-label="TW" className={classes.margin} size="small">
                                <i className="ti-facebook"/>
                            </IconButton>
                        </Link>
                        <Link target="_blank" href={'https://www.instagram.com/saludatualcancerd'} variant="subtitle1" color="textSecondary">
                            <IconButton aria-label="IG" className={classes.margin} size="small">
                                <i className="ti-instagram"/>
                            </IconButton>
                        </Link>
                        <Link target="_blank" href={'https://www.linkedin.com/company/saludatualcancerd'}
                              variant="subtitle1" color="textSecondary">
                            <IconButton aria-label="LI" className={classes.margin} size="small">
                                <i className="ti-linkedin"/>
                            </IconButton>
                        </Link>
                        <Link target="_blank" href={'https://www.youtube.com/channel/UCscs_dsKA3vuz4k45XM25gQ'} variant="subtitle1" color="textSecondary">
                            <IconButton aria-label="LI" className={classes.margin} size="small">
                                <i className="ti-youtube"/>
                            </IconButton>
                        </Link>
                    </div>
                </Grid>
            </Grid>
            {isMobile && (
                <div className={align.textCenter}>
                    <Box p={4}>
                        <Copyright/>
                    </Box>
                </div>
            )}
        </Container>
    );
}
