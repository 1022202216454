import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Slide from '@material-ui/core/Slide';
import useStyles from './notification-style';
import msg from '../../const/brand';

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

export default function Notification() {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    localStorage.setItem('snackbarContent', 'true');
    setOpen(false);
  };
  return (
    <Snackbar
      TransitionComponent={TransitionUp}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      classes={{
        root: classes.notification,
      }}
      open={open}
      onClose={handleClose}
    >
      <SnackbarContent
        message={<Typography style={{color:'#fff'}} id="message-id">{msg.crypto.notifMsg}</Typography>}
        classes={{
          action: classes.action
        }}
        action={(
          <Button key="undo" variant="contained" className={classes.button} onClick={handleClose}>
              Aceptar
          </Button>
        )}
      />
    </Snackbar>
  );
}
