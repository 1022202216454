import React, { Fragment } from 'react';
import LoginForm from '../../components/RLForms/Login';

function Login() {
  return (
    <Fragment>
      <div>
        <LoginForm/>
      </div>
    </Fragment>
  );
}

export default Login;
