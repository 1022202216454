import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useStyles from './policy-style';

export default function PrivacyInfo() {
    const classes = useStyles();
    return (
        <div className={classes.policyWrap}>
            <Container maxWidth="md">
                <Grid container>
                    <Grid item md={12} xs={12}>
                        <div className={classes.text}>
                            <Typography>
                                En esta Política de Privacidad, Salud A Tu Alcance pone a tu disposición toda la
                                información
                                sobre el tratamiento de datos de carácter personal que realizamos.
                            </Typography>

                            <Typography>
                                Un dato de carácter personal es cualquier información que te identifique, bien
                                directamente como
                                tu nombre y apellidos, dirección electrónica o email, dirección postal, teléfono, o bien
                                otros
                                datos que, aunque no te identifican directamente si están directamente relacionados
                                contigo.
                            </Typography>
                            <Typography>
                                Para Salud A Tu Alcance la protección de tu información y datos es muy importante, por
                                ello,
                                hemos implementado mecanismos seguros de transmisión de datos.
                            </Typography>

                            <Typography variant="h5">
                                ¿QUIÉN ES EL RESPONSABLE DEL TRATAMIENTO DEL TRATAMIENTO DE LOS DATOS?
                            </Typography>

                            <Typography>
                                Todos los datos personales que nos puedas facilitar voluntariamente durante tu acceso y
                                visita a
                                nuestra página, ya sea por ponerte en contacto con nosotros, acceder a determinados
                                servicios,
                                acceder al área privada como usuario, solicitar información, formar parte de Salud A Tu
                                Alcance
                                o bien suscribirte a nuestros contenidos, serán incorporados y tratados, tanto a través
                                de
                                medios telemáticos, informáticos y no automatizados, por:
                            </Typography>

                            <Typography>
                                <b>Salud A Tu Alcance</b><br/><br/>
                                Calle Proyecto 3 #28 Reparto Oquet, Santiago De Los Caballeros, RD <br/><br/>
                                {/*<br/>RNC: ####### <br/><br/>*/}
                                Para cualquier duda, pregunta o comentario que quieras hacernos llegar, te facilitamos
                                los
                                siguientes puntos de contacto:<br/><br/>
                                Teléfono:809-489-9843 <br/><br/>
                                Email: info@saludatualcance.com.do
                            </Typography>

                            <Typography variant='h5'>
                                ¿QUIÉN PUEDE FACILITARNOS DATOS?
                            </Typography>
                            <Typography>
                                Usuarios y Visitantes de la página web <b>https://saludatualcance.com.do </b> puede
                                facilitarnos
                                datos de carácter personal en nuestros formularios, servicios y contenidos; datos que
                                nos
                                facilitas voluntariamente y con la finalidad de que te prestemos los servicios, te
                                incorpores
                                dentro de la Salud A Tu Alcance , te suscribas a nuestros contenidos o puedas
                                interactuar
                                activamente con nosotros.
                            </Typography>

                            <Typography>
                                Todos los datos que nos facilites voluntariamente, bien porque son necesarios para
                                prestarte el
                                servicio o información, estarán marcados con un asterisco o te indicará la página que
                                debes
                                facilitarlos o bien datos adicionales que completan tu información y solicitud, son
                                veraces y
                                correctos, es decir, responden a tu situación real.
                            </Typography>
                            <Typography >
                                En Salud A Tu Alcance no recogemos datos de menores de edad; por ello, si tienes menos
                                de 14
                                años, acude a tus padres para que puedan decidir si facilitan tus datos y puedan
                                informarte
                                correctamente de la finalidad del tratamiento de los mismos.
                            </Typography>
                            <Typography >
                                Para determinados tratamientos de datos te solicitaremos que nos otorgues tu
                                consentimiento a
                                través de la aceptación o activación de las casillas de validación específicas.
                            </Typography>

                            <Typography variant='h5'>
                                ¿QUÉ DATOS RECOPILAMOS?
                            </Typography>


                            <Typography >
                                La información y datos personales que te solicitamos al enviarnos una consulta,
                                solicitar
                                información, suscribirte a contenidos, newsletter, revistas, eventos, congresos y otras
                                actuaciones de Salud A Tu Alcance, van a ser tus datos de contacto: nombre, apellidos,
                                dirección, email, teléfono contacto.
                            </Typography>

                            <Typography variant='h5'>
                                ¿CÓMO SE USA LA INFORMACIÓN Y DATOS QUE NOS FACILITAS?
                            </Typography>

                            <Typography >
                                La finalidad del tratamiento de los datos personales que haya facilitado a Salud A Tu
                                Alcance a
                                través de sus diferentes canales de comunicación u obtención de datos personales
                                dependerá del
                                motivo por el que nos los haya facilitado:
                            </Typography>

                            <Typography >
                                <ul>
                                    <li>
                                        Tratamiento de los datos de los candidatos, con el fin de valorar y gestionar su
                                        solicitud
                                        de empleo y en su caso, llevar a cabo las actuaciones necesarias para la
                                        selección y
                                        contratación de personal, a fin de ofertarle puestos que se ajusten a tu perfil.

                                    </li>
                                    <li>
                                        Tratamiento de datos para contactar con el remitente de la información, dar
                                        respuesta a
                                        su solicitud, petición o consulta y gestionar la publicación de consultas y
                                        comentarios
                                        y hacer un seguimiento posterior.
                                    </li>
                                    <li>
                                        Remitir periódicamente comunicaciones comerciales sobre servicios, eventos y
                                        noticias
                                        relacionadas con nuestra actividad profesional (por ejemplo, la newsletter),
                                        salvo que
                                        se indique lo contrario o se oponga o revoque su consentimiento.
                                    </li>
                                </ul>
                            </Typography>
                            <Typography >
                                Los datos personales facilitados se conservarán mientras dure su relación con Salud A Tu
                                Alcance, manteniéndose más allá cuando por imperativo legal se establezca. Igualmente,
                                le
                                comunicamos que sus datos personales no serán utilizados por Salud A Tu Alcance para la
                                elaboración de perfiles comerciales. El tratamiento de su información personal se
                                llevará a cabo
                                de forma lícita, leal, transparente, adecuada, pertinente, limitada, exacta y
                                actualizada. Es
                                por ello que Salud A Tu Alcance se compromete a adoptar todas las medidas razonables
                                para que
                                estos datos se supriman o rectifiquen sin dilación cuando sean inexactos.
                            </Typography>

                            <Typography >
                                Asimismo, el usuario garantiza que los datos aportados son verdaderos, exactos,
                                completos y
                                actualizados, siendo responsable de cualquier daño o perjuicio, directo o indirecto, que
                                pudiera
                                ocasionarse como consecuencia del incumplimiento de tal obligación. En caso de que el
                                usuario
                                facilite datos de terceros, manifiesta contar con el consentimiento de los mismos y se
                                compromete a trasladarle la información contenida en esta cláusula, eximiendo a Salud A
                                Tu
                                Alcance de cualquier responsabilidad en este sentido.
                            </Typography>

                            <Typography variant='h5'>
                                ¿Cómo y dónde se comparte la información?
                            </Typography>

                            <Typography >
                                Para determinadas actuaciones como son hacerte socio de Salud A Tu Alcance, o en
                                aquellos casos
                                en que te suscribas a algún servicio que conlleve el pago de una determinada cantidad
                                económica,
                                tus datos no van a ser compartidos con terceras empresas o personas ajenas a Salud A Tu
                                Alcance.
                                En los casos que te hemos indicado anteriormente, debemos compartir tu información con
                                las
                                administraciones públicas que tienen competencia en dichas áreas de acuerdo con las
                                obligaciones
                                legales existentes.
                            </Typography>

                            <Typography variant='h5'>
                                Seguridad de tus datos
                            </Typography>

                            <Typography >
                                Salud A Tu Alcance da gran importancia a la seguridad de toda la
                                información de identificación personal. Por ello, hemos adoptado medidas para proteger
                                correctamente los datos que tratamos y mantener su confidencialidad y respeto de tu
                                intimidad.
                            </Typography>

                            <Typography >
                                Una vez que hayamos recibido tu información, sobre dichos datos hemos implementado
                                medidas de
                                seguridad para garantizar el acceso, confidencialidad, integridad y evitar cualquier
                                pérdida,
                                mal uso y/o alteración de los datos que están bajo nuestro control.
                            </Typography>

                            <Typography variant='h5'>
                                Datos de navegación en <b>https://saludatualcance.com.do/ </b>
                            </Typography>

                            <Typography >
                                Salud A Tu Alcance solamente registrará datos anónimos con respecto a la navegación de
                                los
                                usuarios, con el fin de obtener datos estadísticos sobre el dominio del proveedor, fecha
                                y hora
                                de acceso al Web Site y número de visitantes diarios por página.
                            </Typography>

                            <Typography >
                                Además, con el fin de conocer la eficacia de los banners y links que apuntan a nuestro
                                sitio,
                                identificaremos también el link de procedencia de la visita. Toda la información
                                obtenida es
                                completamente anónima, y en ningún caso podría ser utilizada para identificar a un
                                usuario
                                concreto ni es posible asociarla con una persona en particular, simplemente nos servirá
                                para
                                mejorar el servicio que ofrecemos a nuestros visitantes.
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

