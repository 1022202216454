export const LOADING_ITEM_REQUEST = 'LOADING_ITEM_REQUEST';
export const LOADING_ITEM_SUCCESS = 'LOADING_ITEM_SUCCESS';
export const LOADING_ITEM_ERROR = 'LOADING_ITEM_ERROR';

export function LoadingItemRequest() {
    return {
        type: LOADING_ITEM_REQUEST
    };
}

export function LoadingItemSuccess(payload) {
    return {
        type: LOADING_ITEM_SUCCESS,
        payload
    };
}

export function LoadingItemError(error) {
    return {
        type: LOADING_ITEM_ERROR,
        error
    };
}

export function LoadingItem(info) {
    return async function (dispatch) {
        dispatch(LoadingItemRequest());
        try {
            dispatch(LoadingItemSuccess(info));
        } catch (error) {
            dispatch(LoadingItemError(error.message));
        }
    };
}
