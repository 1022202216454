import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Carousel from 'react-slick';
import clsx from 'clsx';
import ImageThumbCard from '../CardsGallery/ImageThumb';
import Title from '../TitleGallery';
import useStyle from './gallery-style';
import { useDispatch, useSelector } from 'react-redux';
import { lightBox } from '../../redux/actions';
import {portfolio} from './images'


export default function Gallery() {
  const classes = useStyle();
  const [data, setData] = useState([]);
  const [isLoaded, setLoaded] = useState(true);
  const [filter, setFilter] = useState('cat1');
  const light_box = useSelector(state => state.light_box);
  const dispatch = useDispatch();

  useEffect(() => {
    setData(portfolio);
    filterChildren('cat1');
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    autoplay: false,
    slidesToShow: 1,
    arrows: false
  };

  const settings2 = {
    dots: true,
    infinite: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    className: "center",
    centerPadding: "60px",
    focusOnSelect: false,
  };

  const filterChildren = (name) => {
    if (name !== 'all') {
      const filteredData = portfolio.filter(item => item.category === name);
      setData(filteredData);
      setFilter(name);
    } else {
      setData(portfolio);
      setFilter('all');
    }

    setLoaded(false);
    setTimeout(() => {
      setLoaded(true);
    }, 700);
  };


  const showPopup = (index, image) => {
    let pos = portfolio.map(e => {return e.img}).indexOf(image);
    let posIndex = pos + 1;

    try {
      dispatch(lightBox(light_box.toggle, posIndex));
    } catch (error) {

    }
  };

  return (
    <div className={classes.root}>
      <Container>
        <Title>
          Nuestro
          <strong style={{ marginLeft: 10 }}>
            Portafolio
          </strong>
        </Title>
        <Carousel  {...settings2}>
          <div style={{ width: 'auto'}} className={classes.filter}>
            <Button
              style={filter === 'cat1' ? {
                backgroundColor: '#00c853',
                color: '#fff',
                borderColor: '#00c853'
              } : {}}
              onClick={() => filterChildren('cat1')}
              className={classes.buttonSelected}
            >
              Cortinas
            </Button>
          </div>
          <div style={{ width: 'auto' }} className={classes.filter}>
            <Button
              style={filter === 'cat2' ? {
                backgroundColor: '#00c853',
                color: '#fff',
                borderColor: '#00c853'
              } : {}}
              onClick={() => filterChildren('cat2')}
              className={classes.buttonSelected}
            >
              Protectores de pared
            </Button>
          </div>

          <div style={{ width: 'auto' }} className={classes.filter}>
            <Button
              style={filter === 'cat3' ? {
                backgroundColor: '#00c853',
                color: '#fff',
                borderColor: '#00c853'
              } : {}}
              onClick={() => filterChildren('cat3')}
              className={classes.buttonSelected}
            >
              Equipamiento en general
            </Button>
          </div>

          <div style={{ width: 'auto' }} className={classes.filter}>
            <Button
              style={filter === 'cat4' ? {
                backgroundColor: '#00c853',
                color: '#fff',
                borderColor: '#00c853'
              } : {}}
              onClick={() => filterChildren('cat4')}
              className={classes.buttonSelected}
            >
              Mobiliario acero inoxidable
            </Button>
          </div>

          <div style={{ width: 'auto' }} className={classes.filter}>
            <Button
              style={filter === 'cat5' ? {
                backgroundColor: '#00c853',
                color: '#fff',
                borderColor: '#00c853'
              } : {}}
              onClick={() => filterChildren('cat5')}
              className={classes.buttonSelected}
            >
              Pisos asépticos
            </Button>
          </div>

          <div style={{ width: 'auto' }} className={classes.filter}>
            <Button
              style={filter === 'cat6' ? {
                backgroundColor: '#00c853',
                color: '#fff',
                borderColor: '#00c853'
              } : {}}
              onClick={() => filterChildren('cat6')}
              className={classes.buttonSelected}
            >
              Gases
            </Button>
          </div>

          <div style={{ width: 'auto' }} className={classes.filter}>
            <Button
              style={filter === 'cat7' ? {
                backgroundColor: '#00c853',
                color: '#fff',
                borderColor: '#00c853'
              } : {

              }}
              onClick={() => filterChildren('cat7')}
              className={classes.buttonSelected}
            >
              Diseños
            </Button>
          </div>
        </Carousel>


        <Hidden xsDown>
          <div className={classes.massonry}>
            {data.map((item, index) => (
              <div
                className={clsx(classes.item, isLoaded && classes.loaded)}
                key={index.toString()}
                style={{ transitionDuration: index / 4 + 's' }}
              >
                <ImageThumbCard
                  img={item.img}
                  size={item.size}
                  openPopup={() => showPopup(index, item.img)}
                />
              </div>
            ))}
          </div>
          {data.length < 1 &&
          <Typography variant="button" component="p" align="center"> No hay imagenes para mostrar </Typography>}
        </Hidden>
        <Hidden smUp>
          <Carousel {...settings}>
            {data.map((item, index) => (
              <div
                className={classes.itemCarousel}
                key={index.toString()}
              >
                <ImageThumbCard
                  img={item.img}
                  size={item.size}
                  openPopup={() => showPopup(index, item.img)}
                />
              </div>
            ))}
          </Carousel>
        </Hidden>
      </Container>
    </div>
  );
}
