export const ARTICLES_REQUEST = 'ARTICLES_REQUEST';
export const ARTICLES_SUCCESS = 'ARTICLES_SUCCESS';
export const ARTICLES_ERROR = 'ARTICLES_ERROR';

export function ArticlesRequest() {
  return {
    type: ARTICLES_REQUEST
  };
}

export function ArticlesSuccess(payload) {
  return {
    type: ARTICLES_SUCCESS,
    payload
  };
}

export function ArticlesError(error) {
  return {
    type: ARTICLES_ERROR,
    error
  };
}

export function article(info) {
  return async function (dispatch) {
    dispatch(ArticlesRequest());
    try {
      dispatch(ArticlesSuccess(info));
    } catch (error) {
      dispatch(ArticlesError(error.message));
    }
  };
}
