import React, {useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import useStyles from './shop-style';
import Button from '@material-ui/core/Button';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { useDispatch, useSelector } from 'react-redux';
import Card from '@material-ui/core/Card';
import { firebase } from '../../api/firebase';
import { article } from '../../redux/actions';
import BackIcon from '@material-ui/icons/ArrowBackSharp';
import NextIcon from '@material-ui/icons/ArrowForwardSharp';

export default function Articles({itemDetailArticle, addItem, category }) {
  const classes = useStyles();
  const items = useSelector(state => state.articles);
  const shopping_item = useSelector(state => state.shopping_item);
  const [last, setLast] = React.useState(null);
  const [disabledPrev, setDisabledPrev] = React.useState(true);
  const [disabledNext, setDisabledNext] = React.useState(false);
  const [storage, setStorage] = React.useState([]);
  const [totalPage, setTotalPage] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [noData, setNoData] = React.useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const db = firebase.firestore();
    const first = db.collection('articles')
      .orderBy('description').limit(8);
       first.get().then(snapshotFirst => {
        const last = snapshotFirst.docs[snapshotFirst.docs.length - 1];
        setLast(last);
         let info = [];
         let oldData = [...storage];
         snapshotFirst.forEach(doc => {
          let data = doc.data();
          data['id'] = doc.id;
          data['sizeOption'] = data.size.map(x => {return x.label;}).join(', ');
          info.push(data);
           oldData.push(data);
        });
         setStorage(oldData);
        dispatch(article(info));
      });

  },[])

  const prevPage = () => {
    let page = pageNumber - 1;
    if(page===1){
      setDisabledPrev(true)
    }else {
      setDisabledPrev(false)
    }

    if (disabledNext){
      setDisabledNext(false)
    }

    setPageNumber(page);
    let info = paginator(storage, page, 8);
    dispatch(article(info.data));
  };

  const paginator = (items, current_page, per_page_items) => {
    let page = current_page || 1,
      per_page = per_page_items || 10,
      offset = (page - 1) * per_page,

      paginatedItems = items.slice(offset).slice(0, per_page_items),
      total_pages = Math.ceil(items.length / per_page);

    return {
      page: page,
      per_page: per_page,
      pre_page: page - 1 ? page - 1 : null,
      next_page: (total_pages > page) ? page + 1 : null,
      total: items.length,
      total_pages: total_pages,
      data: paginatedItems
    };
  }

  const nextPage = async () => {

    if (disabledPrev){
      setDisabledPrev(false)
    }

    if (!noData) {
    const db = firebase.firestore();
    const next = db.collection('articles')
      .orderBy('description')
      .startAfter(last.data().description)
      .limit(8);
      next.get()
        .then((querySnapshot) => {
          let info = [];
          let oldData = [...storage];
          const last = querySnapshot.docs[querySnapshot.docs.length - 1];
          setLast(last);
          querySnapshot.forEach(doc => {
            let data = doc.data();
            data['id'] = doc.id;
            data['sizeOption'] = data.size.map(x => {
              return x.label;
            })
              .join(', ');
            oldData.push(data);
            info.push(data);
          });

          setStorage(oldData);
          let page = pageNumber + 1;
          setPageNumber(page);

          if (info.length > 0) {
            dispatch(article(info));
            setDisabledPrev(false);
          }
          if (info.length < 8){
            setTotalPage(page)
            setNoData(true);
            setDisabledNext(true);
          }

        });
    } else {
      let page = pageNumber + 1;
      setPageNumber(page);

      if(page === totalPage){
        setDisabledNext(true)
      }

      let info = paginator(storage, page, 8);
      dispatch(article(info.data));
    }

  };

  const itemDetail = (id) => {
    let thisItem = items.find(item => item.id === id);
    itemDetailArticle(thisItem)
  };


  return (
    <>
      <Grid spacing={2} container direction="row" justify="flex-start" alignItems="center">
        {items.map((item, index) => {
          return (
            <Grid key={index} item xs={12} xl={12} sm={4} md={4} lg={3} className={clsx(classes.gridItem)}>
              <Card className={classes.paper}>
                <img width={175} height={175} style={{ alignSelf: 'center' }} src={item.imageUrl} alt={item.title}/>

                <div className={classes.divText}>
                  <Typography style={{ alignSelf: 'flex-start', color: '#616161' }} variant={'subtitle1'}> {item.title.length > 25 ? item.title.substr(0, 25) + '...' : item.title} </Typography>
                  {/*<Typography style={{alignSelf:'flex-start'}} variant={'h6'}>RD$ {item.price} </Typography>*/}
                  <div style={{ flexDirection: 'row', display: 'flex' }}>
                    <Typography style={{ alignSelf: 'flex-start', marginRight: 5, color: '#757575' }} variant={'subtitle2'}>size:</Typography>
                    <Typography style={{ alignSelf: 'flex-start' }} variant={'subtitle2'}> {item.size.length > 0 ? item.size.map(x => {return x.label;}).join(', ') : 'N/A'} </Typography>
                  </div>
                </div>

                <div className={classes.divButtonCar}>
                  <div style={{ marginRight: 5 }}>
                    <Button onClick={() => itemDetail(item.id)}
                            className={classes.button1} size="small" variant="outlined"
                            color="secondary">
                      Detalles
                    </Button>
                  </div>
                  <div>
                    <Button onClick={() => addItem(item.id)}
                            startIcon={<ShoppingCartIcon/>} className={classes.button2}
                            size="small" variant="contained" color="primary">
                      Agregar {shopping_item.find(x => x.id === item.id) ? '( ' + shopping_item.find(x => x.id === item.id).quantity + ' )' : null}
                    </Button>
                  </div>
                </div>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      <div className={classes.pagination}>
        <div className={classes.carouselWrap}>
          <Button  disabled={disabledPrev} className={clsx(classes.prev)} onClick={prevPage}>
            <BackIcon/>
          </Button>
          <Button  disabled={disabledNext} className={clsx(classes.next)} onClick={nextPage}>
            <NextIcon/>
          </Button>
        </div>
      </div>
    </>
  );
}
