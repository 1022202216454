import React, {useEffect} from "react";
import {BrowserRouter as Router, Switch} from "react-router-dom";
import ScrollToTopRoute from "../ScrollToTopRoute";
import NotFound from "../View/404/404";
import Quotation from "../View/quotation";
import Home from "../View/home"
import Company from "../View/company/company";
import CompanyMission from "../View/company/companyMission";
import CompanyValues from "../View/company/companyValues";
import CompanyVision from "../View/company/companyVision";
import Contact from "../View/contact/contact";
import Info from "../View/info/info";
import Login from "../View/login/login"
import Register from "../View/register/register"
import Privacy from "../View/privacy-terms/privacy";
import Terms from "../View/privacy-terms/terms";
import BioSecurity from "../View/services/bioSecurity";
import Movilidad from "../View/services/movilidad";
import Engineering from "../View/services/engineering";
import SuppliesStore from "../View/services/suppliesStore";
import ProcessShopping from "../View/shopping-cart/processShopping";
import ShoppingCart from "../View/shopping-cart/shoppingCart";
import {firebase} from "../api/firebase";
import {shoppingItem, LoadingItem} from "../redux/actions";
import {useDispatch} from "react-redux";


export default function RouterList() {
    const user = firebase.auth().currentUser;
    const dispatch = useDispatch();

    useEffect(()=>{
        if (user){
            firebase.firestore()
                .collection('clients')
                .doc(user.uid)
                .collection('articles')
                .get()
                .then(documentSnapshots => {
                    let info = [];
                    documentSnapshots.forEach(doc => {
                        let data = doc.data();
                        data['id'] = doc.id;
                        info.push(data);
                    });
                    dispatch(LoadingItem(false))
                    dispatch(shoppingItem(info));
                });
        }
    },[user])

    return (
        <Router>
            <Switch>
                <ScrollToTopRoute exact={true} path={"/"} component={Home}/>
                <ScrollToTopRoute exact={true} path={"/cotizacion"} component={Quotation}/>
                <ScrollToTopRoute exact={true} path={"/empresa"} component={Company}/>
                <ScrollToTopRoute exact={true} path={"/mision"} component={CompanyMission}/>
                <ScrollToTopRoute exact={true} path={"/valores"} component={CompanyValues}/>
                <ScrollToTopRoute exact={true} path={"/vision"} component={CompanyVision}/>
                <ScrollToTopRoute exact={true} path={"/contacto"} component={Contact}/>
                <ScrollToTopRoute exact={true} path={"/covid-19"} component={Info}/>
                <ScrollToTopRoute exact={true} path={"/iniciar-sesion"} component={Login}/>
                <ScrollToTopRoute exact={true} path={"/registrarse"} component={Register}/>
                <ScrollToTopRoute exact={true} path={"/privacidad"} component={Privacy}/>
                <ScrollToTopRoute exact={true} path={"/terminos"} component={Terms}/>
                <ScrollToTopRoute exact={true} path={"/bioseguridad"} component={BioSecurity}/>
                <ScrollToTopRoute exact={true} path={"/ingenieria-hospitalaria"} component={Engineering}/>
                <ScrollToTopRoute exact={true} path={"/suministros"} component={SuppliesStore}/>
                <ScrollToTopRoute exact={true} path={"/carrito-de-compras"} component={ProcessShopping}/>
                <ScrollToTopRoute exact={true} path={"/tienda"} component={ShoppingCart}/>
                <ScrollToTopRoute exact={true} path={"/movilidad"} component={Movilidad}/>
                <ScrollToTopRoute component={NotFound}/>
            </Switch>
        </Router>

    );
}
