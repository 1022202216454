import React, { Fragment } from 'react';
import RegisterForm from '../../components/RLForms/Register';

function Register() {
  return (
    <Fragment>
      <div>
        <RegisterForm />
      </div>
    </Fragment>
  );
}


export default Register;
