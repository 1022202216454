import React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Notification from '../../components/Notification';
// import brand from '../../const/brand';
import Grid from '@material-ui/core/Grid';
import Decoration from '../../components/FormsLanding/Decoration';
import Container from '@material-ui/core/Container';

const sectionMargin = margin => (margin * 20);
const useStyles = makeStyles(theme => ({
    mainWrap: {
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
        background: theme.palette.background.paper,
    },
    divText: {
      alignItems:'flex-start',
    },
    text: {
      alignItems:'flex-start',
    },
    subtitle:{
        marginBottom:theme.spacing(6),
    },
    spaceBottom: {
        marginBottom: sectionMargin(theme.spacing())
    },
    spaceTop: {
        paddingTop:theme.spacing(15),
    },
    spaceBottomText: {
        marginBottom:theme.spacing(10),
    },
    containerWrap: {
        marginTop: theme.spacing(10),
        padding: theme.spacing(4),
        '& > section': {
            position: 'relative'
        }
    },
}));

export default function Company(props) {
    const classes = useStyles();
    const {onToggleDark, onToggleDir} = props;
    return (
        <React.Fragment>
            {/*<Head>*/}
            {/*    <title>{brand.agency.name}&nbsp; - Compañia </title>*/}
            {/*</Head>*/}
            <CssBaseline/>
            <div className={[classes.mainWrap]}>
                <Header onToggleDark={onToggleDark} onToggleDir={onToggleDir} invert/>
                <Container maxWidth="lg" className={[classes.containerWrap]}>
                    <Decoration/>
                    <section className={clsx(classes.spaceTop, classes.spaceBottom)}>
                        <Typography className={classes.subtitle} variant="h3" align="center" gutterBottom>
                            Nosotros
                        </Typography>

                        <Grid container direction="row" justify="center" alignItems="center" className={[classes.text, classes.spaceBottomText]}>
                            <Grid item ms={12} xs={12} className={[classes.text]}>
                                <Typography variant="h6" align="justify" gutterBottom>
                                    Somos una empresa que como objetivo principal tenemos, suministrar productos y servicios de la más alta calidad, cumpliendo con los estándares sanitarios, conforme a las normas internacionales de la OMS Y OPS, con el fin de brindar seguridad y confianza a cada usuario. Tenemos diversos departamentos que abarcan las necesidades de nuestros consumidores, desde grandes hospitales e instituciones públicas, privadas y hasta los hogares de cada ciudadano.
                                </Typography>
                            </Grid>
                        </Grid>


                        <Typography className={classes.subtitle} variant="h4" align="center" gutterBottom>
                            Conoces nuestros valores y principios.
                        </Typography>
                        <Grid spacing={5} container direction="row" justify="center" alignItems="center" className={[classes.text]}>
                            <Grid item xs={12} md={4} className={[classes.text]}>
                                <div className={classes.divText}>
                                        <img  src={require('../../images/icons/icon_shape1.png')} alt=""/>
                                    <Typography component="h3" gutterBottom >
                                        Misión
                                    </Typography>
                                </div>
                                <Typography variant="subtitle1" align="justify" gutterBottom>
                                    Garantizar los servicios, brindando el mayor beneficio a los usuarios manteniendo la calidad y eficiencia de los servicios ofertados utilizando tecnologías adecuadas.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4} className={[classes.text]}>
                                <div className={classes.divText}>
                                        <img  src={require('../../images/icons/icon_shape2.png')} alt=""/>
                                    <Typography component="h3" gutterBottom>
                                        Visión
                                    </Typography>
                                </div>
                                <Typography variant="subtitle1" align="justify">
                                    Somos una empresa que favorece a otras instituciones del sector salud en servicios de equipamiento médico, instalaciones industriales, planta física, gestión de servicios y desinfección electro y los protocolos de bioseguridad.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4} className={[classes.text]}>
                                <div className={classes.divText}>
                                        <img src={require('../../images/icons/icon_shape3.png')} alt=""/>
                                    <Typography component="h3" gutterBottom>
                                        Valores
                                    </Typography>
                                </div>
                                   <Typography variant="subtitle1" display={'block'} align="justify">
                                       -Trabajo en equipo - Pasión por servir - Integridad - Responsabilidad - Orientación a los resultados.
                                   </Typography>
                            </Grid>
                        </Grid>
                    </section>
                </Container>
                <section className={classes.spaceTop}>
                    <Footer/>
                </section>
                <Notification/>
            </div>
        </React.Fragment>
    );
}
