import React, { useRef, useEffect } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Carousel from 'react-slick';
import TestimonialCard from '../Cards/Testimonial';
import TitleIcon from '../Title/WithIcon';
import CompanyLogo from '../CompanyLogo';
import SquareParallax from '../Parallax/SingleSquare';
import useStyle from './testi-style';
import avatar1 from '../../images/avatars/img1.png';
import avatar2 from '../../images/avatars/img2.png';
import avatar3 from '../../images/avatars/testimonio3.jpg';
import MdiIcon from "@material-ui/icons/FormatQuote";
import Grid from "@material-ui/core/Grid";

const testiContent = [
  {
    text: 'Nunca pensé que su trabajo sería tan impecable, tengo más de 5 años trabajando con ellos son 100% únicos.',
    avatar: avatar1,
    name: 'Ingeniero Edwin Suarez',
    title: 'Presidente de Eya Home Solution',

    rating: 5
  },
  {
    text: 'La manera que ellos trabajan demuestran que son una empresa organizada, quedé sorprendido con el trabajo.',
    avatar: avatar2,
    name: 'Doctor Luis Campos',
    title: 'Presidente de CRESA',
    rating: 5
  },
  {
    text: 'Realmente valoro la rápida implementación y flexibilidad de su equipo de profesionales ofrecen un servicio personalizado.',
    avatar: avatar3,
    name: 'CASADIG',
    title: 'Centro de Salud Integral',
    rating: 5
  },
];

export default function Testimonials() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const classes = useStyle();

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    arrows: false,
    slidesToScroll: 1,
    variableWidth: true,
    responsive: [{
      breakpoint: 1100,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    }, {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
      }
    }, {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      }
    }]
  };

  const slider = useRef(null);
  useEffect(() => {
    if (theme.direction === 'ltr' && window.innerWidth > 1279) {
      const limit = window.innerWidth > 1400 ? 3 : 2;
      const lastSlide = Math.floor(testiContent.length - limit);
      slider.current.slickGoTo(lastSlide);
    }
  }, []);

  return (
    <div className={classes.root}>
      <SquareParallax />
      <div className={classes.carousel}>
        <Carousel ref={slider} {...settings}>
          {isDesktop && (
            <div className={classes.item}>
              <div className={classes.itemPropsFirst} />
            </div>
          )}
          {testiContent.map((item, index) => (
            // <div key={index.toString()} className={classes.item}>
              <Grid key={index.toString()} className={classes.item} item md={4} xs={12} xl={12}>
                <Grid container alignItems="center">
              <TestimonialCard
                avatar={item.avatar}
                title={item.title}
                name={item.name}
                text={item.text}
                star={item.rating}
              />
                </Grid>
              </Grid>

          ))}
          {isDesktop && (
            <div className={classes.item}>
              <div className={classes.itemPropsLast} />
            </div>
          )}
        </Carousel>
      </div>
      <div className={classes.floatingTitle}>
        <Container fixed>
          <div className={classes.title}>
            <TitleIcon text={'Testimonios clientes'} icon={<MdiIcon className={classes.icon}/>} />
          </div>
        </Container>
      </div>
      <CompanyLogo />
    </div>
  );
}
