import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import Carousel from 'react-slick';
import React, { useRef } from 'react';
import useStyles from './shop-style';
import BackIcon from '@material-ui/icons/ArrowBackSharp';
import NextIcon from '@material-ui/icons/ArrowForwardSharp';


const settings = {
  dots: false,
  variableWidth: false,
  infinite: true,
  speed: 900,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export default function CarouselCover({ coverPageList }) {

  const classes = useStyles();
  const slider = useRef(null);
  const nextSlide = () => {
    slider.current.slickNext();
  };
  const prevSlide = () => {
    slider.current.slickPrev();
  };

  return (
    <div className={classes.carouselWrap}>
      <Button className={clsx(classes.nav, classes.prev)} onClick={prevSlide}>
        <BackIcon/>
      </Button>
      <Button className={clsx(classes.nav, classes.next)} onClick={nextSlide}>
        <NextIcon/>
      </Button>
      <Carousel className={classes.carousel} ref={slider} {...settings}>
        {coverPageList.map((cover, index) => {
          return (
            <div key={index}>
              <img alt={'cover'+index} width={'100%'} style={{ alignSelf: 'center' }} src={cover.imageUrl}/>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};
