import { makeStyles } from '@material-ui/core/styles';
const headerStyles = makeStyles(theme => ({
  '@keyframes slideRight': {
    from: {
      opacity: 0,
      transform: 'translateX(-100px)'
    },
    to: {
      opacity: 1,
      transform: 'none'
    }
  },
  fixed: {},
  invert: {},
  openDrawer: {},
  header: {
    position: 'absolute',
    color: theme.palette.common.white,
    background: 'none',
    boxShadow: 'none',
    transition: 'background 0.3s ease',
    '& > *': {
      [theme.breakpoints.down('md')]: {
        paddingLeft: 0
      }
    },
    '&$fixed': {
      position: 'fixed',
      background: 'rgba(0, 0, 0, 0.6)',
      backdropFilter: 'saturate(180%) blur(20px)',
      '& $navMenu': {
        '& svg': {
          fill: theme.palette.common.white
        },
        '& a': {
          color: theme.palette.common.white
        }
      },
      '& $mobileMenu': {
        '& $bar': {
          backgroundColor: theme.palette.common.white,
          '&:after, &:before': {
            backgroundColor: theme.palette.common.white
          },
        }
      },
    },
    '&$openDrawer': {
      zIndex: 1600,
      boxShadow: 'none',
      background: 'none',
      filter: 'none'
    },
    '&$invert': {
      '& $navMenu': {
        '& svg': {
          fill: theme.palette.text.primary
        },
        '& a': {
          color: theme.palette.text.primary,
        }
      },
      '& $divider': {
        borderColor: theme.palette.divider
      },
      '& $mobileMenu': {
        '& $bar': {
          backgroundColor: `${theme.palette.text.primary}`,
          '&:after, &:before': {
            backgroundColor: `${theme.palette.text.primary}`
          }
        }
      },
    }
  },

  linkColor:{
    '& a': {
      textTransform: 'capitalize',
      minWidth: 0,
      padding: theme.spacing(0, 2),
      fontWeight: theme.typography.fontWeightMedium,
      transition: 'all 0.3s ease-out',
      color: `#000 !important`,
      fontSize: 16,
      boxShadow: 'none',
      position: 'relative',
      margin: theme.spacing(0.5, 1),
      '&:hover': {
        color: `#00c853 !important`,
        background: 'rgba(255, 255, 255, 0.2)'
      },
      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(0, 3),
      },
      [theme.breakpoints.down('xs')]: {
        margin: 0
      },
    },
    '&[class="active"]': {
      '& a': {
        boxShadow: '0 1.5px 32px -8px rgba(0, 0, 0, 0.8)',
        color: `#000 !important`,
        background: '#00c853',
      }
    }

  },

  headerContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& nav': {
      alignItems: 'center',
      display: 'flex'
    }
  },
  logo: {
    marginRight: theme.spacing(),
    '& a': {
      textDecoration: 'none',
      display: 'block',
      fontSize: 16,
      color: theme.palette.common.white,
      alignItems: 'center',
      fontWeight: theme.typography.fontWeightMedium,
      textAlign: 'center'
    },
    '& img': {
      display: 'block',
      margin: '0 auto',
      width: 125,
      height: 125,
      [theme.breakpoints.down('xs')]: {
        height: 100,
        width: 100,
      }
    }
  },
  active: {},
  button: {
    fontSize:16,
    textTransform: 'capitalize',
    // background: theme.palette.secondary.light,
    color: '#fff',
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover': {
      height: 28,
      background: 'rgba(255, 255, 255, 0.2)'
    },
  },
  navLogo: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 2),
    },
    [theme.breakpoints.up('lg')]: {
      flex: 1,
    },
  },
  navMenu: {
    '& ul': {
      margin: 0,
      padding: 0,
      listStyle: 'none',
      '& li': {
        listStyle: 'none',
        position: 'relative',
        display: 'inline-block',
        '& a': {
          textTransform: 'capitalize',
          minWidth: 0,
          padding: theme.spacing(0, 2),
          fontWeight: theme.typography.fontWeightMedium,
          transition: 'all 0.3s ease-out',
          color: theme.palette.common.white,
          fontSize: 16,
          boxShadow: 'none',
          position: 'relative',
          margin: theme.spacing(0.5, 1),
          '&:hover': {
            background: 'rgba(255, 255, 255, 0.2)'
          },
          [theme.breakpoints.down('md')]: {
            margin: theme.spacing(0, 3),
          },
          [theme.breakpoints.down('xs')]: {
            margin: 0
          },
        },
        '&[class="active"]': {
          '& a': {
            boxShadow: '0 1.5px 32px -8px rgba(0, 0, 0, 0.8)',
            color: `${theme.palette.common.white} !important`,
            background: '#00c853',
          }
        }
      }
    }
  },

  buttonLogin:{

  },
  buttonSignIn:{
    backgroundColor:'#2196F3',
    maxWidth:150
  },

  navAuth: {
    justifyContent: 'space-evenly',
    '& a': {
      color: theme.palette.common.white,
      padding: theme.spacing(0.5, 2),
      margin: theme.spacing(0, 0.5),
      fontSize: 16,
    },
    '& $button': {
      background: theme.palette.secondary.light,
      color: theme.palette.secondary.main + '!important',
      fontWeight: theme.typography.fontWeightRegular
    },
  },
  langMenu: {
    textTransform: 'capitalize',
  },
  modeMenu: {
    textTransform: 'capitalize',
  },
  icon: {
    // background: theme.palette.secondary.light,
    '&:hover': {
      height: 100,
      background: 'rgba(255, 255, 255, 0.2)'
    },
  },

  bar: {},
  menu: {},
  paperNav: {
    width: '100%',
    background: 'rgba(0, 0, 0, 0.6)',
    backdropFilter: 'saturate(180%) blur(20px)',
    [theme.breakpoints.up(680)]: {
      width: 300,
    },
  },
  mobileMenu: {
    margin: theme.spacing(0, 1),
    '& $bar': {
      backgroundColor: '#00c853',
      '&:after, &:before': {
        backgroundColor: '#00c853',
      },
    },
    '&[class*="is-active"]': {
      '& $bar': {
        backgroundColor: `${theme.palette.common.white} !important`,
        '&:after, &:before': {
          backgroundColor: `${theme.palette.common.white} !important`
        }
      },
    }
  },
  mobileNav: {
    '& $menu': {
      padding: theme.spacing(0, 5),
      overflow: 'auto',
      top: theme.spacing(10),
      width: '100%',
      position: 'absolute',
      height: 'calc(100% - 80px)',
      '& a': {
        animationName: '$slideRight',
        animationTimingFunction: 'ease'
      },
    }
  },
  menuList: {
    textTransform: 'capitalize',
    color: theme.palette.common.white,
    '& span': {
      fontSize: 24
    }
  },
  shoppingCartIcon: {
    fontSize: 30,
    color: '#fff'
  },
  divider: {
    background: 'none',
    borderLeft: '1px solid rgba(255, 255, 255, 0.5)',
    height: 20
  },
  dividerSidebar: {
    background: 'rgba(255, 255, 255, 0.2)',
    margin: '1rem 0'
  },

  navMenu2: {
    '&$invert': {
      '& ul': {
        '& li': {
          '& a': {
            transition: 'all 0.3s ease-out',
            color: "#000",
          }
        }
      }
    },
    '& ul': {
      listStyle: 'none',
      '& li': {
        [theme.breakpoints.up('lg')]: {
        },
        listStyle: 'none',
        position: 'relative',
        display: 'inline-block',
        '& a': {
          color: '#00c853',
          textTransform: 'capitalize',
          fontSize: 18,
          fontWeight: theme.typography.fontWeightMedium,
          background: 'none !important',
          boxShadow: 'none',
          position: 'relative',
          padding: '6px',
          minWidth: 0,
          '&:after': {
            content: '""',
            borderBottom: '3px solid #fff',
            width: '0%',
            position: 'absolute',
            bottom: 0,
            left: '50%',
            transition: 'all 0.2s cubic-bezier(0.42, 0.16, 0.21, 0.93)'
          },
          '&:hover': {
            transition: 'all 0.3s ease-out',
            '&:after': {
              width: '60%',
              left: 8,
              borderBottomColor: theme.palette.primary.light
            }
          }
        },
        '&[class="active"]': {
          '& a': {
            color: theme.palette.primary.light,
            '&:after': {
              borderBottomColor: theme.palette.primary.light,
              width: '60%',
              left: 8,
            },
          }
        }
      }
    }
  },
  cartIconToggle:{
    marginLeft:20,
    backgroundColor:'transparent',
    borderColor:'rgba(255,255,255,.3)',
    '&:focus': {
      outline: 'none',
      backgroundColor:'transparent',
      borderColor:'rgba(255,255,255,.3)',
    },
    '&:hover': {
      outline: 'none',
      backgroundColor:'transparent',
      borderColor:'rgba(255,255,255,.3)',
    },
  },
  dropdownItem:{
    '&:focus': {
      color:'#00c853',
      outline: 'none',
    },
    '&:hover': {
      color:'#00c853',
      outline: 'none',
    },
  },
  logout: {
    fontSize: 12,
    borderColor: '#00e676',
    borderStyle: 'solid',
    borderWidth: 1,
    backgroundColor: '#00e676',
    color: '#fff',
    fontWeight: 'bold',
    '&:hover': {
      borderWidth: 1,
      backgroundColor: '#00c853',
      borderStyle: 'solid',
      borderColor: '#00c853',
      color: '#fff'
    },
    '&:focus': {
      outline: 'none',
    },
  },
  cancel: {
    fontSize: 12,
    fontWeight: 'bold',
    backgroundColor: '#f44336',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#f44336',
    color: '#fff',
    '&:hover': {
      borderColor: '#c62828',
      borderStyle: 'solid',
      borderWidth: 1,
      backgroundColor: '#c62828',
      color: '#fff'
    },
    '&:focus': {
      outline: 'none',
    },
  },

  cartDropdownMenu:{
    marginTop:10,
    color:'#000',
    fontColor:'#000',
    backgroundColor:'rgba(255,255,255,.3)',
    borderColor:'rgba(15,192,243,.3)',
  },
}));

export default headerStyles;
