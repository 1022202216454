import React, { useRef } from 'react';
import Carousel from 'react-slick';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import BackIcon from '@material-ui/icons/ArrowBackSharp';
import NextIcon from '@material-ui/icons/ArrowForwardSharp';
import Title from '../TitleGallery';
import SimpleThumbCard from '../CardsCategories/SimpleThumb';
import useStyles from './all-categories-style';
import {images as dataProducts } from './images'


const settings = {
  dots: false,
  infinite: true,
  speed: 1500,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 7000,
  slidesToShow: 4,
  slidesToScroll: 4,

  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 750,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

export default function Feature() {
  const classes = useStyles();
  const slider = useRef(null);
  const nextSlide = () => {
    slider.current.slickNext();
  };
  const prevSlide = () => {
    slider.current.slickPrev();
  };
  return (
    <div className={classes.root}>
      <Container className={classes.carouselContainer}>
        <Title>
          <strong style={{ marginRight: 10 }}>
            Clientes
          </strong>
          Trabajados
        </Title>
        <div className={classes.carouselWrap}>
          <Button className={clsx(classes.nav, classes.prev)} onClick={prevSlide}>
            <BackIcon/>
          </Button>
          <Button className={clsx(classes.nav, classes.next)} onClick={nextSlide}>
            <NextIcon/>
          </Button>
          <Carousel ref={slider} {...settings}>
            {dataProducts.map((item, index) => (
              <div key={index.toString()} className={classes.item}>
                <SimpleThumbCard
                  title={item.title}
                  logo={item.logo}
                />
              </div>
            ))}
          </Carousel>
        </div>
      </Container>
    </div>
  );
}
