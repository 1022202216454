import { makeStyles } from '@material-ui/core/styles';

const cardsStyles = makeStyles(theme => ({
  simpleThumb: {
    display: 'block',
    textAlign: 'center',
    marginLeft: theme.spacing(7),
    transition: 'all 0.35s ease-out',
    color: theme.palette.text.primary,
  },

  paper: {
    backgroundImage: `linear-gradient(120deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    width: 250,
    padding: 5,
    margin: 3
  },

  figure: {
    overflow: 'hidden',
    verticalAlign: 'middle',
  },
}));

export default cardsStyles;
